import {
  Component, ViewChild,
} from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UserService } from "src/app/shared/services/api/user/user.service";
import { CognitoService } from "src/aws/services/cognito/cognito.service";
import { RSA_NO_PADDING } from "constants";
import { MasterDataService } from "src/app/shared/services/master-data/master-data.service";
import { userInfo } from "os";
import { Roles } from "src/app/shared/models/other-data.model";
import { UsersService } from "src/app/shared/services/admin/users/users.service";
import { tap } from "rxjs/operators";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { UserFormComponent } from "src/app/core/components/forms/user-form/user-form.component";
import { Location as ngLocation } from '@angular/common';

@UntilDestroy()
@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})
export class EditUserComponent {
  @ViewChild("userForm") userForm: UserFormComponent;
  isValid = false;
  userId;
  user_data;
  user;

  constructor(
    private userService: UserService,
    private usersService: UsersService,
    private notif: NotificationService,
    private masterDataService: MasterDataService,
    private router: Router,
    private location: ngLocation,
    private route: ActivatedRoute
  ) {
    localStorage.removeItem("lastSearchQuery");

    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params: ParamMap) => {

      this.userId = params.get("id");
      this.getUserData()
    });
  }

  getUserData(): void {

    this.usersService.getUserDetail(this.userId).pipe(untilDestroyed(this)).subscribe((res) => {


        this.user_data = {
          username:  res.user?.Username,
          id: res.appData?.id,
          company: res.appData?.company,
          name: res.user.attributes.name,
          surname: res.user.attributes.familyName,
          email: res.user.attributes.email,
          role: Roles.find(role => res.user.groups.includes(role.name.replace('ROLES.','').toLowerCase()))?.id,
          receiveAlerts: res.user.attributes.receiveAlerts,
          emailNewsletter: res.user.attributes.emailNewsletter,
          registration_date: new Date(res.user.UserCreateDate).toISOString().split('T')[0].replace(/-/g,'/'),
        };
        if(!res.appData?.field_interest){
          this.user_data.areas = []
          this.user_data.fields = []
      }
      else{
        this.user_data.fields=res.appData?.field_interest.filter(field=> this.masterDataService.getActiveData("field").map(res=>res.id).includes(field))
        this.user_data.areas= [...new Set(this.user_data.fields.map(field=> this.masterDataService.searchParent("field",field,"area")).filter(area=>area))]
      }
      this.resetForm();
    })
  }

  resetForm() {
    // Make sure to copy, so we are sure the data is not modified
    const data = JSON.parse(JSON.stringify(this.user_data));

    this.userForm.setData(data);
  }
  updateUser(): void {

    let body = this.userForm.getData();

    body.username = this.user_data.username;
    body.id = this.userId
    this.userService.updateUser(this.userId,body).pipe(untilDestroyed(this),tap(
      res => this.router.navigate(["administration", "search-users"]),
      err => this.notif.error("NOTIF.UPDATE_ERROR")
    ),).subscribe((res)=>{
    });

  }

  newUser(): void {
    /** redireccion a alta usuario */
    this.router.navigate(["administration", "register-user"]);
  }

  back(){
    this.location.back();
  }
  deleteUser(): void {
    /** borrar usuario */
    // realizar peticion
    this.userService.deleteUser(this.userId);
  }
}
