import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService } from "src/app/shared/services/api/company/company.service";
import { CognitoUser } from "src/aws/services/cognito/cognito.model";

@UntilDestroy()
@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent {
  @Input() user!: any; // TODO: Type CognitoUser
  @Input() index!: number;
  company:string;
  loadingCompany:boolean=true;
  headers = [
    this.t.instant("HEAD.NAME"),
    this.t.instant("HEAD.SURNAME"),
    this.t.instant("HEAD.ROLE"),
    this.t.instant("HEAD.EMAIL"),
    this.t.instant("HEAD.COMPANY"),
    this.t.instant("HEAD.PREFERENCES"),
    this.t.instant("HEAD.LANGUAGE"),
    this.t.instant("HEAD.REGISTRATION_DATE"),
    this.t.instant("HEAD.NUM_DAILY_VISITS"),
  ];

  constructor( private t: TranslateService, private companyService: CompanyService) {
  }
  ngOnInit(){
    if(this.user?.company){
      this.getCompanyName()
    }
    else{
      this.loadingCompany=false;

    }
  }
  getCompanyName(){
    this.companyService
    .getCompany(this.user?.company)
    .pipe(untilDestroyed(this))
    .subscribe(res => {
      this.company = res.name
      this.loadingCompany=false;

    }
    )  }
}
