import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { from, Observable, of, throwError } from "rxjs";
import { API as awsApi } from "aws-amplify";
import { catchError, map, mergeMap, retry, retryWhen, take, tap } from "rxjs/operators";
import { genericRetryStrategy } from "./api_retry";


@Injectable({
  providedIn: "root",
})
export class AWSApiHttpInterceptor implements HttpInterceptor {

  constructor(
    private enabled: boolean = true,
    private apiUrl: string,
    private apiName: string,
    private revertOnError: boolean = true,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.enabled) {
      return next.handle(request);
    }

    if (!request.url.startsWith(this.apiUrl)) {
      return next.handle(request);
    }

    const apiFunction = this.getApiMethod(request);
    if (!apiFunction) {
      return next.handle(request);
    }

    const path = request.urlWithParams.replace(this.apiUrl, '');
    const options = {
      body: request.body,
      headers: this.getHeaders(request.headers)
    }

    return of(true)
      .pipe(
        mergeMap(_ => from(apiFunction(this.apiName, path, options))),
        // Retry        
        retryWhen(genericRetryStrategy({
          waitTimes: [3000, 10000, 30000, 60000],
          filter: e => this.shouldRetry(e)
        })),
        // Convert to HttpResponse so the standard Angular http client works fine
        map(r => new HttpResponse({
          body: r,
          status: 200
        })),
        catchError(e => {
          if (this.revertOnError) {
            console.error('Error on api operation, reverting to http', e);
            return next.handle(request);
          }
          return throwError(new HttpErrorResponse(e.response));
        })
      )
  }

  private shouldRetry(e: any): boolean {
    const response = e.response;
    if (!response) {
      // Not a network error
      return false;
    }

    if (response.status == 500 && response.data?.message == "Internal Server Error" && !response.data?.errorCode) {
      // Network error, but not from lambda. Candidate of timeout.
      return true;
    }

    return false;
  }

  private getApiMethod(request: HttpRequest<unknown>): (apiName: any, path: any, init: any) => Promise<any> {
    const method = request.method.toLowerCase();
    let fn = null;

    switch (method) {
      case 'delete': {
        fn = awsApi.del;
        break;
      }
      default: {
        fn = awsApi[method];
      }
    }

    if (fn) {
      return fn.bind(awsApi);
    }
    return null;
  }

  private getHeaders(headers:HttpHeaders):{[key:string]:string} {
    if(!headers){
      return {}
    }
    return headers.keys().reduce((acc,key) => {
      acc[key]=headers.get(key)
      return acc
    },{})
  }
}
