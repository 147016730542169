import { Injectable } from '@angular/core';
import { from, Observable, pipe, UnaryFunction } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { Storage as AwsStorage, UploadTask } from "@aws-amplify/storage";
import { downloadAndZip } from 'src/app/shared/utils/DownloadDocumentUtils';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

export type ExtraTags = { [key: string]: string };

type UploadTaskPlus = UploadTask & Partial<{ onCancel: () => void }>;

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  private s3 = environment.aws.S3;

  constructor(private notif: NotificationService) {
  }

  uploadPdfFile(file: File, extraTags?: ExtraTags): Observable<string | UploadTask> {
    let extension = "pdf";
    if (file) {
      extension = this.getFileExtension(file?.name);
    }
    return this.uploadFile(
      file,
      this.s3.paths.FILE_UPLOAD + encodeURIComponent(file.name),
      "application/"+extension,
      extraTags,
    );
  }

  private getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  uploadFile(file: File, key: string, contentType: string, extraTags?: ExtraTags): Observable<string | UploadTask> {
    const tags = new URLSearchParams();
    tags.append('source', `app`);
    tags.append('origin', window.location.origin);
    tags.append('origin-path', window.location.href.replace(window.location.origin, '').replace('/#/', '/'));
    if (extraTags) {
      Object.entries(extraTags).forEach(([k, v]) => {
        tags.append(k, v);
      })
    }

    if (environment.aws.localStack) {
      return this.simpleUpload(file, key, contentType, tags);
    } else {
      return this.resumableUpload(file, key, contentType, tags);
    }
  }

  private resumableUpload(file: File, key: string, contentType: string, tags: URLSearchParams): Observable<string | UploadTask> {
    const obs = new Observable<string | UploadTask>(subscriber => {
      const operation: UploadTaskPlus = AwsStorage.put(key, file, {
        contentType,
        resumable: true,
        tagging: tags.toString(),
        completeCallback: (event) => {
          console.log(`Successfully uploaded ${event.key}`);
          subscriber.next(key);
          subscriber.complete();
        },
        progressCallback: (progress) => {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          subscriber.next(operation);
        },
        errorCallback: (err) => {
          console.error('Unexpected error while uploading', err);
          subscriber.error(err);
        },
      })

      operation.onCancel = () => {
        console.log('UploadTask has been canceled');
        subscriber.complete();
      }

      subscriber.next(operation);

    });

    return obs;
  }

  private simpleUpload(file: File, key: string, contentType: string, tags: URLSearchParams): Observable<string> {
    const promise = AwsStorage.put(key, file, {
      contentType,
      tagging: tags.toString(),
    });

    return from(promise)
      .pipe(
        map(() => key)
      );
  }


  uploadImage(file: File, extraTags?: ExtraTags): Observable<string> {
    const filename = file.name;

    const key = this.s3.paths.FILE_UPLOAD + encodeURIComponent(file.name);
    let img_ext = "";

    if (filename.endsWith(".jpg") || filename.endsWith(".jpeg") || filename.endsWith(".JPG") || filename.endsWith(".JPEG")) {
      img_ext = "jpeg"
    } else if (filename.endsWith(".png") || filename.endsWith(".PNG")) {
      img_ext = "png";
    }

    return this.uploadFile(
      file,
      key,
      `image/${img_ext}`,
      extraTags,
    ).pipe(
      onlyOnUploadComplete(),
    );
  }

  getFileURL(key: string): Observable<string> {
    return from(AwsStorage.get(key))
  }

  async downloadDocuments(docs: string[]) {
    await Promise.all(docs.map(doc => this.getFileURL(doc).toPromise()))
      .then(urls => downloadAndZip(urls))
      .catch(err => {
        console.log("Error while downloading documents", err);
        this.notif.error("NOTIF.GENERAL_ERROR");
      });
  }

  downloadFile(link: string): void {
    this.getFileURL(link)
      .pipe(tap((url: string) => {
        // console.log(url);
        window.open(url, "_blank");
      })).subscribe()
  }

  cancelUpload(task: UploadTaskPlus) {
    AwsStorage.cancel(task);
    task.onCancel?.();
  }
}


export function onlyOnUploadComplete(): UnaryFunction<Observable<UploadTask | string>, Observable<string>> {
  return pipe(
    filter(v => typeof v == 'string'),
    // From this point of the flow it will always be string, as the filter protects it.
  ) as UnaryFunction<Observable<UploadTask | string>, Observable<string>>;
}

export function tapOnUploadComplete(onTap: (v: string) => void): UnaryFunction<Observable<string | UploadTask>, Observable<string | UploadTask>> {
  return pipe(
    tap(v => {
      if (typeof v === 'string') {
        onTap(v);
      }
    })
  );
}

export function tapOnUploadProgress(onTap: (v: UploadTask) => void): UnaryFunction<Observable<string | UploadTask>, Observable<string | UploadTask>> {
  return pipe(
    tap(v => {
      if (typeof v !== 'string') {
        onTap(v);
      }
    })
  );
}
