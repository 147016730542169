import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ActionParams {
  [key: string]: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RuleActionsService {

  constructor(private http: HttpClient) { }

  getUserActions(ruleId: string): Observable<ActionParams> {
    return this.http.get<ActionParams>(`/rule/${ruleId}/userActions`);
  }
}
