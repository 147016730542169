<div class="messagePanel">

  <h1>{{ 'noAccessPage.title' | translate }}</h1>
  <br>
  <h2>{{ 'noAccessPage.text' | translate }}</h2>
  <br>
  <h2><u>{{ 'noAccessPage.email' | translate }}</u></h2>
  <br>
  <h2>{{ 'noAccessPage.footer' | translate }}</h2>

  <!-- <app-button theme="main" (click)="login()" text="RETURN_HOME"></app-button> -->

</div>
