import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { ModalComponent } from 'src/app/core/components/modal/modal.component';
import validators from 'src/app/core/config/validators';
import { MasterData, MasterDataEntries, MasterDataOptions, MasterDataTypes, MasterDataValuesOut } from 'src/app/shared/models/master-data.model';
import { GetMasterdataElementNamePipe } from 'src/app/shared/pipes/get-masterdata-element-name/get-masterdata-element-name.pipe';
import { MasterdataManagementService } from 'src/app/shared/services/api/masterdata/masterdata-management.service';
import { AppLanguageService } from 'src/app/shared/services/app-language/app-language.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { tapOnError } from 'src/app/shared/utils/rxjsUtils';

@UntilDestroy()
@Component({
  selector: 'app-masterdata-management',
  templateUrl: './masterdata-management.component.html',
  styleUrls: ['./masterdata-management.component.scss']
})
export class MasterdataManagementComponent {
  mdType: (keyof MasterDataEntries);
  mdElement: MasterDataValuesOut;
  options: MasterDataOptions;
  private mdElementsBackup: MasterDataValuesOut[];

  // masterdata
  @ViewChild("modal") modal: ModalComponent | undefined;
  @ViewChild("deleteMDModal") deleteMDModal: ModalComponent | undefined;
  loading = true;
  editing = false;
  mdForm: UntypedFormGroup;
  private deleteElement = null;

  // pagination
  itemsPerPageMD = 10;
  currentPageMD = 1;
  totalMD = 0;

  // relations
  @ViewChild("relationsModal") relationsModal: ModalComponent | undefined;
  loadingRelations = false;
  childValues: MasterDataValuesOut[] = null;
  relationForm: UntypedFormGroup;
  addedRelations = [];
  editingRelation = false;
  // pagination
  itemsPerPageRelations = 10;
  currentPageRelations = 1;
  totalRelations = 0;

  // page reload
  @ViewChild("reloadPageModal") reloadPageModal: ModalComponent | undefined;

  constructor(
    private mdmService: MasterdataManagementService,
    private translateService: TranslateService,
    private notif: NotificationService,
    private appLangService: AppLanguageService,
    private builder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private getMasterdataElementName: GetMasterdataElementNamePipe,
    private router: Router
  ) {
    localStorage.removeItem("lastSearchQuery");
    
    this.options = {
      mdTypes: null,
      mdElements: null,
      mdRelations: null
    }
    this.loadMasterDataTypes();
    this.buildForm();
    this.buildRelationForm();
  }

  private loadMasterDataTypes() {
    this.loading = true;
    this.options["mdTypes"] = null;
    this.mdmService.getMasterDataList()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.options["mdTypes"] = res;
        this.loading = false;
      });
  }

  private loadMasterdataValues() {
    this.loading = true;
    this.options["mdElements"] = null;
    this.mdElementsBackup = null;
    this.mdmService.getElements(this.mdType)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.options["mdElements"] = res;
        this.totalMD = res.length;
        this.currentPageMD = 1;
        this.loading = false;
      });

  }

  masterDataSelected(mdElement: MasterDataValuesOut) {
    this.resetMDElment();
    this.mdElement = mdElement;
  }

  masterdataTypeChanged(type: keyof MasterDataEntries) {
    this.resetMDType();
    this.mdType = type;
    this.loadMasterdataValues();
    this.loadRelations();
  }

  getMDValue(mdElement: MasterDataValuesOut): string {
    if (mdElement) {
      return mdElement.i18n[this.translateService.currentLang].name
    }
  }

  getMDDescription(mdElement: MasterDataValuesOut): string {
    if (mdElement) {
      const desc = mdElement.i18n[this.translateService.currentLang].description;
      return desc ? desc : '';
    }
  }

  resetMDType() {
    this.mdType = null;
    this.resetMDElment();
  }

  resetMDElment() {
    this.mdElement = null;
  }

  getIDPrefix(): string {
    if (!this.mdType) {
      return;
    }
    let prefix = '';
    switch (this.mdType) {
      case "area":
        prefix = "AR_";
        break;
      case "cluster":
        prefix = "CL_";
        break;
      case "field":
        prefix = "FLD_";
        break;
      case "geo_scope":
        prefix = "GEO_";
        break;
      case "initiative":
        prefix = "IN_";
        break;
      case "issuer":
        prefix = "IS_";
        break;
      case "phase":
        prefix = "PH_";
        break;
      case "type":
        prefix = "TP_";
        break;
      default:
        return;
    }
    return prefix;
  }

  addMD() {
    this.modal.open();
  }

  editMD(mdElement: MasterDataValuesOut) {
    this.editing = true;
    this.fillForm(mdElement)
    this.modal.open();
  }

  private updateMDFile() {
    this.mdmService.updateMDFile()
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onUpdateMDFileError(err))
      )
      .subscribe(() => {
        this.reloadPageModal.open();
        // this.masterDataService.loadMasterData()
        //   .pipe(untilDestroyed(this))
        //   .subscribe();
      });
  }

  private onUpdateMDFileError(err: unknown) {
    console.log("Error updating masterdata file", err);
    this.notif.warning("Error updating masterdata file, please contact your support team!");
  }

  deleteMD(mdElement: MasterDataValuesOut) {
    this.deleteElement = mdElement;
    this.deleteMDModal.open();
  }

  confirmDeleteMD() {
    if (this.deleteElement.usages === 0) {
      this.mdmService.deleteElement(this.mdType, this.deleteElement.id)
        .pipe(
          untilDestroyed(this),
          tapOnError(err => this.onDeleteMDError(err)),
          tap(_ => this.onDeleteMDSuccess())
        )
        .subscribe();
    }
  }

  private onDeleteMDError(err: unknown) {
    console.log('Error deleting masterdata', err);
    this.notif.error("NOTIF.DELETE_ERROR");
  }

  private onDeleteMDSuccess() {
    this.notif.success("NOTIF.DELETE_MD_SUCCESS");
    this.updateMDFile();
    this.closeDeleteMDModal();
    this.loadMasterdataValues();
  }

  addNewMD() {
    const formValue = this.mdForm.value;
    if (!this.mdForm.valid) {
      console.log('Invalid form', formValue);
      return;
    }

    this.mdmService.addElement(this.mdType, this.getBody(formValue))
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onAddNewMDError(err)),
        tap(_ => this.onAddNewMDSuccess())
      )
      .subscribe();
  }

  private onAddNewMDError(err: unknown) {
    console.log('Error adding masterdata', err);
    this.notif.error("NOTIF.ADD_ERROR");
  }

  private onAddNewMDSuccess() {
    this.notif.success("NOTIF.ADD_SUCCESS");
    this.updateMDFile();
    this.mdForm.reset();
    this.closeModal();
    this.loadMasterdataValues();
  }

  updateMD() {
    const formValue = this.mdForm.value;
    if (!this.mdForm.valid) {
      console.log('Invalid form', formValue);
      return;
    }

    this.mdmService.updateElement(this.mdType, this.mdForm.get('id').value, this.getBody(formValue))
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onUpdateMDError(err)),
        tap(_ => this.onUpdateMDSuccess())
      )
      .subscribe();
  }

  private onUpdateMDError(err: unknown) {
    console.log('Error updating masterdata', err);
    this.notif.error("NOTIF.UPDATE_ERROR");
  }

  private onUpdateMDSuccess() {
    this.notif.success("NOTIF.UPDATE_SUCCESS");
    this.editing = false;
    this.mdForm.reset();
    this.updateMDFile();
    this.closeModal();
    this.loadMasterdataValues();
  }

  closeModal() {
    this.modal.close();
    this.mdForm.reset();
    this.editing = false;
  }

  closeDeleteMDModal() {
    this.deleteElement = null;
    this.deleteMDModal.close();
  }

  getLangs(): string[] {
    return this.appLangService.getLangs();
  }

  private buildForm() {
    this.mdForm = this.builder.group({
      id: [, Validators.compose([Validators.required, Validators.pattern(validators.masterDataId)])],
      i18n: this.builder.group({})
    });
    const langs = this.getLangs();

    const i18nGroup = this.mdForm.get("i18n") as UntypedFormGroup;
    langs.forEach(lang => {
      i18nGroup.addControl(lang, this.builder.group({
        name: [, Validators.required],
        description: []
      }))
    })
  }

  private fillForm(mdElement: MasterDataValuesOut) {
    const values = { id: mdElement.id, i18n: {} }

    this.getLangs().forEach(lang => {
      values.i18n[lang] = { name: mdElement.i18n[lang].name }
      values.i18n[lang].description = mdElement.i18n[lang].description ? mdElement.i18n[lang].description : null;
    })

    this.mdForm.patchValue(values);
    this.cdr.detectChanges();
  }

  private getBody(formValue: MasterData): MasterData {
    const body = { id: formValue.id, i18n: {} }

    this.getLangs().forEach(lang => {
      body.i18n[lang] = { name: formValue.i18n[lang].name };
      if (formValue.i18n[lang].description) {
        body.i18n[lang].description = formValue.i18n[lang].description;
      }
    })
    return body;
  }

  currentLang(): string {
    return this.translateService.currentLang;
  }

  isActive(mdElement: MasterDataValuesOut): boolean {
    return mdElement.active;
  }

  activateMD(mdElement: MasterDataValuesOut) {
    this.mdmService.updateActive(this.mdType, mdElement.id, true)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onActiveMDError(err)),
        tap(_ => this.onActiveMDSuccess())
      )
      .subscribe();
  }

  private onActiveMDError(err: unknown) {
    console.log('Error activating masterdata', err);
    this.notif.error("NOTIF.ACTIVATE_ERROR");
  }

  private onActiveMDSuccess() {
    this.notif.success("Masterdata was activated successfully");
    this.updateMDFile();
    this.loadMasterdataValues();
  }

  deactivateMD(mdElement: MasterDataValuesOut) {
    this.mdmService.updateActive(this.mdType, mdElement.id, false)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onDeactiveMDError(err)),
        tap(_ => this.onDeactiveMDSuccess())
      )
      .subscribe();
  }

  private onDeactiveMDError(err: unknown) {
    console.log('Error deactivating masterdata', err);
    this.notif.error("NOTIF.DEACTIVATE_ERROR");
  }

  private onDeactiveMDSuccess() {
    this.notif.success("Masterdata was deactivated successfully");
    this.updateMDFile();
    this.loadMasterdataValues();
  }

  search(event: any) {
    const term = event.target.value;
    if (!term || term === "") {
      this.options.mdElements = this.mdElementsBackup;
    }
    else {
      if (!this.mdElementsBackup) {
        this.mdElementsBackup = this.options.mdElements;
      }
      this.options.mdElements = this.mdElementsBackup.filter(elem => this.getMDValue(elem).toLowerCase().includes(term.toLowerCase()));
    }

    this.currentPageMD = 1;
    this.totalMD = this.options.mdElements.length;
  }

  // crud relations
  private loadChildValues(child: keyof MasterDataEntries) {
    this.childValues = null;
    if (!child) {
      return;
    }

    if (this.editingRelation) {
      this.fillAddedValues(child);
    }

    this.mdmService.getElements(child)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.childValues = res;
      });
  }

  getRelations(): { parent: string; child: string; childValue: string; }[] {
    const parent = this.mdElement.id;

    if (!this.options.mdRelations[parent]) {
      return [];
    }

    const childs = Object.keys(this.options.mdRelations[parent])
    const relations = [];

    const resList = childs.map(c => this.options.mdRelations[parent][c].map(value => ({
      parent: parent,
      child: c,
      childValue: value
    })));
    relations.push(...resList);

    return relations.flat();
  }

  private loadRelations() {
    if (this.mdType !== "area" && this.mdType !== "cluster" && this.mdType !== "phase") {
      return;
    }

    this.loadingRelations = true;
    this.options["mdRelations"] = null;
    this.mdmService.getRelations(this.mdType)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => {
          this.loadingRelations = false;
          this.totalRelations = 0;
        })
      )
      .subscribe(res => {
        this.options.mdRelations = res;
        this.totalRelations = res.length;
        this.currentPageRelations = 1;
        this.loadingRelations = false;
      })

  }

  private buildRelationForm() {
    this.relationForm = this.builder.group({
      child: [, Validators.required],
      childKey: [, Validators.required]
    })

    this.relationForm.get('child').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.loadChildValues(res);
      })
  }

  getRelationsTitle(): string {
    const relTitle = this.translateService.instant('LABEL.RELATIONS');
    const parent = this.translateService.instant(`MASTERDATA_TYPES.${this.mdType.toUpperCase()}`);
    const md = this.getMDValue(this.mdElement);
    const children = this.getRelationChilds()
      .map(child => this.translateService.instant(`MASTERDATA_TYPES.${child.toUpperCase()}`))
      .join(', ');
    return children ? `${relTitle} ${parent} ${md} - ${children}` : `${relTitle} ${parent} ${md}`;
  }

  getSelectMessage(elem: string): string {
    return `${this.translateService.instant("MESSAGES.SELECT_ONE")
      .replace(":type", this.translateService.instant(elem))}`;
  }

  hasRelations(): boolean {
    return Object.keys(this.options.mdRelations).length > 0 && this.options.mdRelations[this.mdElement.id];
  }

  closeRelationsModal() {
    this.addedRelations = [];
    this.relationsModal.close();
    if (this.editingRelation) {
      this.editingRelation = false;
    }
    this.relationForm.reset();
  }

  addRelation() {
    this.relationsModal.open();
  }

  editRelation() {
    this.editingRelation = true;
    this.relationsModal.open();
  }

  removeAddedRelation(item: { child: string, childKey: string }) {
    this.addedRelations = this.addedRelations.filter(elem => elem.childKey !== item.childKey);
  }

  updateNewRelations() {
    let body = {};
    if (this.addedRelations.length > 0) {
      const child = this.relationForm.get('child').value;

      // other md
      Object.keys(this.options.mdRelations).forEach(elem => {
        body[elem] = this.options.mdRelations[elem][child];
      })
      // selected md
      body[this.mdElement.id].push(...this.addedRelations.map(rel => rel.childKey));

      this.updateRelation(body);
    }
  }

  private fillAddedValues(child: keyof MasterDataEntries) {
    this.options.mdRelations[this.mdElement.id][child]
      .forEach(element => {
        this.addedRelations.push({
          child: child,
          childKey: element,
          childValue: this.getMasterdataElementName.transform(MasterDataTypes[child.toUpperCase()], element)
        });
      });
  }

  updateExistingRelations() {
    let body = {};
    const child = this.relationForm.get('child').value;

    // other md
    Object.keys(this.options.mdRelations).forEach(elem => {
      body[elem] = this.options.mdRelations[elem][child];
    })

    // selected md
    body[this.mdElement.id] = this.addedRelations.map(rel => rel.childKey);
    this.updateRelation(body);
  }

  updateRelation(body: any) {
    this.mdmService.updateRelation(this.mdType, this.relationForm.get('child').value, body)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onUpdateRelationError(err)),
        tap(_ => this.onUpdateRelationSuccess())
      )
      .subscribe();
  }

  private onUpdateRelationError(err: unknown) {
    if (this.editingRelation) {
      console.log('Error updating relation', err);
      this.notif.error("NOTIF.UPDATE_ERROR");
    } else {
      console.log('Error adding relation', err);
      this.notif.error("NOTIF.ADD_ERROR");
    }
  }

  private onUpdateRelationSuccess() {
    if (this.editingRelation) {
      this.notif.success("NOTIF.UPDATE_SUCCESS");
    }
    else {
      this.notif.success("NOTIF.ADD_SUCCESS");
    }
    this.closeRelationsModal();
    this.updateMDFile();
    this.loadRelations();
  }

  getRelationChilds(): string[] {
    if (!this.mdElement || !this.options.mdRelations[this.mdElement.id]) {
      return []
    }
    return Object.keys(this.options.mdRelations[this.mdElement.id]);
  }

  getRelationChildKeys(child: keyof MasterDataEntries): string[] {
    if (!this.mdElement || !child || !this.childValues) {
      return [];
    }

    // filter used values
    return this.childValues.filter(elem =>
      !(Object.keys(this.options.mdRelations)
        .map(parentValue => this.options.mdRelations[parentValue][child])
        .flat()
      ).includes(elem.id)).map(res => res.id)
  }

  addRelationToList() {
    if (!this.relationForm.invalid) {
      const { child, childKey } = this.relationForm.value;
      if (!this.addedRelations.find(elem => elem.childKey === childKey)) {
        this.addedRelations.push({
          child: child,
          childKey: childKey,
          childValue: this.getMasterdataElementName.transform(MasterDataTypes[child.toUpperCase()], childKey)
        });
      }
      this.relationForm.get('childKey').reset();
    }
  }

  // pagination
  changeMDPage(event: any) {
    this.currentPageMD = event;
  }

  changeRelationsPage(event: any) {
    this.currentPageRelations = event;
  }

  // reload page
  closeReloadPageModal() {
    this.reloadPageModal.close();
  }

  reloadPage() {
    location.reload();
  }
}
