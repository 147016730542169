import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { filter } from "rxjs/operators";
import { UserActionsService } from "src/app/shared/services/actions/user-actions.service";
import { UserService } from "src/app/shared/services/api/user/user.service";
import { AppLanguageService } from "src/app/shared/services/app-language/app-language.service";
import { AWSService } from "src/aws/aws.service";

@UntilDestroy()
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  appLang;
  toogleMenu = false;
  menu = null;
  sessionInfo: {
    username: string,
  } = null;

  userDetails = null;

  constructor(
    private userActions: UserActionsService,
    private userService: UserService,
    private awsService: AWSService,
    private appLangService: AppLanguageService,
    private router: Router
  ) {
    this.getActions();
    this.getSessionInfo();
    this.getUserDetails();
  }

  ngOnInit(): void {
    this.appLang = this.appLangService.getSetUpLanguage();

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(r => r instanceof NavigationEnd)
      ).subscribe(r => {
        if (screen.availWidth <= 768) {
          this.toogleMenu = false;
        }
      })
  }

  private getActions() {
    return this.userActions
      .getMenuOptions()?.pipe(
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.menu = res;
      });
  }

  private getSessionInfo() {
    return this.userService
      .getSessionInfo().pipe(
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.sessionInfo = res;
      });
  }

  private getUserDetails() {
    this.userService.getUserDetails()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.userDetails = res;
      })
  }

  logout(): void {
    this.awsService.logout();
  }

  getLangs(): string[] {
    return this.appLangService.getLangs();
  }

  onLanguageChange() {
    this.appLangService.changeAppLanguage(this.appLang);
  }
}
