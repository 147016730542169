import { Component } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Company } from "src/app/shared/models/company.model";
import { CompanyService } from "src/app/shared/services/api/company/company.service";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { tapOnError } from "src/app/shared/utils/rxjsUtils";

@UntilDestroy()
@Component({
  selector: "app-edit-company",
  templateUrl: "./edit-company.component.html",
  styleUrls: ["./edit-company.component.css"],
})
export class EditCompanyComponent {
  companyForm: UntypedFormGroup;
  isValid = false;
  companyId = null;
  companyData: Company = null;

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private notif: NotificationService
  ) {
    localStorage.removeItem("lastSearchQuery");
    
    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.companyId = params["id"];
        this.getCompanyData();
      });
  }

  formChanged(event: any): void {
    this.companyForm = event;
    this.isValid = this.companyForm.valid;
  }

  getCompanyData(): void {
    this.companyService
      .getCompany(this.companyId)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.companyData = res;
      });
  }

  updateCompany(): void {
    this.companyService
      .updateCompany(this.companyId, this.companyForm.value)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onUpdateError(err)),
        tap(_ => this.onUpdateSuccess())
      ).subscribe();
  }

  addCompany(): void {
    this.router.navigate(["administration", "register-company"]);
  }

  isActive(): boolean {
    return this.companyData.active;
  }

  hasUsers(): boolean {
    return this.companyData.num_users > 0;
  }

  activateCompany(): void {
    this.companyService.updateActive(this.companyId, true)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onActivateError(err)),
        tap(_ => this.onActivateSuccess())
      )
      .subscribe();
  }

  deactiveCompany(): void {
    this.companyService.updateActive(this.companyId, false)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onDeactivateError(err)),
        tap(_ => this.onDeactivateSuccess())
      )
      .subscribe();
  }

  deleteCompany(): void {
    this.companyService
      .deleteCompany(this.companyId)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onDeleteError(err)),
        tap(_ => this.onDeleteSuccess())
      )
      .subscribe();
  }

  onActivateError(err: unknown) {
    console.log('Error activating company', err);
    this.notif.error("NOTIF.ACTIVATE_COMPANY_ERROR");
  }

  onActivateSuccess() {
    this.notif.success("NOTIF.CREATE_COMPANY_SUCCESS");
    this.router.navigate(["administration", "show-company", this.companyId]);
  }

  onDeactivateError(err: unknown) {
    console.log('Error deactivating company', err);
    this.notif.error("NOTIF.DEACTIVATE_COMPANY_ERROR");
  }

  onDeactivateSuccess() {
    this.notif.success("NOTIF.DEACTIVATE_COMPANY_SUCCESS");
    this.router.navigate(["administration", "show-company", this.companyId]);
  }

  onDeleteError(err: unknown) {
    console.log('Error deleting company', err);
    this.notif.error("NOTIF.DELETE_COMPANY_ERROR");
  }

  onDeleteSuccess() {
    this.notif.success("NOTIF.DELETE_COMPANY_SUCCESS");
    this.router.navigate(["administration", "search-company"]);
  }

  onUpdateError(err: unknown) {
    console.log('Error updating company', err);
    this.notif.error("NOTIF.UPDATE_COMPANY_ERROR");
  }

  onUpdateSuccess() {
    this.notif.success("NOTIF.UPDATE_COMPANY_SUCCESS");
    this.router.navigate(["administration", "show-company", this.companyId]);
  }
}
