import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { Rule, RuleCreate, RuleDetail, RuleFilter } from "src/app/shared/models/rules.model";
import { SearchModelInput, SearchModelOutput } from "src/app/shared/models/search.model";
import { noErrorHandling } from "src/app/shared/utils/RequestUtils";
import { NotificationService } from "../../notification/notification.service";

@Injectable({
  providedIn: "root",
})
export class RulesService {

  constructor(private http: HttpClient, private notif: NotificationService) { }

  searchAndSaveRules(query: SearchModelInput<RuleFilter>): Observable<SearchModelOutput<Rule>> {
    return this.http.post<SearchModelOutput<Rule>>("/rule/search", query)
      .pipe(
        tap((res) => {
          if (Object.keys(query.query).length !== 0 && res["items"].length > 0) {
            this.saveSearchRules(query.query)
              .pipe(catchError(_err => {
                this.notif.error("NOTIF.SAVE_SEARCH_ERROR");
                return of(res);
              }))
              .subscribe()
          }
        })
      );
  }

  searchRules(query: SearchModelInput<RuleFilter>): Observable<SearchModelOutput<Rule>> {
    return this.http.post<SearchModelOutput<Rule>>("/rule/search", query);
  }

  saveSearchRules(query: RuleFilter): Observable<void> {
    return this.http.post<void>(`/rule/search/history`, { query: query }, {
      ...noErrorHandling()
    });
  }

  getRule(idRule: string): Observable<RuleDetail> {
    return this.http.get<RuleDetail>(`/rule/${idRule}`);
  }

  deleteRule(idRule: string): Observable<void> {
    return this.http.delete<void>(`/rule/${idRule}`);
  }

  createRule(body: RuleCreate): Observable<string> {
    return this.http.post<{ id: string }>(`/rule`, body, {
      ...noErrorHandling()
    })
      .pipe(map((res) => res.id));
  }

  createAndValidate(body: RuleCreate): Observable<string> {
    return this.createRule(body).pipe(
      mergeMap((id) => this.validate(id))
    )
  }
  editAndValidate(idRule: string, body: RuleCreate): Observable<void> {
    return this.editRule(idRule, body).pipe(
      mergeMap((_) => this.validate(idRule)),
      map(_ => null),
    )
  }
  editRule(idRule: string, body: RuleCreate): Observable<string> {
    return this.http.put<void>(`/rule/${idRule}`, body).pipe(
      map(_ => idRule)
    );
  }

  validate(id: string): Observable<string> {
    return this.http.post<void>(`/rule/${id}/validate`, {}).pipe(
      map(_ => id)
    );
  }

  getPathToRule(id: string): string {
    return `/rules/show-rule/${id}`;
  }
}
