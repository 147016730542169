import { ControlKey } from "./rule-form.form";


export const datesByPhase: {[key: string]: ControlKey[]} = {
    'PH_NOR': ['publication_date', 'effective_date', 'approval_date', 'application_date'],
    'PH_CI': ['publication_date', 'effective_date', 'application_date', 'closing_date'],
    'PH_JUR': ['publication_date'],
    'PH_IN': ['publication_date', 'approval_date', 'closing_date'],
    'PH_OTR': ['publication_date']
}
