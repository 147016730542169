<nav class="bg-black text-white relative">
  <div class="relative flex items-center justify-between h-16 lg:hidden">
    <div class="absolute inset-y-0 left-0 w-full flex items-center">
      <button type="button" (click)="toogleMenu = !toogleMenu" class="inline-flex items-center justify-center p-2 rounded-md
          text-gray-400 hover:text-white
          focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
        <span class="sr-only">Open main menu</span>
        <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
          stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
          stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <img class="cursor-pointer" routerLink="/" src="assets/imgs/black_logo.png" />
    </div>
  </div>
  <div class="hidden lg:flex lg:flex-wrap menu gap-12">
    <img class="cursor-pointer ml-12" routerLink="/" src="assets/imgs/black_logo.png" />

    <div *ngFor="let menuItem of menu;">
      <div *ngIf="menuItem.path" class="flex items-center cursor-pointer relative w-auto h-auto nav_80">
        <a routerLink="{{menuItem.path}}" routerLinkActive="active">
          {{ "MENU_HEADER." + menuItem.label | uppercase | translate }}
        </a>
      </div>

      <div *ngIf="menuItem.options" class="flex items-center cursor-pointer relative w-auto h-auto nav_80">
        <a *ngIf="!menuItem.path" role="button">
          {{ "MENU_HEADER." + menuItem.label | uppercase | translate }}
        </a>

        <ul class="hidden bg-deloitte_gray9">
          <li *ngFor="let menuOption of menuItem.options">
            <a class="flex py-5 px-5 text-tiny" routerLink="{{menuOption.path}}" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">{{
              "MENU_OPTIONS." + menuOption.label | uppercase | translate }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="menu-right">
      <div *ngIf="userDetails" class="userInfo">
        <span>{{userDetails.given_name}} </span>
        <span>{{userDetails.family_name}}</span>
      </div>

      <div class="app-lang-option">
        <select [(ngModel)]="appLang" (change)="onLanguageChange()">
          <option *ngFor="let lang of getLangs()" [value]="lang">
            {{"LANGUAGE." + lang | uppercase | translate }}
          </option>
        </select>
      </div>

      <button *ngIf="sessionInfo" class="w-28 mr-1 cursor-pointer justify-center text-deloitte_green" (click)="logout()">{{"BUTTON.LOGOUT" | translate}}</button>
    </div>
  </div>

  <!-- Menu for mobilephones and tablets -->
  <div [ngClass]="toogleMenu ? 'block lg:hidden' : 'hidden'" id="mobile-menu">
    <div *ngFor="let menuItem of menu">
      <div *ngIf="menuItem.path" class="border-b border-b-deloitte_gray9">
        <a class="block px-5 py-3 text-sm bg-menu" routerLink="{{menuItem.path}}" routerLinkActive="active">{{
          "MENU_HEADER." + menuItem.label | uppercase | translate }}</a>
      </div>

      <div *ngIf="menuItem.options" class="border-b border-b-deloitte_gray9">
        <a *ngIf="!menuItem.path" class="block relative px-5 py-3 text-sm bg-menu">
          {{ "MENU_HEADER." + menuItem.label | uppercase | translate }}
          <div class="
              pointer-events-none
              absolute
              inset-y-0
              right-3
              flex
              items-center
              px-2
              text-white
            ">
            <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </a>

        <ul class="hidden pt-1 bg-deloitte">
          <li class="block px-5 py-3 text-sm bg-black" *ngFor="let menuOption of menuItem.options">
            <a routerLink="{{menuOption.path}}" routerLinkActive="active">
              {{ "MENU_OPTIONS." + menuOption.label | uppercase | translate}}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="self-center py-3 flex justify-around">
      <div *ngIf="userDetails" class="userInfo">
        <span>{{userDetails.given_name}}</span>
        <span>{{userDetails.family_name}}</span>
        <span>{{userDetails.email}}</span>
      </div>
    </div>

    <div class="self-center py-3 flex justify-around">
      <div class="mobile-app-lang-option">
        <label>{{"SECTION.LANGUAGE" | translate}}</label>
        <select [(ngModel)]="appLang" (change)="onLanguageChange()">
          <option *ngFor="let lang of getLangs()" [value]="lang">
            {{"LANGUAGE." + lang | uppercase | translate }}
          </option>
        </select>
      </div>

      <ng-container *ngIf="sessionInfo">
        <app-button class="w-full" theme="delete" (click)="logout()" text="LOGOUT"></app-button>
      </ng-container>
    </div>
  </div>
</nav>
