import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Url } from 'src/app/shared/models/other-data.model';
import { RuleFilter } from 'src/app/shared/models/rules.model';
import { noErrorHandling } from 'src/app/shared/utils/RequestUtils';

@Injectable({
  providedIn: 'root'
})
export class ExportPdfService {

  constructor(private http: HttpClient) { }

  exportPdfSearch(filters: RuleFilter): Observable<string> {
    return this.http.post<Url>(`/search/export/pdf`, filters, { ...noErrorHandling() })
      .pipe(map(res => res.url));
  }

  exportToPdf(textId: string): Observable<string> {
    return this.http.get<Url>(`/rule/${textId}/export/pdf`, { ...noErrorHandling() })
      .pipe(map(res => res.url));
  }
}
