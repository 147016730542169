<div [formGroup]="group">
  <input (input)="search($event)" (focus)="focus()"
    ngClass="{{
      customInputCSS ? customInputCSS :
      'w-full border rounded focus:outline-none appearance-none py-3 px-2 border-deloitte_gray9 text-sm'
    }}"
    type="text" />

  <div *ngIf="show" class="
      relative
      border border-deloitte_gray
      inset-x-0 z-10
      bottom-auto
      max-h-32
      bg-white
      overflow-y-auto
  " style="min-height: 14rem;">
    <div class="grid">
      <label class="inline-flex px-3 py-2 items-center w-full p-2" *ngIf="showAll">
        <input
          id="default"
          type="checkbox" class="form-radio text-indigo-600" [value]="null" [checked]="data().value.length === 0"
          (change)="onCheckboxChange($event, null)"
        />
        <span class="ml-2 text-sm">{{ "LABEL.SHOW_ALL" | translate }}</span>
      </label>

      <div class="flex" *ngFor="let option of searchItems; let i = index">
        <label class="inline-flex px-3 py-2 items-center w-full p-2">
          <input type="checkbox" class="form-radio text-indigo-600" [value]="option['id']"
            (change)="onCheckboxChange($event, option)" [checked]="isChecked(option)" formArrayName="data" />
          <span class="ml-2 text-sm">{{ option["name"] }}</span>
        </label>
      </div>
    </div>
  </div>

  <div>
    <span *ngFor="let option of data().value" class="
        inline-flex
        items-center
        justify-center
        px-3
        py-2
        mt-2
        mr-3
        text-xs
        font-bold
        leading-none
        text-black
        deloitte_{{option['parentId'] ?? ''}}
        rounded
        cursor-pointer
      " (click)="remove(option)">{{ option["name"] ? option["name"] : option }} &times;
    </span>
  </div>
</div>
