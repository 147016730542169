import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { S3Service } from 'src/aws/services/s3Service/s3.service';
import validators from 'src/app/core/config/validators';

@UntilDestroy()
@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {
  @Output() formChanged = new EventEmitter();
  @Input() companyId: number;
  @Input() companyData?= null;
  companyForm: UntypedFormGroup;
  options: any;
  logo: any;

  constructor(
    private s3Service: S3Service,
    private builder: UntypedFormBuilder
  ) {
    this.companyForm = this.builder.group({
      name: ['', Validators.required],
      logo: this.builder.group({
        title: [''],
        link: ['']
      }),
      domain: ['', Validators.compose([
        Validators.required,
        Validators.pattern(validators.domain)
      ])]
    });

    this.companyForm
      .valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.formChanged.emit(this.companyForm));
  }

  ngOnInit(): void {
    if (this.companyData) {
      this.fillForm();

      this.s3Service.getFileURL(this.companyData?.logo.link)
        .pipe(untilDestroyed(this))
        .subscribe(url => {
          this.logo = url;
        });
    }
  }

  fillForm() {
    this.companyForm.get('name').setValue(this.companyData?.name);
    this.companyForm.get('logo').setValue({
      title: this.companyData?.logo.title,
      link: this.companyData?.logo.link
    })
    this.companyForm.get('domain').setValue(this.companyData.domain);
  }

  updateFile(ev: any): void {
    if (ev.target.files) {
      const { name } = ev.target.files[0];

      if (
        name.endsWith(".jpg") || name.endsWith(".jpeg") || name.endsWith(".png") ||
        name.endsWith(".JPG") || name.endsWith(".JPEG") || name.endsWith(".PNG")
      ) {
        const logo: File = ev.target.files[0];

        this.s3Service.uploadImage(logo)
          .pipe(untilDestroyed(this))
          .subscribe((Key) => {
            this.s3Service.getFileURL(Key)
              .pipe(untilDestroyed(this))
              .subscribe(url => {
                this.logo = url;
              });

            this.companyForm.get('logo')
              .setValue({ title: name, link: Key });
          });
      }
    }
  }

  removeLogo(): void {
    this.logo = null;
    this.companyForm.get('logo').reset();
  }

  downloadLogo() {
    window.open(this.logo, "_blank");
  }
}
