<form [formGroup]="companyForm">
  <div>
    <div class="section">
      <div class="aside">
        <label></label>
      </div>

      <div class="content">
        <div>
          <label for="name">
            {{"label.name" | uppercase | translate}}
            <span
              class="red"
              *ngIf="companyForm.get('name').hasError('required')"
            > *</span>
          </label>
          <input autocomplete="off" name="name" type="text" formControlName="name" />
        </div>
      </div>
    </div>

    <div class="section">
      <div class="aside">
        <label></label>
      </div>

      <div class="content">
        <div>
          <label for="domain">
            {{"label.domain" | uppercase | translate}}
            <span class="red" *ngIf="companyForm.get('domain').hasError('required')"> * </span>
          </label>

          <div class="input-error-bottom" *ngIf="companyForm.get('domain').hasError('pattern')">
            <span class="red mb-1" >{{"VALIDATORS.DOMAIN" | translate}}</span>
          </div>
          <input autocomplete="off" name="domain" type="text" formControlName="domain" />
        </div>
      </div>
    </div>

    <div class="section">
      <div class="aside">
        <label></label>
      </div>

      <div class="content">
        <div>
          <label for="logo">
            {{"LABEL.logo" | uppercase | translate}}
            <span
              class="red"
              *ngIf="companyForm.get('logo').get('link').hasError('required')"
            > *</span>
          </label>
        </div>

        <div class="button-wrapper">
          <span>
            {{"BUTTON.SELECT_LOGO" | uppercase | translate}}
          </span>
          <input
            (change)="updateFile($event)"
            class="upload-file"
            name="logo" type="file" accept=".jpg,.jpeg,.png"
          />
        </div>

        <div id="logo-message" *ngIf="!companyForm.get('logo').get('link').value">
          {{ "MESSAGES.LOGO_FORMAT" | uppercase | translate }}
        </div>

        <div *ngIf="logo">
          <a class="download" (click)="downloadLogo()">
            <img src="{{ logo }}" alt="company logo" />
          </a>
          <button (click)="removeLogo()">
            <img class="p-2" src="assets/svg/remove.svg" />
            {{ "BUTTON.REMOVE" | uppercase | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
