<div *ngIf="!rulesData" class="loader">
  <app-loader></app-loader>
</div>

<div *ngIf="rulesData" class="container flex-col md:flex-row flex items-stretch relative">
  <div [ngClass]="expandAside ? '' : '-left-full'" style="height: fit-content; min-height: 100%"
    class="w-full md:w-3/12 absolute top-0 left-0 bottom-0 block md:flex-auto bg-white z-20 text-sm">
    <div class="px-3 relative">
      <app-button class="absolute top-0 right-0" theme="toggle" (click)="toggle()">
        <img src="assets/svg/Icon-Close@2x.svg" />
      </app-button>

      <p class="title text-xl mt-2 text-black font-bold">
        {{ "SIDEFILTER.SEARCH" | translate }}
      </p>

      <div [ngClass]="{
            'overflow-hidden': this.expanded,
            'overflow-hidden expand_test': !this.expanded
          }">
        <div *ngIf="search_record?.filters?.title" class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div class="w-full font-bold mt-2">
            {{ "LABEL.TITLE" | uppercase | translate }}
          </div>
          <div class="w-full flex flex-wrap">
            <span class="mb-2">{{ search_record?.filters?.title }}</span>
          </div>
        </div>

        <div *ngIf="search_record?.filters?.lang" class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div class="w-full font-bold mt-2">
            {{ "LABEL.LANGUAGE" | uppercase | translate }}
          </div>
          <div class="w-full flex flex-wrap">
            <span class="tag p-2 mr-2 mb-2 rounded">
              {{ "Language." + search_record?.filters?.lang | uppercase | translate }}</span>
          </div>
        </div>

        <div *ngIf="wasFilteredByValidation()" class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div class="w-full font-bold mt-2">
            {{ "LABEL.VALIDATION_STATUS" | uppercase | translate }}
          </div>
          <div class="w-full flex flex-wrap">
            <span class="tag p-2 rounded mr-2 mb-2">
              {{ getValidated(search_record?.filters?.validated) | translate }}
            </span>
          </div>
        </div>

        <div *ngIf="
              search_record?.filters?.areas ||
              (search_record?.filters?.fields &&
                search_record?.filters?.fields.length > 0)
            " class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
            <div *ngIf="search_record?.filters?.areas && search_record?.filters?.areas.length > 0">
            <div class="w-full font-bold mt-2">
              {{ "LABEL.AREA" | uppercase | translate }}
            </div>
            <div class="w-full flex flex-wrap" *ngFor="let area of search_record?.filters?.areas">
              <span class="tag p-2 rounded mr-2 mb-2">
                {{ area | getMasterDataElement: "area":actual_lang }}
              </span>
            </div>
          </div>

          <div *ngIf="
                search_record?.filters?.fields &&
                search_record?.filters?.fields.length > 0
              " class="w-full font-bold mt-2">
            {{ "LABEL.FIELD" | uppercase | translate }}
          </div>
          <div *ngIf="
                search_record?.filters?.fields &&
                search_record?.filters?.fields.length > 0
              " class="w-full flex flex-wrap">
            <span class="tag p-2 rounded mr-2 mb-2" *ngFor="let field of search_record?.filters?.fields">{{ field |
              getMasterDataElement: "field":actual_lang }}</span>
          </div>
        </div>

        <div *ngIf="
              search_record?.filters?.initiatives &&
              search_record?.filters?.initiatives.length > 0
            " class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div class="w-full font-bold mt-2">
            {{ "LABEL.INITIATIVES" | uppercase | translate }}
          </div>
          <div class="w-full flex flex-wrap">
            <span class="tag p-2 rounded text-sm mr-2 mb-2"
              *ngFor="let initiative of search_record?.filters.initiatives">{{
              initiative | getMasterDataElement: "initiative":actual_lang
              }}</span>
          </div>
        </div>

        <div *ngIf="
              search_record?.filters?.publication_date &&
              search_record?.filters?.publication_date.length > 0
            " class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div *ngIf="search_record?.filters?.publication_date[0]" class="w-full font-bold mt-2">
            {{ "LABEL.START_PUBLICATION_DATE" | uppercase | translate }}
          </div>
          <div *ngIf="search_record?.filters?.publication_date" class="w-full">
            {{ search_record?.filters?.publication_date[0] }}
          </div>
          <div *ngIf="search_record?.filters?.publication_date[1]" class="w-full font-bold mt-2">
            {{ "LABEL.FINISH_PUBLICATION_DATE" | uppercase | translate }}
          </div>
          <div *ngIf="search_record?.filters?.publication_date[1]" class="w-full">
            {{ search_record?.filters?.publication_date[1] }}
          </div>
        </div>

        <div *ngIf="
              search_record?.filters?.cluster ||
              (search_record?.filters?.geo_scopes &&
                search_record?.filters?.geo_scopes.length > 0)
            " class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div *ngIf="search_record?.filters?.cluster" class="w-full font-bold mt-2">
            {{ "LABEL.TYPE_GEOSCOPE" | uppercase | translate }}
          </div>
          <div *ngIf="search_record?.filters?.cluster" class="w-full flex flex-wrap">
            <span class="tag p-2 rounded mr-2 mb-2">
              {{
              search_record?.filters?.cluster
              | getMasterDataElement: "cluster":actual_lang
              }}
            </span>
          </div>
          <div *ngIf="
                search_record?.filters?.geo_scopes &&
                search_record?.filters?.geo_scopes.length > 0
              " class="w-full font-bold mt-2">
            {{ "LABEL.GEOSCOPE" | uppercase | translate }}
          </div>

          <div *ngIf="
                search_record?.filters?.geo_scopes &&
                search_record?.filters?.geo_scopes.length > 0
              " class="w-full flex flex-wrap">
            <span class="tag p-2 rounded mr-2 mb-2" *ngFor="let geoscope of search_record?.filters?.geo_scopes">{{
              geoscope | getMasterDataElement: "cluster":actual_lang
              }}</span>
          </div>
        </div>

        <div *ngIf="
              search_record?.filters?.issuers &&
              search_record?.filters?.issuers.length > 0
            " class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div class="w-full font-bold mt-2">
            {{ "LABEL.ISSUING_BODY" | uppercase | translate }}
          </div>
          <div class="w-full flex flex-wrap">
            <span class="tag p-2 rounded mr-2 mb-2" *ngFor="let issuer of search_record?.filters?.issuers">{{ issuer |
              getMasterDataElement: "issuer":actual_lang }}</span>
          </div>
        </div>

        <div *ngIf="search_record?.filters?.phases" class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div class="w-full font-bold mt-2">
            {{ "LABEL.TYPE_RULE" | uppercase | translate }}
          </div>
          <div class="w-full flex flex-wrap">
            <span class="tag p-2 rounded mr-2 mb-2">
              {{
              search_record?.filters?.phases
              | getMasterDataElement: "phase":actual_lang
              }}
            </span>
          </div>
        </div>

        <div *ngIf="search_record?.filters?.processing_status"
          class="w-full border-b-2 border-b-deloitte_gray2 mt-8 pb-6">
          <div class="w-full font-bold mt-2">
            {{ "LABEL.processing_status" | uppercase | translate }}
          </div>
          <div class="w-full flex flex-wrap">
            <span class="tag p-2 rounded mr-2 mb-2">
              {{ getState(search_record?.filters?.processing_status) | translate }}
            </span>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <app-button *ngIf="!isFavourite" theme="deloitteunderline" text="ADD_FAVOURITE" (click)="addToFavourites()">
        </app-button>
        <app-button *ngIf="isFavourite" theme="deloitteunderline" text="DELETEFAVOURITE"
          (click)="removeFromFavourites()"></app-button>
      </div>
    </div>
  </div>
  <!-- Icono Lupa -->
  <div [ngClass]="expandAside ? 'lg:w-3/12' : 'lg:w-1/12'" class="block md:flex-auto text-center pt-8">
    <app-button class="absolute top-3 right-3 lg:top-0 lg:right-0 lg:relative" theme="toggle"
      (click)="expandAside = !expandAside">
      <img src="assets/imgs/search.png" />
    </app-button>
  </div>
  <!-- Listado de normas y paginacion -->
  <div [ngClass]="
        expandAside
          ? 'lg:w-9/12 hidden border-l-0 md:border-l-[11.5rem] lg:border-l-0'
          : 'lg:w-11/12'
      " class="w-full md:block md:flex-auto px-4">
    <app-breadcrum name="{{ getFormatES() }}"></app-breadcrum>
    <span *ngIf="!isFavourite" class="font-bold my-6 inline-block text-2xl">
      {{ "LABEL.SEARCH" | translate }}
      {{ ", " + getFormatES() }}
    </span>

    <span *ngIf="isFavourite" class="font-bold my-6 inline-block text-2xl">
      {{ search_record?.title }}
    </span>

    <!-- Lista normas -->
    <div class="overflow-auto">
      <app-rule *ngFor="
            let rule of rulesData?.items
              | paginate
                : {
                    itemsPerPage: itemsPerPage,
                    currentPage: currentPage,
                    totalItems: total
                  }
          " [rule]="rule" [index]="rule?.id"></app-rule>

      <div *ngIf="rulesData && rulesData?.items.length === 0" class="flex justify-center mt-8">
        <h1 class="p-3 font-bold text-2xl md:text-3xl">
          {{ "LABEL.NO_RESULTS" | translate }}
        </h1>
      </div>
    </div>

    <div *ngIf="rulesData" class="w-full block text-center mt-8">
      <pagination-controls (pageChange)="changePage($event)" [directionLinks]="true" [responsive]="true"
        previousLabel="PREV" nextLabel="NEXT" [autoHide]="true"></pagination-controls>
    </div>
  </div>
</div>

<!-- Modal añadir favorito -->
<app-modal [formGroup]="addFavForm" #addFavModal *ngIf="!isFavourite">
  <div (click)="closeModal()" class="absolute top-3 right-3">
    <img class="scale-75" src="assets/svg/Icon-Close@2x.svg" />
  </div>
  <div class="text-2xl mb-6 block font-bold text-left">
    <span>{{ "SECTION.ADD_FAVOURITE" | translate }}</span>
  </div>
  <div style="text-align: center;">
    <input type="text" class="input-form mb-1" formControlName="name">
    <app-button theme="main" (click)="confirmAddToFavourites()" [disabled]="addFavForm.get('name').hasError('required')"
      text="SAVE"></app-button>
  </div>
</app-modal>

<!-- Modal borrar favorito -->
<app-modal #removeFavModal *ngIf="isFavourite">
  <div (click)="closeModal()" class="absolute top-3 right-3">
    <img class="scale-75" src="assets/svg/Icon-Close@2x.svg" />
  </div>
  <div>
    <app-button theme="delete_red" [disabled]="!isValid" (click)=" removeFromFavourites()" text="DELETE"></app-button>
  </div>
</app-modal>
