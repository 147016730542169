<div class="loader p-3 font-bold text-1xl">
  <div class="flex w-full md:w-auto text-black text-2xl font-bold text-center">
    <h1>{{ 'LABEL.REGISTER_USER_MSG' | translate }}</h1>
  </div>

</div>
<div class="loader " style="margin-top: 4rem; display:grid; gap: 3rem; grid-auto-flow: column;">

  <app-button theme="main" (click)="back()" text="RETURN"></app-button>
  <app-button theme="main" (click)="redirectToHome()" text="RETURN_HOME"></app-button>
</div>
