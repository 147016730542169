<div *ngIf="!user" class="flex justify-center mt-6">
  <app-loader></app-loader>
</div>

<div class="container" *ngIf="user">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-3 md:mb-10">
      <div class="
            col-start-0
            col-span-4
            md:col-span-3
            px-28
            md:px-28
          ">
        <app-breadcrum></app-breadcrum>
        <div class="w-full mt-4">
          <span class="font-bold">{{
            "MENU_OPTIONS.SHOW-USER" | translate
            }}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="user" class="container">
    <div *ngIf="user.notInDB" class="w-full">
        <div class="text-sm mr-8 flex justify-end" style="color: #DA291C">* {{"MESSAGES.USER_NOT_IN_DB" | translate }}</div>
      </div>
      <div class="mx-0 xl:mx-8">

      <div class=" pb-4 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{"LABEL.ROLE" | translate }}</label>
          </div>
        <div *ngIf="user?.groups?.length>0" class="w-full md:w-9/12 lg:w-7/12 md:px-0 text-sm">
          <ul class="grid grid-cols-1 gap-1">
            <li class="text-sm" *ngFor="let group of user?.groups">
              {{ group }}
            </li>
          </ul>
        </div>

        <div *ngIf="!user?.groups?.length || user?.groups?.length === 0" class="w-full md:w-9/12">
          <div class="text-sm"><span style="color: #DA291C"
            >*</span
          >{{"LABEL.NO_GROUPS" | translate }}</div>
        </div>

      </div>
    </div>

      <div class="mt-6 pb-4 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{"LABEL.EMAIL" | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ user?.email }}</div>
          </div>
        </div>
      </div>

      <div class="mt-6 pb-4 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{"LABEL.NAME" | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ user?.name }}</div>
          </div>
        </div>
      </div>

      <div class="mt-6 pb-4 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{"LABEL.SURNAME" | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ user?.lastname }}</div>
          </div>
        </div>
      </div>

      <div class="mt-6 pb-4 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{"LABEL.COMPANY" | translate }}</label>
          </div>

          <app-loader *ngIf="loadingCompany" diameter="20"></app-loader>


          <div *ngIf="!loadingCompany">

            <div *ngIf="user?.company" class="w-full md:w-9/12">
            <div class="text-sm" >{{ user?.company }}</div>
          </div>
          <div *ngIf="!user?.company" class="w-full md:w-9/12">
            <div class="text-sm" ><span style="color: #DA291C"
              >*</span
            >{{"LABEL.NO_COMPANY" | translate }}</div>
          </div>
          </div>

        </div>
      </div>

      <div class="mt-6 pb-4 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{"LABEL.FIELDS" | translate }}</label>
          </div>
        <div *ngIf="user?.interests?.length>0" class="w-full md:w-9/12 lg:w-7/12 md:px-0 text-sm">
          <ul class="grid grid-cols-1 gap-1">
            <li class="text-sm" *ngFor="let interest of user?.interests">
              {{ interest | getMasterDataElement: "field":currentLang() }}
            </li>
          </ul>
        </div>
        <div *ngIf="!user?.interests?.length || user?.interests?.length === 0" class="w-full md:w-9/12">
          <div class="text-sm"><span style="color: #DA291C"
            >*</span
          >{{"LABEL.NO_FIELDS" | translate }}</div>
        </div>
      </div>
    </div>

      <div class="mt-6 pb-4 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{"LABEL.ALERTS" | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div *ngIf="user?.receiveAlerts" class="text-sm">{{"BUTTON.YES" | uppercase | translate  }}</div>
            <div *ngIf="!user?.receiveAlerts" class="text-sm">{{ "BUTTON.NO" | uppercase | translate  }}</div>
          </div>
        </div>
      </div>

      <div class="mt-6 pb-4 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{"LABEL.NEWSLETTER" | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div *ngIf="user?.emailNewsletter" class="text-sm">{{"BUTTON.YES" | uppercase | translate  }}</div>
            <div *ngIf="!user?.emailNewsletter" class="text-sm">{{ "BUTTON.NO" | uppercase | translate  }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full hidden md:block">
    <div class="w-full md:flex md:flex-row md:justify-around px-2.5 py-5 md:gap-2 lg:px-12 xl:px-40">
      <div class="flex flex-col md:flex-row md:flex-wrap gap-2 md:items-center">
        <app-button theme="main" text="EDIT" (click)="editUser()"></app-button>
        <app-button theme="main" text="RETURN" (click)="back()"></app-button>

      </div>
    </div>
  </div>
</div>
