import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GetMasterDataPipe } from 'src/app/shared/pipes/get-masterdata/get-masterdata.pipe';
import { GetMasterdataElementNamePipe } from 'src/app/shared/pipes/get-masterdata-element-name/get-masterdata-element-name.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MasterDataService } from 'src/app/shared/services/master-data/master-data.service';
import { MasterDataTypes } from 'src/app/shared/models/master-data.model';

@UntilDestroy()
@Component({
  selector: 'app-search-alerts',
  templateUrl: './search-alerts.component.html',
  styleUrls: ['./search-alerts.component.scss']
})
export class SearchAlertsComponent implements OnInit {
  searchAlertsForm: UntypedFormGroup;
  options;
  recipientsList = [];
  @Input() reset;
  @Output() formChanged = new EventEmitter();
  @Output() resetValues = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private masterDataService: MasterDataService,
    private translateService: TranslateService,
    private getMasterData: GetMasterDataPipe,
    private getMasterdataElementName: GetMasterdataElementNamePipe
  ) {
    this.searchAlertsForm = this.formBuilder.group({
      title: [],
      recipient: [],
      language: [],
      area: [],
      fields: [[]],
      date: this.formBuilder.array([]),
    });

    this.options = {
      langs: this.translateService.getLangs().map(e => e.toUpperCase()),
      areas: this.getMasterData.transformFiltered(MasterDataTypes.AREA),
      fields: [],
      recipients: this.recipientsList
    }

    this.searchAlertsForm.valueChanges.pipe(untilDestroyed(this)).subscribe(
      () => {
        this.formChanged.emit(this.searchAlertsForm);
      }
    );
  }

  get fields() {
    return this.searchAlertsForm.get('fields') as UntypedFormControl;
  }
  get date() {
    return this.searchAlertsForm.get('date') as UntypedFormArray;
  }
  start_date(event: any) {
    this.date.value[0] = event.target.value;
    this.searchAlertsForm.markAsDirty()
    this.formChanged.emit(this.searchAlertsForm);
  }
  finish_date(event: any) {
    this.date.value[1] = event.target.value;
    this.searchAlertsForm.markAsDirty()
    this.formChanged.emit(this.searchAlertsForm);
  }
  ngOnInit() {
    this.reset.pipe(untilDestroyed(this)).subscribe(() => {
      this.resetForm();
      this.resetValues.emit();
    });
  }

  setFieldValues() {
    const ids: any = this.masterDataService.getFilteredRelations("area", this.searchAlertsForm.get('area').value, "field");
    const myData: Record<string, string>[] = [];
    ids.forEach((id: string) => {
      myData.push({
        id: id,
        name: this.getMasterdataElementName.transform(MasterDataTypes.FIELD, id),
      });
    });
    this.options.fields = myData
  }

  setFieldValue(ev: any): void {
    this.fields.setValue(ev)
    this.formChanged.emit(this.searchAlertsForm);
  }

  resetForm(): void {
    this.options.fields = [];

    this.searchAlertsForm.reset();
    const elements = document.querySelectorAll("input[type='checkbox']");
    elements?.forEach((elem: HTMLInputElement) => {
      elem.checked = false;
    });

  }
}
