import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "src/app/core/components/modal/modal.component";
import { SearchHistoryService } from "src/app/shared/services/api/search-history/search-history.service";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { getDate } from "src/app/shared/utils/DateUtils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { GetMasterdataElementNameListPipe } from "src/app/shared/pipes/get-masterdata-element-name-list/get-masterdata-element-name-list.pipe";
import { GetMasterdataElementNamePipe } from "src/app/shared/pipes/get-masterdata-element-name/get-masterdata-element-name.pipe";
import { ProcessingStatus, ProcessingState, VALIDATION_STATUS_ENUM, ExportResult } from 'src/app/shared/models/other-data.model';
import { MasterDataTypes } from "src/app/shared/models/master-data.model";
import { SearchHistory } from "src/app/shared/models/search-history.model";
import { ExportExcelService } from "src/app/shared/services/api/export/excel/export-excel.service";
import { tapOnError } from "src/app/shared/utils/rxjsUtils";
import { tap } from "rxjs/operators";
import { S3Service } from "src/aws/services/s3Service/s3.service";
import { RuleFilter } from "src/app/shared/models/rules.model";
import { ExportPdfService } from "src/app/shared/services/api/export/pdf/export-pdf.service";

@UntilDestroy()
@Component({
  selector: "app-search-record",
  templateUrl: "./search-record.component.html",
  styleUrls: ["./search-record.component.css"],
})
export class SearchRecordComponent {
  @ViewChild("modal") modal: ModalComponent | undefined;
  searchData = [];
  backUp = null;

  toggleOptions = false;
  loading = true;
  once = true;
  term = '';

  processingStates = ProcessingStatus;

  constructor(
    private router: Router,
    private historyService: SearchHistoryService,
    private notif: NotificationService,
    private translateService: TranslateService,
    private getMasterdataElementNameList: GetMasterdataElementNameListPipe,
    private getMasterdataElementName: GetMasterdataElementNamePipe,
    private exportExcelService: ExportExcelService,
    private exportPdfService: ExportPdfService,
    private s3Service: S3Service
  ) {
    localStorage.removeItem("lastSearchQuery");
    this.getData();
  }

  getData(): void {
    this.searchData = null;
    this.historyService.getRecords().pipe(
      untilDestroyed(this)
    ).subscribe(data => {
      this.generateSearchData(data);
    });
  }

  showRecord(item: SearchHistory): void {
    this.router.navigate(["search", "show-record", item.id], {
      state: { favourite: false, record: item },
    });
  }

  exportpdf(item: SearchHistory): void {
    this.exportPdfService.exportPdfSearch({
      ids: item.result
    }).pipe(
      untilDestroyed(this),
      tapOnError(err => {
        console.log("Error exporting search history result to pdf", err);
        this.notif.error("NOTIF.GENERAL_ERROR");
      }),
      tap(url => {
        this.s3Service.downloadFile(url);
      })
    ).subscribe();
  }

  exportexcel(item: SearchHistory): void {
    this.exportExcelService.exportExcelSearch({
      ids: item.result
    }).pipe(
      untilDestroyed(this),
      tapOnError(err => {
        console.log("Error exporting search history result to excel", err);
        this.notif.error("NOTIF.GENERAL_ERROR");
      }),
      tap((res: ExportResult) => {
        console.log(res);
        const docs: string[] = res.documents.map(elem => elem.link);
        docs.push(res.url);
        this.s3Service.downloadDocuments(docs);
      })
    ).subscribe();
  }

  deleteElement(item: SearchHistory): void {
    this.historyService
      .deleteRecord(item.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notif.success("NOTIF.DELETE_SUCCESS");
        this.getData();
      });
  }

  deleteHistory(): void {
    this.modal?.open();
  }

  closeModal(): void {
    this.modal?.close();
  }

  deleteAll(): void {
    this.closeModal();
    this.historyService
      .deleteSearchHistory()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notif.success("NOTIF.DELETE_SUCCESS");
        this.getData();
      });
  }

  getFormatES(date: string): string {
    return getDate(date, this.translateService.currentLang);
  }

  getFilters(item: RuleFilter): string[] {
    const res = [];

    if (item.lang) {
      res.push(this.translateService.instant("LANGUAGE." + item.lang.toUpperCase()));
    }
    if (item.validated !== undefined && item.validated !== null) {
      let currentValidate = item.validated ? VALIDATION_STATUS_ENUM.VALIDATED : VALIDATION_STATUS_ENUM.PENDING;
      res.push(this.translateService.instant(currentValidate));
    }
    if (item.geo_scopes) {
      res.push(this.getMasterdataElementName.transform(MasterDataTypes.CLUSTER, item.geo_scopes[0]));
    }
    if (item.areas) {
      res.push(...this.getMasterdataElementNameList.transform(MasterDataTypes.AREA, item.areas));
    }
    if (item.fields) {
      res.push(...this.getMasterdataElementNameList.transform(MasterDataTypes.FIELD, item.fields));
    }
    if (item.issuers) {
      res.push(...this.getMasterdataElementNameList.transform(MasterDataTypes.ISSUER, item.issuers));
    }
    if (item.phases) {
      res.push(this.getMasterdataElementName.transform(MasterDataTypes.PHASE, item.phases[0]));
    }
    if (item.processing_status) {
      let currentPS;
      this.processingStates.forEach((elem: ProcessingState) => {
        if (elem.id == item.processing_status)
          currentPS = elem.name
      })
      res.push(this.translateService.instant(currentPS));
    }
    if (res.length == 0 && item.initiatives) {
      res.push(...this.getMasterdataElementNameList.transform(MasterDataTypes.INITIATIVES, item.initiatives));
    }
    if (item.title) {
      res.push(item.title)
    }
    if (res.length == 0 && item.publication_date) {
      res.push(...item.publication_date
        .filter(elem => !!elem)
        .map(element => new Date(element).toISOString().split("T")[0]));
    }
    return res
  }

  generateSearchData(data: SearchHistory[]): void {
    const dates = new Set();
    data.map((elem) => {
      const date = new Date(parseInt(elem.datetime)).toISOString();
      dates.add(date.split("T")[0]);
    });
    const searchData = [];
    dates.forEach((date) => {
      searchData.push({
        datetime: date,
        data: []
      });
    });

    const datesArr = Array.from(dates);
    data.forEach(element => {
      const aux: any = { ...element };
      const date = new Date(parseInt(element.datetime)).toISOString();
      const index = datesArr.indexOf(date.split("T")[0]);

      aux.datetime = element.datetime;
      aux.filters = element.filters;

      searchData[index].data.push(aux);
    });

    this.searchData = searchData;
  }

  searching(): boolean {
    return this.term && this.term !== '';
  }

  filterSearchItems(event: any) {
    this.term = event.target.value.toLowerCase();
    if (!this.searching()) {
      this.searchData = this.backUp;
    }
    else {
      if(!this.backUp){
        this.backUp = this.searchData;
      }

      this.searchData = this.backUp.map(elem => ({
        ...elem,
        data: elem.data.filter(data => this.getFilters(data.filters).filter(filter =>
          filter.toLowerCase().includes(this.term)
        ).length > 0)
      }))
    }
  }

  isSearchDataEmpty(): boolean {
    return this.searchData.length === 0 || typeof this.searchData.find(elem => elem.data.length > 0)==="undefined";
  }
}
