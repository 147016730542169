import { Component } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CompanyOut } from "src/app/shared/models/company.model";
import { CompanyService } from "src/app/shared/services/api/company/company.service";
import { TranslateService } from "@ngx-translate/core";
import { getDate } from "src/app/shared/utils/DateUtils";

@UntilDestroy()
@Component({
  selector: "app-search-company",
  templateUrl: "./search-company.component.html",
  styleUrls: ["./search-company.component.css"],
})
export class SearchCompanyComponent {
  // Pagination
  totalPage = 0;
  itemsPerPage = 6;
  currentPage = 1;
  total = 0;
  // search
  showActiveCompanies = true;
  companyData: CompanyOut[] = null;
  backUp: CompanyOut[];

  constructor(
    private translateService: TranslateService, 
    private companyService: CompanyService
  ) {
    localStorage.removeItem("lastSearchQuery");
    
    this.loadData();
  }

  loadData() {
    this.companyService
      .getCompanies()
      .pipe(untilDestroyed(this))
      .subscribe((res: CompanyOut[]) => {
        this.backUp = res;
        this.filterCompanies();
      })
  }

  changePage(event: any): void {
    console.log(event);
    this.currentPage = event;
  }

  search(ev: any): void {
    const term: string = ev.target ? ev.target.value : ev.value;

    if (term.trim() === '') {
      this.companyData = this.backUp.filter(item => item.active === this.showActiveCompanies);
    } else {
      this.companyData = this.backUp.filter(
        item => {
          let dateString = this.getFormatES(item.entry_date.toString());
          return item.name.toLowerCase().includes(term.toLowerCase()) || 
          item.domain.toLowerCase().includes(term.toLowerCase()) || 
          dateString.toLowerCase().includes(term.toLowerCase()) || 
          item.num_users.toString().toLowerCase().includes(term.toLowerCase());
        }
      ).filter(item => item.active === this.showActiveCompanies)
    }
  }

  toggleActiveCompanies(): void {
    this.showActiveCompanies = !this.showActiveCompanies;
    this.companyData = null;
    this.currentPage = 1;
    this.filterCompanies();
  }

  private filterCompanies() {
    this.companyData = this.backUp.filter(elem => elem.active === this.showActiveCompanies);
  }

  getFormatES(date: string): string {
    return getDate(date, this.translateService.currentLang);
  }
}
