<div class="w-full inline-flex cursor-pointer" (click)="showRule()">
  <!-- Area y materia -->
  <div class="w-[13rem] lg:w-1/5 flex flex-col">
    <div
      id="first"
      class="w-[13rem] lg:w-full bg-black text-white py-2 mb-2 text-sm flex items-center"
    >
      <span class="inline-block w-full px-4 home-card-header-text">{{
        headers[0]
      }}</span>
      <div class="vbar"></div>
    </div>
    <div class="w-[13rem] lg:w-full py-2 bg-white h-full flex min-height">
      <div
        class="w-full text-sm px-4 inline-block border border-b-none border-l-none border-t-none md:border-r-deloitte_gray9"
      >
        <ul class="list-none mt-2 flex flex-wrap">
          <li *ngFor="let area of rule?.areas">
            <div class="mr-2 mb-2 tag pt-1 pb-2 px-1">
              {{ area | getMasterDataElement: "area":currentLang() }}
            </div>
            <ul class="list-disc mt-2">
              <ng-container *ngFor="let field of rule?.fields">
                <li *ngIf="getFieldParent(field) === area" class="m-2">
                    {{ field | getMasterDataElement: "field":currentLang() }}
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Iniciativa -->
  <div class="w-[20rem] lg:w-1/5 flex flex-col">
    <div
      id="second"
      class="w-[20rem] lg:w-full bg-black text-white text-sm py-2 mb-2 flex items-center"
    >
      <!-- [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }" -->
      <!-- border border-b-none border-l-none border-t-none border-r-white -->
      <span class="inline-block w-full px-4 home-card-header-text">{{
        headers[1]
      }}</span>
      <div class="vbar"></div>
    </div>
    <div class="w-[20rem] lg:w-full py-2 bg-white h-full flex min-height">
      <div
        class="w-full text-sm px-4 inline-block border border-b-none border-l-none border-t-none md:border-r-deloitte_gray9"
      >
        <ul class="mt-2 list-disc" *ngFor="let initiative of rule?.initiatives">
          <li class="m-2">{{ initiative | getMasterDataElement: "initiative":currentLang() }}</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Geo scope and issuer -->
  <div class="w-[20rem] lg:w-2/5 flex flex-col">
    <div
      id="third"
      class="w-[20rem] lg:w-full bg-black text-white text-sm py-2 mb-2 flex items-center"
    >
      <!-- [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }" -->
      <!-- border border-b-none border-l-none border-t-none border-r-white -->
      <span class="inline-block w-full px-4 home-card-header-text">{{
        headers[2]
      }}</span>
      <div class="vbar"></div>
    </div>
    <div class="w-[20rem] lg:w-full py-2 bg-white h-full flex min-height">
      <div
        class="w-full text-sm px-4 inline-block border border-b-none border-l-none border-t-none md:border-r-deloitte_gray9"
      >
        <div class="w-full mt-3 mb-6">
          <span class="tag py-2 px-2 align-middle">
            {{ rule?.geo_scope | getMasterDataElement: "geo_scope":currentLang() }}
          </span>
        </div>

        <div class="w-full m-1">
          <div>
            <span>
              {{ rule?.issuer | uppercase | getMasterDataElement: "issuer":currentLang() }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Titulo y descripcion -->
  <div class="w-[25rem] lg:w-4/5 flex flex-col">
    <div
      id="fourth"
      class="w-[25rem] lg:w-full bg-black text-white text-sm py-2 mb-2 flex items-center"
    >
      <!-- [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }" -->
      <span class="inline-block w-full px-4 home-card-header-text">{{
        headers[3]
      }}</span>
    </div>
    <div
      class="w-[25rem] lg:w-full py-2 pr-6 bg-white h-full flex overflow-hidden relative"
    >
      <div class="w-full text-sm px-4 inline-block break-all">
        <div class="w-full mt-1 title-description-text">
          <span class="w-full font-bold ">{{
            rule?.info?.lang_info[actualLang]?.title
          }}</span>
        </div>
          <span class="wrap-description">{{
            rule?.info?.lang_info[actualLang]?.description
          }}</span>
      </div>

      <div
        [ngClass]="getImpactClass(getImpact(rule?.info?.impact))"
        class="absolute top-0 right-0 bottom-0 flex items-center"
      >
        <span class="align-middle text-xs inline-block text"
          >{{ "LABEL.IMPACT" | translate }}:</span
        >
        &nbsp;
        <span class="font-bold align-middle text-xs inline-block text">
          {{ getImpact(rule?.info?.impact) | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
<div
  class="w-[78rem] lg:w-full bg-deloitte_gray2 pr-3 pl-4 flex justify-between align-middle mb-8"
  style="box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12)"
>
  <div class="lg:w-8/12 flex flex-wrap justify-between cursor-default">
    <div class="w-44 flex-auto p-px" *ngIf="rule?.info?.dates?.approval_date">
      <div
        class="text-deloitte_gray9 w-full inline-block text-[0.625rem] home-card-title-date-text"
      >
        {{ "LABEL.APPROVAL_DATE" | translate }}
      </div>
      <div class="text-xs text-deloitte_black font-bold home-card-date-text">
        {{ getFormatES(rule?.info?.dates?.approval_date) }}
      </div>
    </div>

    <div class="w-44 flex-auto p-px" *ngIf="rule?.info?.dates?.publication_date">
      <div
        class="text-deloitte_gray9 w-full inline-block text-[0.625rem] home-card-title-date-text"
      >
        {{ "LABEL.PUBLICATION_DATE" | translate }}
      </div>
      <div class="text-xs text-deloitte_black font-bold">
        <span class="home-card-date-text">{{
          getFormatES(rule?.info?.dates?.publication_date)
        }}</span>
      </div>
    </div>

    <div class="w-44 flex-auto p-px" *ngIf="rule?.info?.dates?.effective_date">
      <div
        class="text-deloitte_gray9 w-full inline-block text-[0.625rem] home-card-title-date-text"
      >
        {{ "LABEL.EFFECTIVE_DATE" | translate }}
      </div>
      <div class="text-xs text-deloitte_black font-bold home-card-date-text">
        {{ getFormatES(rule?.info?.dates?.effective_date) }}
      </div>
    </div>

    <div class="w-44 flex-auto p-px" *ngIf="rule?.info?.dates?.application_date">
      <div
        class="text-deloitte_gray9 w-full inline-block text-[0.625rem] home-card-title-date-text"
      >
        {{ "LABEL.APPLICATION_DATE" | translate }}
      </div>
      <div class="text-xs text-deloitte_black font-bold home-card-date-text">
        {{ getFormatES(rule?.info?.dates?.application_date) }}
      </div>
    </div>

    <div class="w-44 flex-auto p-px" *ngIf="rule?.info?.dates?.closing_date">
      <div
        class="text-deloitte_gray9 w-full inline-block text-[0.625rem] home-card-title-date-text"
      >
        {{ "LABEL.CLOSING_DATE" | translate }}
      </div>
      <div class="text-xs text-deloitte_black font-bold home-card-date-text">
        {{ getFormatES(rule?.info?.dates?.closing_date) }}
      </div>
    </div>
  </div>

  <div class="ruleInfo">
    <ul class="langSelector">
      <li *ngFor="let lang of getLanguages()" (click)="switchLanguage(lang)">
        <span ngClass="{{ lang === actualLang ? 'selectedLang' : 'lang' }}">
          {{ lang | uppercase }}
        </span>
      </li>
    </ul>

    <div class="validation">
      <span ngClass="{{ wasValidated() ? 'validated' : 'pending' }}">
        {{ getValidation() | uppercase | translate }}
      </span>
    </div>

    <div ngClass = "{{isNew() ? 'flex' : 'hidden'}}">
      <div class="flex items-center">
        <app-button
          class="cursor-default"
          theme="new"
          text="NEW"
          textClass="new-button-text"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
