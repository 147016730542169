import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith('/') || request.url.startsWith('/assets/')) {
      return next.handle(request);
    }
    const req = request.clone({
      headers: request.headers.set(
        'accept-language',
        this.injector.get(TranslateService)?.getDefaultLang()
      ),
    });
    const apiReq = req.clone({
      url: `${environment.apiUrl}${req.url}`,
    });
    return next.handle(apiReq);
  }
}
