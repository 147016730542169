<div class="loader p-3 font-bold text-1xl">
  <div style="text-align: center;">
    <h1 class="mb-4">{{errorInfo?.errorStatus}}</h1>
    <h1>{{errorInfo?.errorMessage}}</h1>

  </div>

</div>
<div class="loader " style="margin-top: 4rem;">

  <app-button theme="main" (click)="back()" text="RETURN"></app-button>
  <app-button theme="main" (click)="redirectToHome()" text="RETURN_HOME"></app-button>
</div>
