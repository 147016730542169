<div class="container">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div class="
            col-start-0
            md:col-start-2
            col-span-4
            md:col-span-3
            px-4
            md:px-1
          ">
        <app-breadcrum></app-breadcrum>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div class="
            col-start-0
            md:col-start-2
            col-span-4
            md:col-span-3
            px-4
            md:px-1
            text-black text-2xl
            font-bold
          ">
        {{ "MESSAGES.CREATE_COMPANY" | uppercase | translate }}
      </div>
    </div>
    <app-company-form (formChanged)="formChanged($event)"></app-company-form>
  </div>
</div>
<div class="container">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10 inline-block w-full">
      <div class="
            col-start-0
            md:col-start-2
            col-span-4
            md:col-span-1
            px-4
            md:px-1
          ">
        <app-button theme="main"
          [disabled]="companyForm ? companyForm.hasError('required') || companyForm.status === 'INVALID' : 'true'"
          (click)="isValid ? addCompany() : ''" text="ADD_COMPANY"></app-button>
      </div>
    </div>
  </div>
</div>
