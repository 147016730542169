import { Component } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { CompanyService } from "src/app/shared/services/api/company/company.service";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { tapOnError } from "src/app/shared/utils/rxjsUtils";

@UntilDestroy()
@Component({
  selector: "app-register-company",
  templateUrl: "./register-company.component.html",
  styleUrls: ["./register-company.component.css"],
})
export class RegisterCompanyComponent {
  companyForm: UntypedFormGroup;
  isValid = false;

  constructor(
    private companyService: CompanyService,
    private notif: NotificationService,
    private router: Router
  ) {
    localStorage.removeItem("lastSearchQuery");
  }

  formChanged(event: any): void {
    this.companyForm = event;
    this.isValid = this.companyForm.valid;
  }

  addCompany(): void {
    this.companyService.addCompany(this.companyForm.value)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onAddCompanyError(err)),
        tap(id => this.onAddCompanySuccess(id))
      )
      .subscribe();
  }

  onAddCompanySuccess(companyId: string) {
    this.notif.success("NOTIF.CREATE_SUCCESS");
    this.router.navigate(["administration", "show-company", companyId]);
  }

  onAddCompanyError(err) {
    console.log('Error creating company', err);
    this.notif.error("NOTIF.CREATE_ERROR");
  }
}
