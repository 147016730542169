import { Component, OnInit } from '@angular/core';
import { AWSService } from 'src/aws/aws.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private awsService: AWSService,
  ) { }

  ngOnInit() {
  }


  login() {
    this.awsService.proceedToLogin();
  }
}
