import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { GetMasterDataPipe } from 'src/app/shared/pipes/get-masterdata/get-masterdata.pipe';
import { Roles } from 'src/app/shared/models/other-data.model';
import { MasterDataTypes } from 'src/app/shared/models/master-data.model';
import { CompanyService } from 'src/app/shared/services/api/company/company.service';
import { MasterDataService } from 'src/app/shared/services/master-data/master-data.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { GetMasterdataElementNamePipe } from 'src/app/shared/pipes/get-masterdata-element-name/get-masterdata-element-name.pipe';

interface Option {
  id: string,
  name: string,
}

@UntilDestroy()
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  userForm: UntypedFormGroup;
  options: any;
  @Output() formChanged = new EventEmitter();
  // initial data in edit
  @Input() readonly userData = null;
  loadingCompany:boolean=true;


  constructor(
    private builder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private getMasterData: GetMasterDataPipe,
    private companyService: CompanyService,
    private masterDataService: MasterDataService,
    private getMasterdataElementName: GetMasterdataElementNamePipe,

  ) {

    function  customValidator(fg:UntypedFormGroup): ValidationErrors | null {
      const areas = fg.get('areas').value
      const fields = fg.get('fields').value
      return (areas.length>0 && !fields) ? {fieldsEmpty: true}: null
    }

    this.userForm = this.builder.group({
      role: [, Validators.required],
      name: ['',],
      surname: ['',],
      email: ['',],
      company: [,],
      // languages: [[], Validators.required],
      areas: [[],],
      fields: [[],],
      receiveAlerts: [[],Validators.required],
      emailNewsletter: [[],],
    },{validators: customValidator});

    this.userForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if(this.userForm.value.role == 4){
        this.userForm.controls['areas'].setValidators([Validators.required])
        this.userForm.controls['areas'].updateValueAndValidity({emitEvent: false})
        this.userForm.controls['fields'].setValidators([Validators.required])
        this.userForm.controls['fields'].updateValueAndValidity({emitEvent: false})
      }
      else{
        this.userForm.controls['areas'].clearValidators()
        this.userForm.controls['areas'].updateValueAndValidity({emitEvent: false})
        this.userForm.controls['fields'].clearValidators()
        this.userForm.controls['fields'].updateValueAndValidity({emitEvent: false})
      }
      return this.formChanged.emit(this.userForm)
    });
  }

  ngOnInit(): void {
     if (this.userData) {
      this.fillUserForm();
    }

    this.getCompanies();
    this.options = {
      roles: Roles,
      // languages: this.translateService.getLangs().map(e => e.toUpperCase()),
      areas: this.getMasterData.transformActive(MasterDataTypes.AREA)
    }
    this.options.fields = this.createRelationObservable('areas', 'fields', MasterDataTypes.AREA, MasterDataTypes.FIELD,updateMultiValueControl);

  }

  fillUserForm(): void {

    this.userForm.setValue({
      role: this.userData?.role ? this.userData?.role : null,
      name: this.userData?.name,
      surname: this.userData?.surname,
      email: this.userData?.email,
      company: this.userData?.company ? this.userData?.company : null,
      // languages: this.userData?.languages,
      areas: this.userData?.areas,
      fields: this.userData?.fields.filter(field=> this.masterDataService.getActiveData("field").includes(field)),
      receiveAlerts: this.userData?.receiveAlerts,
      emailNewsletter: this.userData?.emailNewsletter,
    });
  }

  createRelationObservable(parentForm: string, childForm: string, parentMD: MasterDataTypes, childMD: MasterDataTypes, childUpdater: ((control: AbstractControl, options: Option[]) => boolean) = undefined): Observable<Option[]> {
    const parentControl = this.userForm.get(parentForm)
    const childControl = this.userForm.get(childForm);

    childControl.disable();

    return parentControl.valueChanges.pipe(
      distinctUntilChanged(),
      // Standarize. whatever value is to array (of ids)
      map((value: string | string[]) => {
        if (!value) return [];

        if (Array.isArray(value)) {
          if (!value.length) return [];
          return value;
        }
        else if (typeof value == 'string') {
          return [value];
        } else {
          // Unrecognized value
          console.error(`Unrecognized value on ${parentForm} control`, value);
          return [];
        }
      }),
      // parent ID'S to child ID'S
      map(ids => ids.map(id => this.masterDataService.getActiveRelations(parentMD, id, childMD)).flat()),
      // Remove duplicates
      map(options => [... new Set(options)]),
      // From ID to option with id and name
      map(options => options.map((id) => ({
        id,
        name: this.getMasterdataElementName.transform(childMD, id) as string,
      }))),
      // Sort by name
      map(options => options.sort((a, b) => a.name.localeCompare(b.name))),
      tap(options => { // Sync child control value
        console.log(parentControl.valueChanges);

        if (!options?.length) {
          childControl.disable();
        } else {
          childControl.enable();
        }

        if (childUpdater && childUpdater(childControl, options)) {

        } else {
          childControl.reset();
        }

        const currentValue = childControl.value;
      })
    )
  }

  getData() {
    const formValue = this.userForm.value;

    return this.formToData(formValue);
  }

  setData(data: any) {

    const formValue = this.dataToForm(data);


    this.userForm.patchValue(formValue);

    // Force one change detection to make sure rx-related elements are properly sync'd on UI
    this.changeDetectorRef.detectChanges();
  }

  dataToForm(data: any): { [key: string]: string } {
    return {
      role: data?.role ? data?.role : null,
      name: data?.name,
      surname: data?.surname,
      email: data?.email,
      company: data?.company ? data?.company : null,
      areas: data?.areas,
      fields: data?.fields,
      receiveAlerts: data?.receiveAlerts,
      emailNewsletter: data?.emailNewsletter,
    };
  }

  formToData(formValue:any){
    return {
      username:"",
      id:"",
      email: formValue.email,
      groups: Roles.filter(r => r.id === formValue.role).map(r => r.name.replace("ROLES.", "").toLowerCase()),
      receiveAlerts: formValue.receiveAlerts,
      emailNewsletter: formValue.emailNewsletter,
      company: formValue.company,
      field_interest: formValue.fields
    }

  }

  getCompanies() {
    this.companyService.getCompanies().pipe(untilDestroyed(this)).subscribe((res) => { this.options.companies = res, this.loadingCompany = false })
  }
  isChecked(option: any, control: string) {
    const data = this.data(control).value;
    return data.includes(option["id"] ? option.id : option);
  }

  data(control: string): UntypedFormArray {
    return this.userForm.controls[control] as UntypedFormArray;
  }

  onCheckboxChange(e: any, option: any, control: string): void {
    const data = this.data(control).value;
    if (e.target.checked) {
      data.push(option.name ? option.id : option);
    } else {
      const index = data.findIndex((element: any) => {
        return element === e;
      });
      data.splice(index, 1)
    }
    this.data(control).updateValueAndValidity();
  }
}

  function updateMultiValueControl(control: AbstractControl, options: Option[]): boolean {
    const currentValue: string[] = control.value || [];

    const existingOpts = options.filter(opt => currentValue.includes(opt.id));

    if (currentValue.length && currentValue.length == existingOpts.length) {
      // DO nothing
      //console.log('Relation', `${parentForm} -> ${childForm}`, options, 'Same value', currentValue);
      return true;
    } else if (existingOpts.length) {
      const ids = existingOpts.map(opt => opt.id);
      // console.log('Relation', `${parentForm} -> ${childForm}`, options, 'partial values', ids);
      control.setValue(ids);
      return true;
    } else if (options?.length == 1) {
      // Only one, select
      const ids = options.map(opt => opt.id);
      //console.log('Relation', `${parentForm} -> ${childForm}`, options, 'Single option', ids);
      control.setValue(ids);
      return true;
    }

    return false;
  }
