import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AlertDetail } from "src/app/shared/models/alert.model";
import { getDate } from "src/app/shared/utils/DateUtils";


@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.css"],
})
export class AlertComponent {
  @Input() alert!: AlertDetail;
  @Input() index!: number;

  headers = [
    this.translateService.instant("HEAD.TITLECOMMUNICATION"),
    this.translateService.instant("HEAD.AREAANDSUBJECT"),
    this.translateService.instant("HEAD.LANGUAGE"),
    this.translateService.instant("HEAD.DATECOMMUNICATION"),
    this.translateService.instant("HEAD.RECIPIENTS"),
  ];

  constructor(
    private translateService: TranslateService,
    private router: Router,
  ) {
  }

  getFormatES(date: string): string {
    return getDate(date, this.translateService.currentLang);
  }

  showAlert(): void {
    console.log("ebaena this.alert: ", this.alert);
    this.router.navigate(["alerts", "show-alert", this.alert.id]);
  }

  currentLang(): string {
    return this.translateService.currentLang;
  }
}
