import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { AWSService } from "./aws.service";
import { MasterDataService } from "src/app/shared/services/master-data/master-data.service";
import { MasterDataFile } from "src/app/shared/models/master-data.model";
import { AWSApiHttpInterceptorProvider } from "./aws.api.interceptor.provider";
import { catchError, filter, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";



@NgModule({})
export class AWSModule {

  static forRoot(): ModuleWithProviders<AWSModule> {
    return {
      ngModule: AWSModule,
      providers: [
        AWSService,

        // Setup AWS
        {
          provide: APP_INITIALIZER,
          useFactory: (
            awsService: AWSService,
            masterDataService: MasterDataService,
          ) => {

            return () => {
              awsService.setup();
          
              return awsService.loginStatus.pipe(
                take(1), // Make sure we take one, this is an initializer

                // If not logged in we stop the app start and redirect to login
                filter(loggedIn => {
                  if (!loggedIn) {
                    if (window.location.pathname.replace(/\//g, '') != 'logout') {
                      awsService.proceedToLogin();
                    }

                  }
                  return loggedIn;
                }),

                // After login, get master data
                mergeMap(_ => awsService.getConfig<MasterDataFile>('master_data')),
                tap(data => masterDataService.setMasterData(data)),
                catchError(e => {
                  return of(true);
                }),
              )
            }
          },
          deps: [AWSService, MasterDataService],
          multi: true,
        },

      ]
    };
  }

  // Make all http requests to API endpoint using Amplify API client  
  static interceptor() {
    return AWSApiHttpInterceptorProvider;
  }
}

