<div *ngIf="!user_data" class="flex justify-center mt-6">
  <app-loader></app-loader>
</div>

  <div class="container">
    <div class="mx-0 xl:mx-12">
      <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
        <div
          class="col-start-0 md:col-start-2 col-span-4 md:col-span-3 px-4 md:px-1"
        >
          <app-breadcrum></app-breadcrum>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="mx-0 xl:mx-12">
      <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
        <div
          class="col-start-0 md:col-start-2 col-span-4 md:col-span-3 px-4 md:px-1 text-black text-2xl font-bold"
        >
          {{ "MESSAGES.EDIT_USER" | uppercase | translate }}
        </div>
      </div>

      <app-user-form #userForm></app-user-form>

      <div
        *ngIf="user_data?.registration_date"
        class="w-full md:flex inline-block float-left mb-4 px-4"
      >
        <div class="md:w-3/12 section_title"></div>
        <div class="md:w-9/12 field_section">
          <label class="text-black font-bold text-sm flex pb-1">
            {{ "LABEL." + "registration_date" | uppercase | translate }}
          </label>
          <div class="w-6/12 field_content">
            <span>{{ user_data.registration_date }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="container">
    <div class="mx-0 xl:mx-12">
      <div class="md:grid md:grid-cols-4 gap-4 mb-8 inline-block w-full">
        <div
          class="md:col-start-2 md:col-span-4 px-4 md:px-1 gap-2 flex flex-col md:flex-row"
        >
          <app-button
            theme="main"
            [disabled]="userForm.userForm.invalid"
            (click)="!userForm.userForm.invalid ? updateUser() :  ''"
            text="SAVE_CHANGE"
          >
          </app-button>
        <app-button theme="main" text="RETURN" (click)="back()"></app-button>

          <!-- <app-button class="self-center" theme="delete" (click)="deleteUser()" text="DELETE_USER"></app-button> -->
        </div>
      </div>
    </div>
  </div>
