import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MasterDataService } from "src/app/shared/services/master-data/master-data.service";


export type Option = { id: string, name: string, parentId: string };

@UntilDestroy()
@Component({
  selector: "app-multiselect",
  templateUrl: "./multiselect.component.html",
  styleUrls: ["./multiselect.component.scss"],
})
export class MultiselectComponent implements OnInit {

  private itemsIndex: { [key: string]: string };
  private _items: Option[] = [];
  get items(): Option[] {
    return this._items;
  }
  @Input() set items(v: Option[]) {
    this._items = v || [];
    this.searchItems = v || [];
    this.itemsIndex = this._items.reduce((acc, entry) => {
      acc[entry.id] = entry.name;
      return acc;
    }, {})
  }

  searchItems: Option[] = [];

  @Input() showAll = true;

  @Output() valueChanged = new EventEmitter();
  show = false;
  once = true;
  group!: UntypedFormGroup;
  backup!: any;
  @Input() reset?;

  private _value: string[] = [];
  get value(): string[] {
    return this._value;
  }
  @Input() set value(v: string[]) {
    this._value = v || [];
    this.group.setValue({
      data: this.value.map(id => {
        let parentData = this.masterDataService.searchParent("field", id, "area");
        if (!parentData) {
          parentData = id;
        }
        if (this.itemsIndex[id]) {
          return { id, name: this.itemsIndex[id], parentId:parentData }
        }
      }
      ).filter(elem => elem)
    })
  }

  @Input() customInputCSS?;

  constructor(private builder: UntypedFormBuilder, private elementRef: ElementRef, private masterDataService: MasterDataService) {
    this.group = this.builder.group({
      data: [[]],
    });
  }

  ngOnInit(): void {
    if (this.reset) {
      this.reset.pipe(untilDestroyed(this)).subscribe(() => {
        this.resetValues();
      });
    }

    this.group.setValue({
      data: this.value ? this.value : []
    })

    this.searchItems = this.items;

  }

  data(): UntypedFormArray {
    return this.group.controls["data"] as UntypedFormArray;
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event: { target: any }): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.show = false;
    }
  }

  focus(): void {
    this.show = true;
    if (this.once) {
      this.backup = this.items;
      this.once = false;
    }
  }

  search(e: any): void {
    this.searchItems = this.items.filter((v: any) => {
      const value = e.target.value.toLowerCase();
      this.group.patchValue({ search_text: value });
      return v['id'] ? v["name"].toLowerCase().includes(value) : v.toLowerCase().includes(value);
    });

    if (e.target.value.trim() === "") {
      this.searchItems = this.items;
    }
  }

  isChecked(value: any): boolean {
    const data = this.data().value;
    return data.find((v: any) => {
      return v['id'] ? v["id"] === value["id"] : v === value;
    });
  }

  onCheckboxChange(e: any, option: any): void {
    if (!option) {
      this.resetValues();
    }
    else {
      const data = this.data().value;
      if (e.target.checked) {
        data.push(option);
        this.data().updateValueAndValidity();
        const values = data.map((elem) => elem.id ? elem.id : elem);
        this.valueChanged.emit(values);
      } else {
        this.remove(option);
      }
    }
  }

  remove(option: any): void {
    const data = this.data().value;
    if (this.once) {
      this.backup = this.items;
      this.once = false;
    }
    const index = data.findIndex((element: any) => option['id'] ? element['id'] === option['id'] : element === option);
    data.splice(index, 1)
    this.data().updateValueAndValidity();
    const values = data.map((elem) => elem.id ? elem.id: elem);
    this.valueChanged.emit(values);
  }

  resetValues(): void {
    this.group.reset({
      data: []
    })
  }
}
