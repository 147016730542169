<div class="container">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div
        class="col-start-0 md:col-start-2 col-span-4 md:col-span-3 px-4 md:px-1 text-black text-2xl leading-8 font-bold mt-4">
        {{ "MESSAGES.CREATE_NEW_RULE" | uppercase | translate }}
      </div>
    </div>

    <app-rule-form #form></app-rule-form>

    <!-- save and validate -->
    <div class="center mt-4 pb-16">
      <div class="responsive-width">
        <app-button theme="main" text="SAVE" style="margin-right: 40px" (click)="submit()" [disabled]="form.form.invalid"></app-button>
      </div>
    </div>
  </div>
</div>
<app-modal #modal>
  <div (click)="closeModal()" class="absolute top-3 right-3 md:right-6">
    <img class="scale-75 cursor-pointer" src="assets/svg/Icon-Close@2x.svg" />
  </div>
  <div class="text-2xl mx-4 mb-6 block font-bold text-center">
    {{ "MESSAGES.CONFIRM_CREATE_RULE" | translate }}
  </div>
  <div class="space-around mt-5 md:mt-8">
    <app-button (click)="addValidatedRule()" text="PUBLISH&SAVE"></app-button>
    <app-button (click)="addRule()" text="SAVE_UNVALIDATED"></app-button>
  </div>
</app-modal>
