<div class="container">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div
        class="col-start-0 md:col-start-2 col-span-4 md:col-span-3 px-4 md:px-1 text-black text-2xl leading-8 font-bold mt-4">
        {{ "MESSAGES.CREATE_ALERT" | uppercase | translate }}
      </div>
    </div>

    <hr class="horizontal-line" style="margin-top: 0px" />

    <div [formGroup]="registerAlertForm">
      <!-- language -->
      <div class="wrap">
        <div class="margin">
          <div class="input-margin-top center">
            <span class="label">{{ "LABEL.ALERT_LANGUAGE" | translate }}</span>
            <span class="red" *ngIf="registerAlertForm.get('language').hasError('required')">*</span>
          </div>
        </div>

        <div style="width: 50%;">
          <div class="center">
            <div class="responsive-width">
              <div class="space-between">
                <div class="input-margin-top" *ngFor="let lang of getLangs()">
                  <input [id]="lang" [value]="lang" type="radio" formControlName="language" />
                  <span class="label ml-2">{{ "LANGUAGE." + lang | uppercase | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="horizontal-line" />

      <!-- Title and description -->
      <div class="wrap">
        <div class="margin">
          <div class="input-margin-top center">
            <span class="label">
              {{"SECTION.COMMUNICATION_SUBJECT" | translate}}
            </span>
          </div>
        </div>

        <div style="width: 50%;">
          <div class="center">
            <div class="responsive-width">
              <div class="input-margin-top">
                <span class="label">{{ "LABEL.TITLE" | translate }}</span>
                <span class="red" *ngIf="registerAlertForm.get('title').hasError('required')">*</span>
              </div>
              <input class="input-form" [formControl]="registerAlertForm.get('title')" for type="text" />

              <div class="input-margin-top">
                <span class="label">{{ "LABEL.CONTENT" | translate }} </span>
                <span class="red" *ngIf="registerAlertForm.get('description').hasError('required')">*</span>
              </div>
              <textarea class="input-form" style="height: 150px"
                [formControl]="registerAlertForm.get('description')"></textarea>
            </div>
          </div>
        </div>
      </div>

      <hr *ngIf="this.registerAlertForm.controls.texts || this.registerAlertForm.controls.documents" class="horizontal-line" />
      <!-- texts -->
      <div *ngIf="this.registerAlertForm.controls.texts" class="wrap">
        <div class="margin">
          <div class="input-margin-top center">
            <span class="label">{{ "SECTION.SEARCH_TEXTS" | translate }}</span>
          </div>
        </div>

        <div style="width: 50%;">
          <div class="center">
            <div class="responsive-width">
              <div class="input-margin-top">
                <app-evolution-filter
                  [textLang]="registerAlertForm.get('language').value"
                  [evolutionsLoaded]="registerAlertForm.get('texts').value"
                  (valueChanged)="addText(registerAlertForm.get('texts'), $event)"
                  (elementRemoved)="removeText(registerAlertForm.get('texts'), $event)"
                  [requiredField]="true"
                  [withState]="false"
                ></app-evolution-filter>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- documents -->
      <div *ngIf="registerAlertForm.controls.documents" class="wrap">
        <div class="margin">
          <div class="input-margin-top center">
            <span class="label">{{ "SECTION.DOCUMENTS" | translate }}</span>
          </div>
        </div>

        <div style="width: 50%;">
          <div class="center">
            <div class="responsive-width">
              <div class="input-margin-top flex">
                <div>
                  <app-button *ngIf="registerAlertForm.get('documents').value[0]"
                    class="hidden md:block" (click)="downloadPdf()" text="PDF" theme="export_home"
                  >
                    <img class="inline-block pr-2" src="assets/imgs/exportar-pdf.png" />
                  </app-button>
                  <app-loader class="mr-5" *ngIf="!registerAlertForm.get('documents').value[0]" [diameter]="20"></app-loader>
                </div>

                <div>
                  <app-button *ngIf="registerAlertForm.get('documents').value[1]"
                    class="hidden md:block" (click)="downloadExcel()" text="EXCEL" theme="export_home"
                  >
                    <img class="inline-block pr-2 mb-1" src="assets/imgs/exportar-excel.png" />
                  </app-button>
                  <app-loader *ngIf="!registerAlertForm.get('documents').value[1]" [diameter]="20"></app-loader>
                </div>
              </div>

              <span class="red" *ngIf="!registerAlertForm.get('documents').value[1] || !registerAlertForm.get('documents').value[0]">
                {{"MESSAGES.EXPORTING" | translate}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <hr class="horizontal-line" />
      <!-- recipients -->
      <div class="wrap">
        <div class="margin">
          <div class="input-margin-top center">
            <span class="label">{{ "LABEL.RECIPIENT_TYPE" | translate }}</span>
          </div>
        </div>

        <div style="width: 50%;">
          <div class="center">
            <div class="responsive-width">
              <div class="space-between">
                <div class="input-margin-top" *ngFor="let recipient of recipient_types">
                  <input [value]="recipient" type="radio" (change)="onRecipientTypeChange(recipient)" name="recipient_type"/>
                  <span class="label ml-2">{{ recipient.name | translate }}</span>
                </div>
              </div>

              <div class="input-margin-top" *ngIf="recipient_type">
                <span class="label">{{ "LABEL.RECIPIENTS" | translate }}</span>
                <span class="red" *ngIf="recipients.hasError('required')">*</span>

                <div *ngIf="isCompanyOrUser()">
                  <div class="input-form-historic">
                    <mat-form-field style="color: black">
                      <input
                        autocomplete="off"
                        matInput type="text"
                        placeholder="{{searchRecipientsMesssage}}"
                        (keyup.enter)="searchRecipients($event)"
                        (blur)="searchRecipients($event)"
                      />
                      <mat-select name="recipients">
                        <mat-option *ngFor="let option of recipientOptions" (click)="addToRecipients(option)">
                          {{ option.name ? option.name: option.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="isEmail()" class="w-full">
                  <div class="flex justify-between gap-2">
                    <input
                      class="input-form"
                      autocomplete="off"
                      type="text"
                      [value]="emailAddress"
                      (input)="onEmailChange($event)"
                    >
                    <app-button theme="main_black" text="Add" (click)="addEmailToRecipients()" [disabled]="!isValidEmail()">
                    </app-button>
                  </div>

                  <div class="w-full input-error-bottom" *ngIf="emailAddress && emailAddress !== '' && !isValidEmail()">
                    <span class="red mb-1" >{{"VALIDATORS.MAIL" | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="input-margin-top">
                <div class="mb-4">
                  <span class="font-bold">{{ "LABEL.ADDED_RECIPIENTS" | translate }}</span>
                </div>

                <div *ngIf="recipients.value.length === 0" style="margin: 2rem 0rem">
                  <h2>{{ "LABEL.NO_RECIPIENTS" | translate }}</h2>
                </div>

                <div *ngIf="recipients.value.length !== 0">
                  <div style="flex-wrap: nowrap; margin-bottom: 2rem"
                    *ngFor="let recipient of recipients.value; let index = index">
                    <div class="space-between">
                      <div style="display: flex; align-items: center; width: 60%">
                        <li>
                          {{ recipient.name ? recipient.name : recipient.value }}
                        </li>
                      </div>

                      <button (click)="removeRecipient(index)">
                        <img src="assets/svg/remove.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center pb-16">
      <div class="responsive-width flex" style="align-items: center">
        <app-button theme="main" text="SAVE" style="margin-right: 40px" (click)="updateCreatingAlert()" [disabled]="registerAlertForm.invalid || creatingAlert"></app-button>
        <app-loader *ngIf="creatingAlert" class="mr-5" [diameter]="20"></app-loader>
      </div>
    </div>
  </div>
</div>
