import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company, CompanyList, CompanyOut, RegisterCompany, UpdateCompany } from 'src/app/shared/models/company.model';
import { noErrorHandling } from 'src/app/shared/utils/RequestUtils';
import { CognitoUser } from 'src/aws/services/cognito/cognito.model';
import { CognitoService } from 'src/aws/services/cognito/cognito.service';
import { NotificationService } from '../../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private http: HttpClient, private cognito: CognitoService, private notif: NotificationService) { }

  getCompanies(): Observable<CompanyOut[]> {
    return this.http.get<CompanyList>("/admin/company")
      .pipe(map(res => res.items));
  }

  getCompany(idCompany: string): Observable<Company> {
    return this.http.get<Company>(`/admin/company/${idCompany}`)
  }

  getCompanyUsers(idCompany: string): Observable<CognitoUser[]> {
    return this.cognito.getUsersByCompany(idCompany);
  }

  updateActive(idCompany: string, active: boolean): Observable<void> {
    return this.http.put<void>(`/admin/company/${idCompany}/active`, { active: active }, { ...noErrorHandling() })
  }

  deleteCompany(idCompany: string): Observable<void> {
    return this.http.delete<void>(`/admin/company/${idCompany}`, { ...noErrorHandling() });
  }

  addCompany(body: RegisterCompany): Observable<string> {
    return this.http.post<{ id: string }>(`/admin/company`, body, { ...noErrorHandling() })
      .pipe(map((res) => res.id));
  }

  updateCompany(idCompany: string, body: UpdateCompany): Observable<void> {
    return this.http.put<void>(`/admin/company/${idCompany}`, body, { ...noErrorHandling() });
  }
}
