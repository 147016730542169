import { Component, Input, OnInit } from "@angular/core";
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"],
})
export class LoaderComponent {

  @Input() diameter?: number = 70;
  color: ThemePalette = 'primary';
  constructor() {}

  // ngOnInit(): void {}
}
