import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { getDate } from "src/app/shared/utils/DateUtils";
import { Impacts, Impact, VALIDATION_STATUS_ENUM } from 'src/app/shared/models/other-data.model';
import { Rule } from "src/app/shared/models/rules.model";
import { MasterDataService } from "src/app/shared/services/master-data/master-data.service";

@Component({
  selector: "app-rule",
  templateUrl: "./rule.component.html",
  styleUrls: ["./rule.component.css"],
})
export class RuleComponent implements OnInit {
  @Input() readonly rule!: Rule;
  @Input() actualLang: string;

  headers = [
    this.translateService.instant("HEAD.AREAANDSUBJECT"),
    this.translateService.instant("HEAD.INITIATIVE"),
    this.translateService.instant("HEAD.GEOSCOPEANDISSUINGBODY"),
    this.translateService.instant("HEAD.TITLEANDDESCRIPTION"),
  ];

  constructor(
    private translateService: TranslateService, 
    private router: Router, 
    private masterDataService: MasterDataService
  ) {}

  ngOnInit(): void {
    if (!this.actualLang) {
      this.actualLang = this.rule.info.defaultLang ? this.rule.info.defaultLang : this.currentLang();
    }
    this.actualLang = this.actualLang.toLowerCase();
  }

  getDateHeaders(): string[] {
    return Object.keys(this.rule?.info.dates);
  }

  getImpact(impact: number): string {
    return Impacts.find((e: Impact) => e.id === impact ? e.name : '')?.name;
  }

  getFormatES(date: string): string {
    return getDate(date, this.currentLang());
  }

  getImpactClass(impact: string): string {
    // modificar estilo impacto según su valor
    let newClass = "impact text-left pt-2 right-0";
    if (impact) {
      newClass = newClass + " " + impact.split('.')[1].toLowerCase();
    }
    return newClass;
  }

  currentLang(): string {
    return this.translateService.currentLang;
  }

  switchLanguage(lang: string): void {
    this.actualLang = lang.toLowerCase();
  }

  getLanguages(): string[] {
    return Object.keys(this.rule.info.lang_info);
  }

  showRule(): void {
    // Nos dirigimos a la vista show-rule del texto clickeado
    this.router.navigate(["rules", "show-rule", this.rule.id], { state: { lang: this.actualLang } });
  }

  wasValidated(): boolean {
    return this.rule.info.validated;
  }

  getValidation(): string {
    return this.rule.info.validated ? VALIDATION_STATUS_ENUM.VALIDATED : VALIDATION_STATUS_ENUM.PENDING;
  }

  isNew(){
    const TWO_WEEKS = 1209600000
    return (Date.now() - new Date(this.rule.info.dates.publication_date).getTime()) < TWO_WEEKS
  }

  getFieldParent(field): string {
    return this.masterDataService.searchParent("field", field, "area");
  }
}
