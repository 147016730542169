import { Injectable } from "@angular/core";
import Amplify, { Hub, HubCapsule, ICredentials } from "@aws-amplify/core";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Storage as AwsStorage } from "@aws-amplify/storage";
import { Provider, Credentials } from "@aws-sdk/types";
import { environment } from "src/environments/environment";
import { LOG_TYPE } from "@aws-amplify/core/lib-esm/Logger";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";
import { debounce, debounceTime, distinctUntilChanged, filter, map, mergeMap, shareReplay, skip, take, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";



@Injectable({
  providedIn: "root",
})
export class AWSService {

  Auth = Auth

  // TODO: Config on constructor/dynamic and not hardcoded to environment
  private config = environment.aws;

  private authEvent: Subject<HubCapsule> = new Subject();
  private credentials: BehaviorSubject<ICredentials> = new BehaviorSubject(null);
  private loginStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public get loginStatus(): Observable<boolean> {
    return this.loginStatusSubject.pipe(
      filter(v => v !== null),
    );
  }




  constructor(
    private httpClient: HttpClient
  ) {

  }


  setup(): Promise<void> {
    Amplify.Logger.LOG_LEVEL = this.config.LOG_LEVEL || LOG_TYPE.WARN;

    // Add listener. Event to subject
    Hub.listen('auth', (data) => this.authEvent.next(data));

    const copy = JSON.parse(JSON.stringify(this.config));
    copy.Storage.AWSS3 = {
      bucket: copy.S3.Bucket,
      region: copy.S3.region,
      dangerouslyConnectToHttpEndpointForTesting: !!copy.S3.endpoint,
    }
    Object.assign(copy.Storage, copy.S3);
    const config = Amplify.configure(copy);
    console.log('Setting up Amplify', config);

    this.setupRx();
    return this.refreshCredentials().then(_ => null);
  }

  private setupRx(): void {

    this.credentials.pipe(
      skip(1),
      tap(c => console.log('Credentials', c?.authenticated, c)),
      map(c => c?.authenticated || false),
      tap(ok => console.log('loginStatus', ok)),
      distinctUntilChanged(),
      tap(ok => this.loginStatusSubject.next(ok)),
    ).subscribe();

    // Every time there is an event,  refresh
    this.authEvent.pipe(
      debounceTime(500),
      tap(_ => this.refreshCredentials()),
    ).subscribe();
  }

  private refreshCredentials(): Promise<void> {
    return Auth.currentCredentials()
      .catch(e => {
        console.error('Error getting credentials', e)
        return null;
      })
      .then(c => {
        this.credentials.next(c instanceof Error ? null : c);
        return null;
      });
  }

  getConfig<T>(key: string): Observable<T> {
    return from(AwsStorage.get(`app-config/${key}.json`))
      .pipe(
        mergeMap(url => {
          return this.httpClient.get<T>(url)
        })
      )
  }

  checkSession(forceCheck: boolean = false): Observable<boolean> {
    if (forceCheck) {
      return from(this.refreshCredentials()).pipe(
        mergeMap(_ => this.loginStatus),
      );
    } else {
      return this.loginStatus;
    }
  }

  getUser(): Observable<any> {// TODO: Type
    return from(Auth.currentAuthenticatedUser())
  }

  getUserGroups(): Observable<string[]> {
    return this.getUser().pipe(
      map(user => user.signInUserSession.accessToken.payload["cognito:groups"] as string[] || []),
      map(groups => groups.filter(g => !g.startsWith(this.config.Auth.userPoolId))) // Remove automatic cognito groups. Only app groups
    )
  }

  proceedToLogin(): void {
    if (this.config.localStack || !this.config.Auth.oauth) {
      return;
    }

    Auth.federatedSignIn(this.config.Auth.oauth.federatedSignIn)
  }

  logout(): void {
    Auth.signOut();
  }

  credentialsProvider(): Provider<Credentials> {
    return () => {
      return Auth.currentCredentials()
              .then(c => Auth.essentialCredentials(c))
    }
  }

}
