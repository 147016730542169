import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertDetail, AlertFilter, AlertOut, RegisterAlert, SearchAlertInput, SearchAlertOutput } from 'src/app/shared/models/alert.model';
import { noErrorHandling } from 'src/app/shared/utils/RequestUtils';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  pageNumber = 1;
  query: any = {};

  constructor(private http: HttpClient) { }

  searchAlerts(query: SearchAlertInput<AlertFilter>): Observable<SearchAlertOutput<AlertOut>> {
    return this.http.post<SearchAlertOutput<AlertOut>>("/alert/search", query);
  }

  getAlert(idAlert: string): Observable<AlertDetail> {
    return this.http.get<AlertDetail>(`/alert/${idAlert}`);
  }

  sendAlert(body: RegisterAlert): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`/alert`, body, { ...noErrorHandling() });
  }
}
