<div class="container">
  <div class="mx-3 my-6 md:mx-12">
    <div>
      <div class="flex w-full md:w-auto pb-3 mb-2 md:pb-0 text-black text-2xl font-bold">
        {{ "MENU_OPTIONS.MANAGE-MASTERDATA" | translate }}
      </div>

      <!-- master data types -->
      <select *ngIf="options?.mdTypes" class="mt-6 md:flex md:flex-wrap md:gap-4"
       (change)="masterdataTypeChanged($event.target.value)"
      >
        <option value="{{null}}" disabled selected hidden>
          {{ getSelectMessage("SELECT_ONE.MD_TYPE") }}
        </option>
        <option *ngFor="let type of options.mdTypes" value="{{type}}">
          {{"MASTERDATA_TYPES." + type | uppercase | translate }}
        </option>
      </select>

      <!-- master data -->
      <div *ngIf="options.mdElements" class="my-6">
        <div class="flex font-bold mb-6 w-full">
          <div class="
            flex
            bg-white
            justify-between
            w-full
            mb-3
            md:mb-0 md:w-6/12
            p-4
            border
            border-t-input_search
            border-r-input_search
            search-icon
            border-l-input_search
            border-b-deloitte
            text-sm
            placeholder:text-sm
          ">
            <input (input)="search($event)" class="w-10/12 focus:outline-none appearance-none searchBar" type="text"
              placeholder="{{ 'PLACEHOLDER.SEARCH_BY_NAME' | translate }}" />
            <img src="assets/svg/search_input.svg" />
          </div>

          <div class="w-full hidden md:flex md:justify-end">
            <app-button theme="main_block_strech" (click)="addMD()" text="ADD_MD"></app-button>
          </div>
        </div>

        <div *ngIf="options.mdElements.length > 0">
          <div class="w-full hidden md:grid md:grid-cols-10 border-b-deloitte_gray9">
            <span class="w-full break-all col-span-4 font-bold">{{'LABEL.NAME' | translate }}</span>
            <span class="hidden md:block w-full break-all col-span-4 font-bold">{{'LABEL.DESCRIPTION' | translate}}</span>
            <div class="flex col-span-2 justify-between"> </div>
          </div>

          <div class="mdElement" *ngFor="let element of options.mdElements | paginate
            : {
                id: 'md_pagination',
                itemsPerPage: itemsPerPageMD,
                currentPage: currentPageMD,
                totalItems: totalMD
              }"
          >

            <div class="w-full grid md:grid-cols-12 gap-2">
              <span class="w-full break-all md:col-span-6 cursor-pointer hover:text-deloitte_gray10"
                matTooltip="{{ 'TOOLTIP.SHOW_RELATIONS' | translate }}"
                (click)="masterDataSelected(element)"
              >
                {{getMDValue(element)}}
              </span>
              <span class="w-full hidden md:block break-all md:col-span-6">{{getMDDescription(element)}}</span>
            </div>

            <div class="flex gap-2 w-64 md:justify-between items-baseline">
              <button class="hover:text-deloitte_gray10 md-btn hidden md:block" (click)="editMD(element)">
                {{"BUTTON.EDIT" | translate}}
              </button>
              <button *ngIf="!isActive(element)" class="hover:text-deloitte_gray10  md-btn" (click)="activateMD(element)">
                {{"BUTTON.ACTIVATE" | translate}}
              </button>
              <button *ngIf="isActive(element)" class="hover:text-deloitte_red  md-btn" (click)="deactivateMD(element)">
                {{"BUTTON.DEACTIVATE" | translate}}
              </button>
              <button class="{{element.usages === 0 ? 'hover:text-deloitte_red  md-btn' : 'disabled-btn'}}"
                (click)="deleteMD(element)" [disabled]="element.usages > 0"
              >
                {{"BUTTON.DELETE" | translate}}
              </button>
            </div>
          </div>

          <div class="w-full block text-center mt-8 mb-8">
            <pagination-controls
              id='md_pagination'
              (pageChange)="changeMDPage($event)"
              [directionLinks]="true"
              [responsive]="true"
              previousLabel="PREV"
              nextLabel="NEXT"
              [autoHide]="true"
            ></pagination-controls>
          </div>
        </div>

        <div *ngIf="!loading && options.mdElements.length === 0">
          <h1 class="p-3 font-bold text-2xl md:text-3xl">
            {{ "LABEL.NO_RESULTS" | translate }}
          </h1>
        </div>
      </div>

      <!-- relations -->
      <div *ngIf="!loading && !loadingRelations && options?.mdRelations && mdElement" class="my-8">
        <div class="flex flex-col md:flex-row mb-2">
          <div class="flex-auto font-bold">{{getRelationsTitle()}}</div>
          <div *ngIf="hasRelations()" class="gap-2 hidden md:flex">
            <!-- <app-button theme="main_block_strech" (click)="addRelation()" text="ADD_RELATION"></app-button> -->
            <app-button theme="main_block_strech" (click)="editRelation()" text="EDIT"></app-button>
          </div>
        </div>

        <div class="flex w-full border-b pb-2 mb-2 border-b-deloitte_gray9" *ngFor="let item of getRelations() | paginate
            : {
              id: 'relations_pagination',
              itemsPerPage: itemsPerPageRelations,
              currentPage: currentPageRelations,
              totalItems: totalRelations
            }
        ">
          <div class="w-full">
            <div class="grid md:grid-cols-12">
              <span class="md:col-span-3">{{"MASTERDATA_TYPES." + item.child | uppercase | translate}}</span>
              <span class="md:col-span-9">{{ item?.childValue | getMasterDataElement: item.child : currentLang() }}</span>
            </div>
          </div>
        </div>

        <div class="w-full block text-center mt-8 mb-8">
          <pagination-controls
            id='relations_pagination'
            (pageChange)="changeRelationsPage($event)"
            [directionLinks]="true"
            [responsive]="true"
            previousLabel="PREV"
            nextLabel="NEXT"
            [autoHide]="true"
          ></pagination-controls>
        </div>

        <div *ngIf="!hasRelations()">
          <span>{{"LABEL.NO_RELATIONS" | translate}}</span>
        </div>
      </div>
    </div>

    <!-- loader -->
    <app-loader *ngIf="loading" class="flex justify-center mt-8"></app-loader>
    <app-loader *ngIf="!loading && loadingRelations && mdElement" class="flex justify-center mt-8"></app-loader>
  </div>
</div>

<!-- Add new masterdata and edit masterdata -->
<app-modal #modal [big]="true">
  <div (click)="closeModal()" class="absolute top-3 right-3 md:right-6">
    <img class="scale-75 cursor-pointer" src="assets/svg/Icon-Close@2x.svg" />
  </div>

  <form [formGroup]="mdForm">
    <!-- <div>
      <div class="w-16">
        <span>{{ "MASTERDATA_TYPES." + mdType | uppercase | translate }}</span>
      </div>

      <div *ngIf="editing">
        <input class="no-border" type="text" disabled formControlName="id" />
      </div>
    </div> -->

    <div *ngIf="!editing">
      <div class="w-full mt-2">
        <div class="flex justify-between">
          <label>{{"LABEL.ID" | translate}}
            <span class="red" *ngIf="mdForm.get('id').hasError('required') || mdForm.get('id').hasError('pattern')"> * </span>
          </label>
          <div class="flex" style="flex-direction: column;">
             <input formControlName="id" type="text" [value]="getIDPrefix()" title="jkkljsdflaskdhf" />
           <span class="text-sm" *ngIf="mdForm.get('id').hasError('required') || mdForm.get('id').hasError('pattern')"> {{"TOOLTIP.MASTERDATA_ID_FORMAT" | translate}} </span>
          </div>

        </div>
      </div>
    </div>

    <div formGroupName="i18n" class="langsGroup">
      <div class="langGroup-shadow" *ngFor="let item of getLangs()" formGroupName="{{item}}">
        <div class="w-16 mt-2 mb-2">
          {{item | uppercase}}
        </div>

        <div class="mt-2 mb-2">
          <div class="flex justify-between">
            <label>{{"LABEL.NAME" | translate}}
              <span class="red" *ngIf="mdForm.get('i18n').get(item).get('name').hasError('required')" > * </span>
            </label>
            <input formControlName="name" type="text" />
          </div>

          <div class="flex justify-between">
            <label>{{"LABEL.DESCRIPTION" | translate}}</label>
            <input formControlName="description" type="text">
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="flex justify-center mt-5 md:mt-8">
    <app-button *ngIf="!editing" [disabled]="mdForm.invalid" class="mr-8" (click)="addNewMD()" text="SAVE"></app-button>
    <app-button *ngIf="editing" [disabled]="mdForm.invalid" class="mr-8" (click)="updateMD()" text="SAVE"></app-button>
    <app-button (click)="closeModal()" text="CANCEL"></app-button>
  </div>
</app-modal>

<!-- Add new relations and edit relations -->
<app-modal #relationsModal [big]="true">
  <div (click)="closeRelationsModal()" class="absolute top-3 right-3 md:right-6">
    <img class="scale-75 cursor-pointer" src="assets/svg/Icon-Close@2x.svg" />
  </div>

  <form [formGroup]="relationForm" *ngIf="mdElement && options.mdRelations">
    <div class="w-full mb-4" *ngIf="relationForm.get('child').value">
      <span class="w-full font-bold col-span-4">
        {{"MASTERDATA_TYPES."+relationForm.get('child').value | uppercase | translate}}
      </span>
    </div>

    <div class="w-full flex gap-2">
      <div class="flex-auto">
        <div *ngIf="!relationForm.get('child').value">
          <select formControlName="child" style="width: 100%;">
            <option [value]="null" disabled selected hidden>
              {{ getSelectMessage("SELECT_ONE.MD_TYPE") }}
            </option>
            <option *ngFor="let item of getRelationChilds(); let len = length" [value]="item">
              {{"MASTERDATA_TYPES."+item | uppercase | translate}}
            </option>
          </select>
        </div>

        <div *ngIf="relationForm.get('child').value">
          <div class="flex gap-2">
            <input
              [(ngModel)]="term" type="text"
              placeholder="{{'BUTTON.SEARCH'|translate}}"
              [ngModelOptions]="{standalone: true}"
            />

            <img src="assets/svg/search_input.svg" />
          </div>

          <div class="flex gap-4">
            <select class="w-full" formControlName="childKey">
              <option [value]="null" disabled selected hidden>
                {{ getSelectMessage("SELECT_ONE.MD") }}
              </option>
              <option *ngFor="let item of getRelationChildKeys(relationForm.get('child')?.value)" [value]="item">
                <span class="break-all">
                  {{ item | getMasterDataElement: relationForm.get('child')?.value : currentLang() }}
                </span>
              </option>
            </select>

            <div class="flex justify-end align-baseline">
              <button class="md-btn text-deloitte_green" [disabled]="relationForm.invalid" (click)="addRelationToList()">
                &#43;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="childValues && len === 0" class="my-2">
      <span class="red">{{"MESSAGES.ALL_VALUES_USED" | translate}}</span>
    </div>

  </form>

  <!-- Added relations -->
  <div *ngIf="addedRelations.length > 0" class="min-w-[30rem] h-80 p-2 overflow-y-scroll">
   <div *ngFor="let item of addedRelations | search:term" class="w-full flex">
      <div class="flex-auto">
        <!-- <span class="col-span-3">{{"MASTERDATA_TYPES." + item.child | uppercase | translate}}</span> -->
        <span>{{ item?.childKey | getMasterDataElement: item.child : currentLang() }}</span>
      </div>
      <div class="flex justify-end">
        <span class="red cursor-pointer" (click)="removeAddedRelation(item)">&times;</span>
      </div>
    </div>
  </div>

  <div *ngIf="relationForm.get('child').value && addedRelations.length === 0">
    <span>{{"LABEL.NO_RELATIONS" | translate}}</span>
  </div>

  <!-- save / cancel -->
  <div *ngIf="relationForm.get('child').value" class="flex justify-center mt-5 md:mt-8">
    <app-button
      *ngIf="!editingRelation"
      [disabled]="!addedRelations || addedRelations.length === 0"
      class="mr-8" (click)="updateNewRelations()" text="SAVE"
    ></app-button>
    <app-button
      *ngIf="editingRelation"
      [disabled]="!addedRelations"
      class="mr-8" (click)="updateExistingRelations()" text="SAVE"
    ></app-button>
    <app-button (click)="closeRelationsModal()" text="CANCEL"></app-button>
  </div>
</app-modal>

<app-modal #deleteMDModal class="h-auto">
  <div (click)="closeDeleteMDModal()" class="absolute top-3 right-3 md:right-6">
    <img class="scale-75 cursor-pointer" src="assets/svg/Icon-Close@2x.svg" />
  </div>
  <div class="px-12">
    <div class="text-2xl font-bold text-center text-black mb-4">
      {{ "MESSAGES.CONFIRM_DELETE_MD" | uppercase | translate }}
    </div>
    <div class="text-sm font-bold text-center justify-start text-deloitte_black mb-14">
      {{ "MESSAGES.PERM_DELETE_MD" | uppercase | translate }}
    </div>
    <div class="grid grid-cols-2 gap-2 md:gap-6">
      <app-button theme="main_block" (click)="confirmDeleteMD()" text="YES"></app-button>
      <app-button theme="main_block" (click)="closeDeleteMDModal()" text="NO"></app-button>
    </div>
  </div>
</app-modal>

<!-- reload page -->
<app-modal #reloadPageModal class="h-auto">
  <div class="px-12">
    <div class="text-2xl font-bold text-center text-black mb-4">
      {{ "MESSAGES.RELOAD_PAGE" | uppercase | translate }}
    </div>
    <div class="grid grid-cols-2 gap-2 md:gap-6">
      <app-button theme="main_block" (click)="reloadPage()" text="YES"></app-button>
      <app-button theme="main_block" (click)="closeReloadPageModal()" text="NO"></app-button>
    </div>
  </div>
</app-modal>
