import { Observable, throwError, timer } from "rxjs";
import { finalize, mergeMap } from "rxjs/operators";

export const genericRetryStrategy = <T>({
    waitTimes = [1000, 2000, 4000],
    filter = undefined,
    tapper = undefined,
  }: {
    waitTimes?: number[],
    filter?: (e: T) => boolean,
    tapper?: (i: number, wait: number) => void,
  } = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i;
          
        if (retryAttempt > waitTimes.length || filter?.(error) === false) {
            return throwError(error);
        }

        const retryWait = waitTimes[retryAttempt];
        console.log(`Attempt ${retryAttempt + 1}: retrying in ${retryWait}ms`);

        tapper?.(i, retryWait);
        return timer(retryWait);
      }),
      finalize(() => console.log('Retry done'))
    );
  };