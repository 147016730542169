<div class="w-full inline-flex cursor-pointer">
  <div class="w-[11rem] md:w-1/5 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[2] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
        "
      >
        <ul>
          <li *ngFor="let group of user?.groups">{{ group }}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="w-[18rem] md:w-1/5 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[3] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
          break-words
        "
      >
        {{ user?.attributes.email }}
      </div>
    </div>
  </div>

  <div class="w-[18rem] md:w-1/5 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[0] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
        "
      >
        {{ user?.attributes.name }}
      </div>
    </div>
  </div>

  <div class="w-[18rem] md:w-1/5 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[1] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
        "
      >
        {{ user?.attributes.familyName }}
      </div>
    </div>
  </div>

  <div class="w-[18rem] md:w-1/5 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span class="inline-block truncate w-full px-4">{{ headers[4] }}</span>
    </div>
    <div *ngIf="loadingCompany" class="py-2 bg-white flex h-full justify-center">
      <app-loader  diameter="20"></app-loader>
</div>
    <div *ngIf="!loadingCompany" class="py-2 bg-white flex h-full">
      <div  class="w-full text-xs px-4 inline-block">
        {{ company ? company: "LABEL.NO_COMPANY" | translate }}
      </div>
    </div>
  </div>
</div>
