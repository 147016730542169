<!-- change language -->
<div class="wrap" [formGroup]="form">
  <!-- 25% -->
  <div class="margin">
    <div class="input-margin-top center">
      <span class="label"></span>
    </div>
  </div>
  <div>
    <div class="center">
      <div class="responsive-width">
        <div
          class="responsive-width center langSection"
          [attr.langsCount]="formHelper.getNumberOfLangs()"
        >
          <div
            *ngFor="let lang of availableLangs"
            class="langItem"
            [attr.status]="getLangStatus(lang)"
          >
            <button
              class="langButton"
              (click)="formHelper.setSelectedLang(lang)"
            >
              {{ "LANGUAGE." + lang | uppercase | translate }}
            </button>

            <div class="langExtra">
              <input
                type="radio"
                [id]="'lang_default_radio_' + lang"
                name="defaultLang"
                [value]="lang"
                formControlName="defaultLang"
              />
              <label [for]="'lang_default_radio_' + lang">{{
                "LABEL.DEFAULT_LANG_CHECK" | translate
              }}</label>
              <button class="removeLangButton" (click)="removeLang(lang)">
                <img src="assets/svg/remove.svg" />
              </button>
            </div>
          </div>
        </div>
        <div
          [ngClass]="{
            'visibility-hidden': !(
              form.hasError('incompleteLangData') && form.dirty
            ),
            'visibility-visible':
              form.hasError('incompleteLangData') && form.dirty
          }"
        >
          <span class="red"> {{ "LABEL.FIELDS_REQUIRED" | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="formHelper.langForm" class="horizontal-line-0"/>

<!-- form -->
<div class="formWrapper" [formGroup]="form" [hidden]="!formHelper.langForm">
  <ng-container *ngIf="formHelper.langForm; let langForm">
    <!-- Title & Description -->
    <div class="wrap">
      <!-- 25% -->
      <div class="margin">
        <div class="input-margin-top center">
          <span class="label">{{ "LABEL.DESCRIPTION" | translate }}</span>
        </div>
      </div>
      <!-- 50% -->
      <div style="width: 50%">
        <div class="center">
          <div class="responsive-width">
            <!-- title -->

            <div class="input-margin-top">
              <span class="label"
                >{{ "LABEL.TITLE" | translate }} ({{
                  "LANGUAGE." + langForm.lang | uppercase | translate
                }})</span
              >
              <span
                class="red"
                *ngIf="langForm.form.get('title').hasError('required')"
                >*</span
              >
            </div>
            <input
              class="input-form"
              [formControl]="langForm.form.get('title')"
              for
              type="text"
            />

            <!-- description -->
            <div class="input-margin-top">
              <span class="label"
                >{{ "LABEL.DESCRIPTION" | translate }} ({{
                  "LANGUAGE." + langForm.lang | uppercase | translate
                }})</span
              >
              <span
                class="red"
                *ngIf="langForm.form.get('description').hasError('required')"
                >*</span
              >
            </div>
            <textarea
              class="input-form"
              style="height: 150px"
              [formControl]="langForm.form.get('description')"
            ></textarea>
          </div>
        </div>
      </div>
      <!-- 25% -->
      <!-- <div class="margin">
            titulo
          </div> -->
    </div>

    <!-- Document & Link-->
    <div class="wrap">
      <!-- 25% -->
      <div class="margin">
        <div class="input-margin-top center">
          <span class="label">{{ "LABEL.LINKS" | translate }}</span>
        </div>
      </div>
      <div style="width: 50%">
        <div class="center">
          <div class="responsive-width">
            <!-- link -->
            <ng-container
              *ngIf="langForm.form.get('link').get('link'); let control"
            >
              <div class="input-margin-top">
                <span class="label">{{ "LABEL.LINK" | translate }}</span>
                <span class="red" *ngIf="control.invalid">*</span>
              </div>
              <input
                class="input-form"
                placeholder="https://..."
                type="text"
                [formControl]="control"
              />
              <div
                class="input-error-bottom"
                *ngIf="control.hasError('pattern')"
              >
                <span class="red">{{
                  "VALIDATORS.URL_FORMAT" | translate
                }}</span>
              </div>
            </ng-container>

            <!-- document -->
            <ng-container
              *ngIf="
                langForm.form.get('document').value;
                let document;
                else: uploadDocumentTemplate
              "
            >
              <div class="input-margin-top">
                <span class="label">{{ "SECTION.DOCUMENT" | translate }}</span>
              </div>

              <div class="space-between">
                <a class="download" (click)="openDocument(document)">
                  {{ document.title }}
                </a>
                <button (click)="langForm.form.get('document').reset()">
                  <img src="assets/svg/remove.svg" />
                </button>
              </div>
            </ng-container>

            <ng-template #uploadDocumentTemplate>
              <div class="input-margin-top">
                <span class="label">{{ "LABEL.PDF" | translate }}</span>
                <span
                  class="red"
                  *ngIf="langForm.form.get('document').hasError('required')"
                  >*</span
                >
              </div>

              <div class="button-wrapper my-2" *ngIf="!uploadDocumentTask">
                <label
                  class="w-full pointer rounded focus:outline-none appearance-none py-2.5 px-5 border border-black bg-white text-base text-black mb-3 mt-1"
                  for="file"
                  >{{ "BUTTON.UPLOAD" | translate }}</label
                >

                <input
                  name="file"
                  id="file"
                  (change)="
                    uploadDocument(langForm.form.get('document'), $event)
                  "
                  type="file"
                  accept=".pdf,.xls,.xlsx"
                  class="upload-file"
                />
              </div>
              <ng-container
                *ngIf="uploadDocumentTask"
                [ngTemplateOutlet]="uploadTaskTemplate"
                [ngTemplateOutletContext]="{ task: uploadDocumentTask }"
                >
              </ng-container>

            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- INFO -->
  <hr class="horizontal-line" />
  <div class="wrap">
    <!-- 25% -->
    <div class="margin">
      <div class="input-margin-top center">
        <span class="label">{{ "LABEL.GENERAL_INFO" | translate }}</span>
      </div>
    </div>
    <!-- 50% -->
    <div style="width: 50%">
      <div class="center">
        <div class="responsive-width">
          <!-- area -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.AREA" | translate }}</span>
            <span class="red" *ngIf="form.get('areas').hasError('required')"
              >*</span
            >
          </div>
          <app-multiselect
            [showAll]="false"
            [items]="areas"
            (valueChanged)="form.get('areas').setValue($event)"
            [value]="form.get('areas').value"
          >
          </app-multiselect>

          <!-- field(materia) -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.FIELD" | translate }}</span>
            <span class="red" *ngIf="form.get('fields').hasError('required')"
              >*</span
            >
          </div>

          <app-multiselect
            [showAll]="false"
            [attr.disabled]="form.get('fields').disabled || null"
            [items]="fields | async"
            (valueChanged)="form.get('fields').setValue($event)"
            [value]="form.get('fields').value"
          >
          </app-multiselect>

          <!-- iniciativa -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.INITIATIVES" | translate }}</span>
            <span
              class="red"
              *ngIf="form.get('initiatives').hasError('required')"
              >*</span
            >
          </div>
          <app-multiselect
            [showAll]="false"
            [items]="initiatives"
            (valueChanged)="form.get('initiatives').setValue($event)"
            [value]="form.get('initiatives').value"
          >
          </app-multiselect>

          <div class="center">
            <hr class="horizontal-line-mini" />
          </div>

          <!-- cluster -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.TYPE_GEOSCOPE" | translate }}</span>
            <span class="red" *ngIf="form.get('cluster').hasError('required')"
              >*</span
            >
          </div>
          <div class="wrapper">
            <div
              style="display: flex; align-items: center"
              *ngFor="let cluster of clusters"
            >
              <div>
                <input
                  [id]="'cluster_' + cluster?.id"
                  [value]="cluster?.id"
                  type="radio"
                  formControlName="cluster"
                />
              </div>
              <div style="margin-left: 8px">
                <label [for]="'cluster_' + cluster?.id">{{
                  cluster?.name
                }}</label>
              </div>
            </div>
          </div>

          <!-- geo scope -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.GEOSCOPE" | translate }}</span>
            <span class="red" *ngIf="form.get('geo_scope').hasError('required')"
              >*</span
            >
          </div>
          <!-- name="geo_scope"  -->
          <select
            id="geo_scope"
            formControlName="geo_scope"
            [value]="form.get('geo_scope').value"
          >
            <option value="null" selected disabled hidden></option>
            <option
              *ngFor="let option of geo_scopes | async"
              [value]="option.id"
            >
              {{ option.name }}
            </option>
          </select>

          <!-- [selected]="option === registerForm.get('geo_scope').value" -->

          <!-- issuer -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.ISSUING_BODY" | translate }}</span>
            <span class="red" *ngIf="form.get('issuer').hasError('required')"
              >*</span
            >
          </div>
          <select name="issuer" id="issuers" formControlName="issuer">
            <option value="null" selected disabled hidden></option>
            <option
              *ngFor="let option of issuers | async"
              value="{{ option.id }}"
            >
              {{ option.name }}
            </option>
          </select>

          <div class="center">
            <hr class="horizontal-line-mini" />
          </div>

          <!-- phase -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.TYPE_RULE" | translate }}</span>
            <span class="red" *ngIf="form.get('phase').hasError('required')"
              >*</span
            >
          </div>
          <div class="wrapper">
            <div
              style="display: flex; align-items: center"
              *ngFor="let phase of phases"
            >
              <div>
                <input
                  [id]="'phase_' + phase?.id"
                  [value]="phase?.id"
                  type="radio"
                  formControlName="phase"
                />
              </div>
              <div style="margin-left: 8px">
                <label [for]="'phase_' + phase?.id">{{ phase?.name }}</label>
              </div>
            </div>
          </div>

          <!-- type -->
          <div class="input-margin-top" [hidden]="form.get('type').disabled">
            <span class="label">{{ "LABEL.SUBTYPE_RULE" | translate }}</span>
            <span class="red" *ngIf="form.get('type').hasError('required')"
              >*</span
            >
          </div>
          <select
            name="type"
            id="types"
            formControlName="type"
            [hidden]="form.get('type').disabled"
          >
            <option value="null" selected disabled hidden></option>
            <option
              *ngFor="let option of types | async"
              value="{{ option.id }}"
            >
              {{ option.name }}
            </option>
          </select>

          <!--


                      HECHO phase = cualquiera -> publicacion  (oblicatoria)
                      HECHO phase = normas o criterios imperativos y tramitacion = aprobada-> entrada en vigor (obligatoria si aparece)
                      HECHO phase = iniciativas normativas y estado tramitacion = tramitacion -> fecha de cierre (no obligatorias aunque aparezcamn)
                      HECHO phase = Normas / Iniciativas normativas y estado de tramitacion da igual -> aprobacion (no obligatorias aunque aparezcamn)
                      HECHO phase = Normas / Criterios interpretativos y estado de tramitacion da igual ->aplicacion (no obligatorias aunque aparezcamn)
                  -->

          <!-- processing state, closing date, approval date -->
          <div class="space-between">
            <!-- processing state -->
            <div>
              <div class="input-margin-top">
                <span class="label">{{
                  "LABEL.PROCESSING_STATUS" | translate
                }}</span>
                <span class="red" *ngIf="form.get('state').hasError('required')"
                  >*</span
                >
              </div>
              <div
                style="display: flex; align-items: center"
                *ngFor="let state of processingStates"
              >
                <div>
                  <input
                    [id]="'state_' + state?.id"
                    [value]="state?.id"
                    type="radio"
                    formControlName="state"
                  />
                </div>
                <div style="margin-left: 8px">
                  <label [for]="'state_' + state?.id">{{
                    state?.name | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="center">
            <hr class="horizontal-line-mini" />
          </div>

          <div class="wrap">
            <!-- publication date -->
            <ng-container
              [ngTemplateOutlet]="datePickerTemplate"
              [ngTemplateOutletContext]="{ name: 'publication_date' }"
            >
            </ng-container>
          </div>

          <div class="wrap" *ngIf="form.get('phase').valid">

            <!-- approval date -->
            <ng-container
              [ngTemplateOutlet]="datePickerTemplate"
              [ngTemplateOutletContext]="{ name: 'approval_date' }"
            >
            </ng-container>

            <!-- effective date -->
            <ng-container
              [ngTemplateOutlet]="datePickerTemplate"
              [ngTemplateOutletContext]="{ name: 'effective_date' }"
            >
            </ng-container>

            <!-- application date -->
            <ng-container
              [ngTemplateOutlet]="datePickerTemplate"
              [ngTemplateOutletContext]="{ name: 'application_date' }"
            >
            </ng-container>
            <!-- closing date -->
            <ng-container
              [ngTemplateOutlet]="datePickerTemplate"
              [ngTemplateOutletContext]="{ name: 'closing_date' }"
            >
            </ng-container>
          </div>

          <div class="center">
            <hr class="horizontal-line-mini" />
          </div>

          <!-- impact -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.IMPACT" | translate }}</span>
            <span class="red" *ngIf="form.get('impact').hasError('required')"
              >*</span
            >
          </div>
          <div class="space-between">
            <div
              style="display: flex; align-items: center"
              *ngFor="let impact of impacts"
            >
              <div>
                <input
                  [id]="'impact_' + impact?.id"
                  [value]="impact?.id"
                  type="radio"
                  formControlName="impact"
                />
              </div>
              <div style="margin-left: 8px">
                <label [for]="'impact_' + impact?.id">{{
                  impact?.name | translate
                }}</label>
              </div>
            </div>
          </div>

          <!-- required_action -->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.REQUIRED_ACTION" | translate }}</span>
          </div>
          <div>
            <div>
              <input [value]="true" name="requiredAction" type="radio" formControlName="requiredAction" />
              <span style="margin-left: 8px">{{"BUTTON.YES" | translate}}</span>
            </div>
            <div>
              <input [value]="false" name="requiredAction" type="radio" formControlName="requiredAction" />
              <span style="margin-left: 8px">{{"BUTTON.NO" | translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 25% -->
    <!-- <div class="margin">
            </div> -->
  </div>
</div>

<hr class="{{ formHelper.langForm ? 'horizontal-line': 'horizontal-line-0' }}" />

<div class="wrap" *ngIf="formHelper.langForm; let langForm">
  <!-- 25% -->
  <div class="margin">
    <div class="input-margin-top center">
      <span class="label">{{ "LABEL.LINKS" | translate }}</span>
    </div>
  </div>
  <div style="width: 50%">
    <div class="center">
      <div class="responsive-width">
        <ng-container *ngIf="formHelper.langForm; let langForm">

          <!-- other links and pdfs added-->
          <div class="input-margin-top">
            <span class="label">{{ "LABEL.LINKS_ADDED" | translate }}</span>
          </div>
          <div
            *ngIf="
              langForm.form.get('other_documents').value.length ||
              langForm.form.get('other_links').value.length
            "
          >
            <div
              *ngFor="
                let link of langForm.form.get('other_links').value;
                let index = index
              "
            >
              <div class="space-between">
                <li>
                  <a [href]="link.link" target="_blank">
                    {{ link.title }}
                  </a>
                </li>
                <button
                  (click)="removeItem(langForm.form.get('other_links'), index)"
                >
                  <img src="assets/svg/remove.svg" />
                </button>
              </div>
            </div>
            <div
              *ngFor="
                let pdf of langForm.form.get('other_documents').value;
                let index = index
              "
            >
              <div class="space-between">
                <li>
                  <a class="download" (click)="openDocument(pdf)">
                    {{ pdf.title }}
                  </a>
                </li>
                <button
                  (click)="
                    removeItem(langForm.form.get('other_documents'), index)
                  "
                >
                  <img src="assets/svg/remove.svg" />
                </button>
              </div>
            </div>

            <ng-container
              *ngFor="let task of uploadOtherDocumentTasks;"
              [ngTemplateOutlet]="uploadTaskTemplate"
              [ngTemplateOutletContext]="{ task: task }"
            >
            </ng-container>
          </div>

        <div class="others_buttons">
          <div>
            <div class="button-wrapper my-2">
              <label
                class="w-full pointer rounded focus:outline-none appearance-none py-2.5 px-5 border border-black bg-white text-base text-black mb-3 mt-1"
                (click)="openOtherLinkForm()">{{ "BUTTON.ADD_LINK" | translate }}</label>
            </div>
          </div>
          <div>
            <div class="button-wrapper my-2">
              <label
                class="w-full pointer rounded focus:outline-none appearance-none py-2.5 px-5 border border-black bg-white text-base text-black mb-3 mt-1"
                for="file_other">{{ "BUTTON.UPLOAD" | translate }}</label>

              <input name="file" id="file_other" (change)="uploadOtherDocuments(langForm.form.get('other_documents'), $event)" type="file" accept=".pdf,.xls,.xlsx"
                class="upload-file" />
            </div>
          </div>
        </div>


        <div [hidden]="otherLinkForm.disabled">
        <div class="center">
          <hr class="horizontal-line-mini" />
        </div>
        <form class="linkForm" [formGroup]="otherLinkForm">
          <!-- other links -->
          <input #otherLinkFocusableInput class="input-form" placeholder="https://..." type="text" formControlName="link"/>
          <div class="input-error-bottom" *ngIf="otherLinkForm.get('link').hasError('pattern')">
            <span class="red" >{{ "VALIDATORS.URL_FORMAT" | translate }}</span>
          </div>
          <input class="input-form" formControlName="title" type="text" placeholder="{{ 'LABEL.NAME_LINK' | translate }}" />

          <div class="linkFormButtons">
            <app-button style="margin-left: 50px" theme="main_black" text="ADD_LINK" (click)="addOtherLinks(otherLinkForm, langForm.form.get('other_links'))" [disabled]="otherLinkForm.invalid">
            </app-button>
            <app-button style="margin-left: 50px" theme="main_black" text="RETURN" (click)="otherLinkForm.disable()">
            </app-button>
          </div>
        </form>
      </div>




      </ng-container>
        <div class="center">
          <hr class="horizontal-line-mini" />
        </div>

        <div style="margin-top: 2rem">
          <app-evolution-filter
            [evolutionsLoaded]="form.get('evolutions').value"
            [textLang]="formHelper.selectedLang"
            (valueChanged)="addItem(form.get('evolutions'), $event)"
            (elementRemoved)="removeItem(form.get('evolutions'), $event)"
            (stateChanged)="changeEvolutionState($event)"
          ></app-evolution-filter>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #labelTemplate let-control="control" let-label="label">
  <div class="input-margin-top">
    <span class="label">{{ label | uppercase | translate }} </span>
    <span class="red" *ngIf="control.hasError('required')">*</span>
  </div>
</ng-template>

<ng-template #datePickerTemplate let-name="name" let-optional="optional">
  <ng-container *ngIf="form.get(name); let control">
    <!-- If it has value, or (in case config exist) it's on datesToShow. -->
    <div
      *ngIf="!datesToShow || datesToShow.includes(name)"
      class="date_field"
    >
      <ng-container
        [ngTemplateOutlet]="labelTemplate"
        [ngTemplateOutletContext]="{ control: control, label: 'LABEL.' + name }"
      ></ng-container>
      <!-- <div class="input-margin-top">
        <span class="label">{{'LABEL.' + name  | uppercase | translate }}</span>
        <span class="red" *ngIf="control.hasError('required')">*</span>
      </div> -->
      <app-datepicker
        [id]="name"
        [name]="name"
        (change)="control.setValue($event.target.value)"
        [value]="control.value"
      >
      </app-datepicker>
      <div
        class="input-error-bottom"
        *ngIf="control.invalid && !control.hasError('required')"
      >
        <span class="red" *ngFor="let entry of control.errors | keyvalue">
          <ng-container *ngIf="entry.key.startsWith('dateRel_')">
            {{
              "VALIDATORS.DATE_RELATION"
                | translate: localizeDateRelationError(entry.value)
            }}
          </ng-container>
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #uploadTaskTemplate let-task="task">
  <div class="uploadTask">
    <mat-spinner [diameter]="30" [color]="primary"></mat-spinner>
    <div class="uploadTask_filename">
      {{ task.name }}
    </div>
    <div class="uploadTask_progress">
      <ng-container *ngIf="task.task?.percent">
        {{ task.task?.percent }}%
      </ng-container>
    </div>
    <button (click)="cancelUploadTask(task)" *ngIf="task.task">
      <img src="assets/svg/remove.svg" />
    </button>
  </div>
</ng-template>
