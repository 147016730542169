import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Menu, MenuOut, UserAppActions } from '../../models/menu-data.model';

@Injectable({
  providedIn: 'root'
})
export class UserActionsService {
  private actions$ = new BehaviorSubject<Partial<UserAppActions>>(null);
  private menu$ = new BehaviorSubject<Menu[]>(null);

  constructor(private http: HttpClient) { }

  loadUserActions() {
    this.http.get<MenuOut>("/config/menu", { headers: { 'Content-Type': 'application/json;' } })
      .pipe(
        tap((res) => {
          this.menu$.next(res.menu);
          this.actions$.next(res.actions);
        })
      ).subscribe()
  }

  getMenuOptions(): Observable<Menu[]> {
    return this.menu$;
  }

  canAccess(action: keyof UserAppActions): Observable<boolean> {
    return this.actions$
      .pipe(
        filter(v => !!v),
        map(actions => {
          return actions?.[action] || false;
        })
      )
  }

  clearUserActions(): void {
    this.actions$.next({});
    this.menu$.next([]);
  }
}
