import { AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { delay, distinctUntilChanged, map, startWith, tap } from "rxjs/operators";
import { LangInfo, Link } from "src/app/shared/models/rules.model";


export class RuleFormHelper {

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {

  }

  get selectedLang(): string {
    return this.form.get('selectedLang').value
  }

  get langInfo(): UntypedFormGroup {
    return this.form.get('lang_info') as UntypedFormGroup;
  }

  get langForm(): { form: UntypedFormGroup, empty$: Observable<boolean>, lang: string } {
    const lang: string = this.selectedLang;
    const form = this.getLangForm(lang);
    if (!form) {
      return null;
    }

    const emptyObs = form.valueChanges.pipe(
      startWith(form.value),
      map(v => isLangGroupValueEmpty(v))
    )

    return {
      form,
      empty$: emptyObs,
      empty: isLangGroupEmpty(form),
      lang: lang,
    } as any
  }

  build(): UntypedFormGroup {
    this.form = buildRuleForm(this.formBuilder);
    return this.form;
  }

  setSelectedLang(lang: string) {

    // Empty lang can be removed
    this.removeLang(this.selectedLang)

    if (!this.hasLang(lang)) {
      this.addLang(lang);
    }

    this.form.get('selectedLang').setValue(lang);

    this.syncLang();
  }

  getLangForm(_lang: string): UntypedFormGroup {
    const langInfo = this.langInfo;

    const lang = _lang || this.form.get('defaultLang').value || Object.keys(langInfo.value)[0];
    return langInfo.get(lang) as UntypedFormGroup;
  }

  hasLang(lang: string): boolean {
    return !!this.getLangForm(lang);
  }

  getNumberOfLangs(): number {
    return Object.keys(this.langInfo.controls).length
  }

  addLang(lang: string): UntypedFormGroup {
    if (this.getLangForm(lang)) {
      return null;
    }

    const group = buildLangInfoForm(this.formBuilder);

    // TODO: translate ?
    group.get('link').get('title').setValue('_rule_main_link_');

    this.langInfo.addControl(lang, group);

    return group;
  }

  removeLang(lang: string, force: boolean = false): boolean {
    const group = this.getLangForm(lang);
    if (!group) {
      return true;
    }

    if (!force && !isLangGroupEmpty(group)) {
      return false;
    }

    this.langInfo.removeControl(lang);

    if (this.form.get('defaultLang').value == lang) {
      this.form.get('defaultLang').setValue(null);
    }

    if (this.selectedLang == lang) {
      this.form.get('selectedLang').setValue(null);
    }

    return true;
  }

  syncLang() {
    if (this.getNumberOfLangs() == 1 || !this.form.get('defaultLang').value) {
      // Only one, set as default
      this.form.get('defaultLang').setValue(this.selectedLang);
    }
  }

    prepareLangs(langs: string[]) {
        Object.keys(this.langInfo.value).forEach(lang => {
            this.removeLang(lang, true);
        });
        langs.forEach(lang => {
            this.addLang(lang);
        });
    }

}

export function isLangGroupEmpty(langGroup: UntypedFormGroup): boolean {
  const value = langGroup.value;
  return isLangGroupValueEmpty(value);
}

export function isLangGroupValueEmpty(value: LangInfo): boolean {
  return !(value.description || value.title || value.link?.link || value.document || value.other_documents?.length || value.other_links?.length);
}

export function buildRuleForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
  const form = buildForm(formBuilder);

    setupDatesValidation(form);

  return form;
}


export type DateType = any;
export interface FormModel {
  selectedLang: string,
  defaultLang: string,
  lang_info: {
    [key: string]: LangInfo
  },
  areas: string[],
  fields: string[],
  initiatives: string[],
  cluster: string,
  geo_scope: string,
  issuer: string,
  phase: string,
  type: string,
  state: number,
  effective_date: DateType,
  publication_date: DateType,
  approval_date: DateType,
  closing_date: DateType,
  application_date: DateType,
  impact: number,
  requiredAction: any, 
  evolutions: any, // TODO: Type
};

export type ControlKey = keyof FormModel;
type FormDefinitionModel<T> = { [key in keyof T]: unknown[] | UntypedFormGroup | UntypedFormArray };

function buildForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
  const group: FormDefinitionModel<FormModel> = {
    selectedLang: [, Validators.required],
    defaultLang: [, Validators.required],
    lang_info: formBuilder.group({}),


    areas: [[], Validators.required],
    fields: [[], Validators.required],
    initiatives: [[], Validators.required],

    cluster: [, Validators.required],
    geo_scope: [, Validators.required],
    issuer: [, Validators.required],

    phase: [, Validators.required],
    type: [, Validators.required],
    state: [, Validators.required],

    effective_date: [, ],
    publication_date: [, Validators.required],
    approval_date: [],
    closing_date: [],
    application_date: [],
    impact: [, Validators.required],
    requiredAction: [],
    evolutions: [[]],
  }
  return formBuilder.group(group);
}


export function buildLangInfoForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
  const group: FormDefinitionModel<LangInfo> = {
    title: [, Validators.required],
    description: [, Validators.required],
    link: formBuilder.group({
      title: [,],
      link: [, Validators.compose([Validators.required, Validators.pattern(UrlRegexp)])],
    }),
    document: [,],
    other_links: [[]],
    other_documents: [[]],
  };
  return formBuilder.group(group);
}

export function buildLinkForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
  const group : FormDefinitionModel<Link> = {
      title: [, Validators.required],
      link: [, Validators.compose([Validators.required, Validators.pattern(UrlRegexp)])],
    };
  return formBuilder.group(group);
}


const UrlRegexp = /(^http:\/\/|^https:\/\/)(\S{3,})(.\w{1,4}$)/;


function setupDatesValidation(form: UntypedFormGroup) {
    const controls: {[key in keyof FormModel]: AbstractControl} = form.controls as any;

    controls.effective_date.addValidators(datesRelationValidator(controls.effective_date, '>=', controls.publication_date, 'publication_date'));
    controls.application_date.addValidators(datesRelationValidator(controls.application_date, '>=', controls.publication_date, 'publication_date'));
    controls.closing_date.addValidators(datesRelationValidator(controls.closing_date, '>=', controls.publication_date, 'publication_date'));
    controls.approval_date.addValidators(datesRelationValidator(controls.approval_date, '>=', controls.publication_date, 'publication_date'));
}




export type Comparison = '<=' | '>=' | '<' | '>' | '=';
export type DateRelationError = { relation: Comparison , refValue: string, refName: string};



function datesRelationValidator(control: AbstractControl, relation: Comparison, refControl: AbstractControl, refName: ControlKey): ValidatorFn {
    refControl.valueChanges.pipe(
        distinctUntilChanged(),
        delay(10),
        tap(v => {
            control.updateValueAndValidity();
        })
    ).subscribe();

    return (c: AbstractControl) => {
        const refValue = refControl.value;

        if (!refValue) { // No ref value, it will be valid
            return null;
        }

        const value = c.value;

        if (!value) { // No value, it will be valid. If it needs to be required, add required validator to the control.
            return null;
        }

        const refDate = parseDate(refValue);
        const valueDate = parseDate(value);

        switch (relation) {
            case '<':  return valueDate <  refDate ? null : {[`dateRel_${refName}`]: { relation: relation, refValue: refValue, refName: refName}};
            case '<=': return valueDate <= refDate ? null : {[`dateRel_${refName}`]: { relation: relation, refValue: refValue, refName: refName}};
            case '=':  return valueDate == refDate ? null : {[`dateRel_${refName}`]: { relation: relation, refValue: refValue, refName: refName}};
            case '>=': return valueDate >= refDate ? null : {[`dateRel_${refName}`]: { relation: relation, refValue: refValue, refName: refName}};
            case '>':  return valueDate >  refDate ? null : {[`dateRel_${refName}`]: { relation: relation, refValue: refValue, refName: refName}};
        }
    }
}


function parseDate(value: string): Date {
    return new Date(value);
}
