<div class="pageContent">
  <div class="container">
    <app-menu></app-menu>
  </div>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>
