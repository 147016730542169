import { Command as $Command } from "@aws-sdk/smithy-client";
import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { environment } from "src/environments/environment.aws-dev";
import { CognitoIdentityProviderClient, ListGroupsCommand, ListUsersInGroupCommand, AdminRemoveUserFromGroupCommand, AdminAddUserToGroupCommand, UserType, ServiceInputTypes, ServiceOutputTypes, ListUsersCommand } from "@aws-sdk/client-cognito-identity-provider";
import { catchError, distinctUntilChanged, filter, map, shareReplay, take, tap } from "rxjs/operators";
import { tapOnError } from "src/app/shared/utils/rxjsUtils";
import { CognitoUser, GroupRef, CognitoGroup, CognitoUserAttributes } from "./cognito.model";
import { AWSService } from "src/aws/aws.service";


interface CognitoDataIndex {
    usersIndex: {[key: string]: CognitoUser}
    users: CognitoUser[],
    groups: {
        [key: GroupRef]: CognitoGroup
    },
}

@Injectable({
    providedIn: 'root'
  })
  export class CognitoService {

    private config = environment.aws.Auth;

    private get baseCommand() {
        return {
            UserPoolId: this.config.userPoolId
        }
    }

    private client: CognitoIdentityProviderClient = this.initClient();


    private data$: BehaviorSubject<CognitoDataIndex> = new BehaviorSubject(null);
    private dataLoaded: boolean = false;

    constructor(
        private awsService: AWSService,
    ) {

    }


    private initClient(): CognitoIdentityProviderClient {
        return new CognitoIdentityProviderClient({
            endpoint: this.config.endpoint,
            region: this.config.region,
            credentials: this.awsService.credentialsProvider()
          });
    }



///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LOCAL DATA MANAGEMENT
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    private emit() {
        this.data$.next(this.data$.value);
    }

    private updateData(mod: (data: CognitoDataIndex) => CognitoDataIndex) {
        // Protect against not loaded data
        if (!this.data$.value) {
            return;
        }

        this.data$.next(mod(this.data$.value));
    }

    private getFromData<T>(mapFn: (data: CognitoDataIndex) => T): Observable<T> {
        if (!this.dataLoaded) {
            this.initData().subscribe();
        }

        return this.data$.pipe(
            filter(v => v !== null),
            map(mapFn),
            distinctUntilChanged((a, b) => {
                if (!a && b || a && !b) {
                    return false;
                }

                if (!a && !b) {
                    return a !== b;
                }

                if (Array.isArray(a) && Array.isArray(b)) {
                    if (a.length != b.length) {
                        return false;
                    }
                }

                return JSON.stringify(a) === JSON.stringify(b)
            }),
            shareReplay(),
        )
    }

    refresh(): Observable<number> {
        return this.initData();
    }

    private initData(): Observable<number> {
        this.dataLoaded = true;

        return from(this.fetchAllData())
                .pipe(
                    map(data => this.indexData(data)),
                    tap(data => {
                        this.data$.next(data);
                    }),
                    map(data => data.users.length),
                    tapOnError(e => {
                        this.dataLoaded = false;
                    }),
                    take(1),
                )
    }

    private indexData(data: {allUsers: UserType[], groups: {group: string, users: UserType[]}[]}): CognitoDataIndex {
        const usersIndex: {[key: string]: CognitoUser} = {};
        const userList: CognitoUser[] = [];
        const groups: {[key: GroupRef]: CognitoGroup} = {};

        const newUser = (u: UserType) => {
            const user = this.mapUser(u);
            usersIndex[user.id] = user;
            userList.push(user);
            return user;
        }

        // Iterate all the users
        data.allUsers.forEach(user => {
            newUser(user);
        })

        // Link users and groups
        data.groups.forEach(entry => {
            const groupUsers = [];
            const groupRef = entry.group;

            entry.users.forEach(u => {
                const id = this.userId(u);
                const user: CognitoUser = usersIndex[id] || newUser(u);

                user.groups.push(groupRef);
                groupUsers.push(user);
            })

            groups[groupRef] = {
                users: groupUsers
            }
        });

        return {
            usersIndex: usersIndex,
            users: userList,
            groups: groups,
        }
    }

    private userId(user: UserType): string {
        return user.Attributes?.find(attr => attr.Name == 'sub')?.Value;
    }

    private mapUser(input: UserType): CognitoUser {
        const attributes: {[key: string]: string} = input.Attributes?.reduce((acc, entry) => {
            acc[entry.Name.toLowerCase()] = entry.Value;
            return acc;
        }, {});

        return {
            ...input,
            id: this.userId(input),
            attributes: {
                email: attributes.email,
                inApp: attributes['custom:inapp'] === 'true',
                name: attributes.given_name || attributes.name,
                familyName: attributes.family_name,
                receiveAlerts: attributes['custom:receivealerts'] === 'true',
                emailNewsletter: attributes['custom:emailnewsletter'] === 'true'
            },
            groups: [],
            company: attributes['custom:organization'],
        }
    }



///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OPERATIONS
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    getGroups(): Observable<GroupRef[]> {
        return this.getFromData(data => Object.keys(data?.groups || {}));
    }

    getUsersInGroup(group: GroupRef): Observable<CognitoUser[]> {
        return this.getFromData(data => data?.groups?.[group]?.users || []);
    }

    getUsersWithoutGroup(): Observable<CognitoUser[]> {
        return this.getFromData(data => data?.users?.filter(u => !u.groups?.length) || [])
    }

    getUsers(): Observable<CognitoUser[]> {
        return this.getFromData(data => data?.users || []);
    }

    searchUsersByEmail(query: string): Observable<CognitoUser[]> {
        return this.getFromData(data => data?.users?.filter(u => u.attributes.email?.includes(query) || []));
    }

    searchUsersByName(query: string): Observable<CognitoUser[]> {
      return this.getFromData(data => data?.users?.filter(u => u.attributes.name?.includes(query) || u.attributes.familyName.includes(query) || []));
    }

    getUser(id: string): Observable<CognitoUser | null> {
        return this.getFromData(data => data?.usersIndex?.[id] || null);
    }

    getUsersByCompany(company: string) {
      return this.getFromData(data => data?.users.filter(u => u.company === company) || []);
    }

    updateUser(user: CognitoUser, groups?: GroupRef[], attributes?: Partial<Pick<CognitoUserAttributes, 'inApp'>>): Observable<CognitoUser> {
        this.updateData(data => {

            if (groups) {
                this.updateUserGroups(data, user, groups);
            }

            if (attributes) {
                Object.assign(user.attributes, attributes);
            }

            return data;
        });

        return of(user);
    }


///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INTERNAL METHODS
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    private updateUserGroups(data: CognitoDataIndex, user: CognitoUser, groups: GroupRef[]): CognitoDataIndex {
        user.groups.forEach(g => {
            if (groups.includes(g)) {
                return;
            }
            // Remove user from group
            const group = data.groups[g];
            if (group) {
                group.users = group.users?.filter(u => u.id != user.id) || [];
            }
        });

        groups.forEach(g => {
            if (user.groups.includes(g)) {
                return;
            }
            // Add user to group
            const group = data.groups[g];
            if (group) {
                group.users.push(user);
            }
        })

        // Assign groups to user
        user.groups = groups;

        return data;
    }


///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INTERNAL (PROMISE) METHODS
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    private async _getGroups(): Promise<string[]> {
        const command = new ListGroupsCommand({
            ...this.baseCommand,
          });

          return this.client.send(command)
              .then(res => res.Groups.filter(g => !!g.Precedence)) // We only care about the ones with precedence (functional ones)
              .then(groups => groups.sort((a, b) => b.Precedence - a.Precedence)) // Sort them by precedence
              .then(groups => groups.map(g => g.GroupName))
    }

    private async _getAllUsersInGroup(group: string): Promise<UserType[]> {

        const commandOpts = {
            ...this.baseCommand,
            GroupName: group
        };
        return this.sendCommandGetAll(ListUsersInGroupCommand, commandOpts, res => res.Users);
    }

    private async _getAllUsers(): Promise<UserType[]> {

        const commandOpts = {
            ...this.baseCommand
        };
        return this.sendCommandGetAll(ListUsersCommand, commandOpts, res => res.Users);
    }


    private async sendCommandGetAll<O,
        CO extends ServiceOutputTypes & {NextToken?: string},
        CI extends ServiceInputTypes,
    >(cmdClass: new (...args: any) => $Command<CI, CO, any>, cmdOptions: CI, resRead: (res: CO) => O[] ): Promise<O[]> {

        let list: O[][] = [];

        let NextToken: string | undefined = undefined;

        do {
            const cmd = new cmdClass({...cmdOptions, NextToken});
            const res: CO = await this.client.send(cmd);

            NextToken = res.NextToken;
            list.push(resRead(res));

        } while(NextToken)

        return Promise.resolve(list.flat());
    }

    private async fetchAllGroupsWithUsers(): Promise<{group: string, users: UserType[]}[]> {
        const groups = await this._getGroups();

        return Promise.all(
            groups.map(g =>
                this._getAllUsersInGroup(g)
                    .then(users => ({
                        users,
                        group: g
                    }))
            )
        )
    }

    private fetchAllData(): Promise<{allUsers: UserType[], groups: {group: string, users: UserType[]}[]}> {
        return Promise.all([
            this._getAllUsers(),
            this.fetchAllGroupsWithUsers(),
        ]).then(([allUsers, groups]) => {
            return {
                allUsers,
                groups
            }
        })
    }
}
