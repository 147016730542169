import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SearchHistory, SearchHistoryList } from "src/app/shared/models/search-history.model";

@Injectable({
  providedIn: "root",
})
export class SearchHistoryService {
  pageNumber = 1;
  query: any = {};

  constructor(private http: HttpClient) { }

  getRecords(): Observable<SearchHistory[]> {
    return this.http.get<SearchHistoryList>("/rule/search/history")
      .pipe(map(searchHistoryList => searchHistoryList.items));
  }

  getRecord(id: string) {
    // falta por implementar
  }

  deleteRecord(id: string): Observable<void> {
    return this.http.delete<void>(`/rule/search/${id}`);
  }

  deleteSearchHistory(): Observable<void> {
    return this.http.delete<void>(`/rule/search`);
  }
}
