<div class="container">
  <div class="mx-3 my-6 md:mx-12">
    <div class="lg:grid lg:w-3/5">
      <div class="px-4">
        <app-breadcrum></app-breadcrum>
      </div>
    </div>
    <div class="w-full flex flex-col-reverse md:flex-col mb-8">
      <div class="
            w-full
            flex flex-col
            md:flex-row
            gap-2
            md:p-0
            justify-end
            md:relative md:top-[-2rem]
          ">
        <app-button theme="deloitte_search" (click)="toggleActiveCompanies()"
          text="{{ showActiveCompanies ? 'DEACTIVATED_COMPANIES' : 'ACTIVATED_COMPANIES' }}"></app-button>
      </div>
      <div class="w-full flex flex-col md:flex-row items-center justify-between">
        <div class="
              flex
              w-full
              md:w-auto
              pb-3
              mb-2
              md:pb-0 md:px-4
              text-black text-2xl
              font-bold
            ">
          {{ "MESSAGES.SEARCH_COMPANIES" | uppercase | translate }}
        </div>
        <div class="
          flex
          bg-white
          justify-between
          w-full
          mb-3
          ml-4
          md:mb-0 md:w-4/12
          p-4
          border
          border-t-input_search
          border-r-input_search
          search-icon
          border-l-input_search
          border-b-deloitte
          text-sm
          placeholder:text-sm
        ">
          <input (input)="search($event)" class="w-10/12 focus:outline-none appearance-none" type="text"
            placeholder="{{ 'PLACEHOLDER.SEARCH_COMPANIES' | translate }}" />
          <img src="assets/svg/search_input.svg" />
        </div>
      </div>
    </div>

    <div *ngIf="!companyData" class="flex justify-center mt-8" style="width: 100vw">
      <app-loader></app-loader>
    </div>

    <div *ngIf="companyData" class="ml-4">
      <div class="overflow-auto">
        <!-- lista de empresas y paginación -->
        <app-company *ngFor="
              let company of companyData
              | paginate : {
                  itemsPerPage: itemsPerPage,
                  currentPage: currentPage,
                  totalItems: total
                }
              let i = index
            " [company]="company" [index]="i"></app-company>
      </div>

      <div class="w-full block text-center mt-8 mb-8">
        <pagination-controls (pageChange)="changePage($event)" [directionLinks]="true" [responsive]="true"
          previousLabel="PREV" nextLabel="NEXT" [autoHide]="true"></pagination-controls>
      </div>

      <div *ngIf="companyData && companyData?.length === 0" class="flex justify-center mt-8">
        <h1 class="p-3 font-bold text-2xl md:text-3xl">
          {{ "LABEL.NO_RESULTS" | translate }}
        </h1>
      </div>
    </div>
  </div>
</div>
