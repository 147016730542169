<div class="container">
  <div class="mx-3 my-6 md:mx-12">
    <div class="lg:grid lg:w-2/5">
      <div class="px-4">
        <app-breadcrum></app-breadcrum>
      </div>
    </div>
    <div class="w-full flex flex-col-reverse md:flex-col mb-8">
      <div class="
            w-full
            grid grid-cols-3
            md:flex md:flex-row
            gap-2
            md:pt-4 md:pb-8
            justify-end
            lg:relative
          ">

        <button *ngFor="let control of groupsControl.controls" class="groupButton" [attr.enabled]="control.enabled"
          (click)="switchGroup(control)">
          <span class="label">{{ "LABEL." + control.value.key | uppercase | translate }}</span>
          <span class="badge" *ngIf="control.value.source | async; let list">{{ list?.length || 0 }}</span>
        </button>

        <div class="noGroupMsg" *ngIf="groupsControl.disabled">no group selected, showing all</div>

      </div>

      <div class="w-full flex flex-col md:flex-row items-center justify-between">
        <div class="
              flex
              w-full
              md:w-auto
              pb-3
              md:pb-0 md:px-4
              text-black text-2xl
              font-bold
            ">
          {{ "MESSAGES.SEARCH_USERS" | uppercase | translate }}
        </div>
        <div class="
              flex
              bg-white
              justify-between
              w-full
              mb-3
              md:mb-0 md:w-4/12
              p-4
              border
              border-t-input_search
              border-r-input_search
              search-icon
              border-l-input_search
              border-b-deloitte
              text-sm
              placeholder:text-sm
            ">
          <input [formControl]="searchControl" class="w-10/12 focus:outline-none appearance-none" type="text"
            placeholder="{{ 'PLACEHOLDER.SEARCH_USERS' | translate }}" /><img src="assets/svg/search_input.svg" />
        </div>
      </div>
    </div>
    <div class="overflow-auto">
      <app-user *ngFor="
        let user of usersList$ | async
        | paginate: { itemsPerPage: pageSize, currentPage: page };
        let i = index; trackBy trackUser
            " [user]="user" [index]="i" (click)="showUser(user)"
      ></app-user>
    </div>

    <div class="w-full block text-center mt-8">
      <pagination-controls (pageChange)="page = $event" [directionLinks]="true" [autoHide]="true" [responsive]="true"
        previousLabel="PREV" nextLabel="NEXT"></pagination-controls>
    </div>
    <div *ngIf="(usersList$ | async)?.length === 0" class="flex justify-center mt-8">
      <h1 class="p-3 font-bold text-2xl md:text-3xl">
        {{ "LABEL.NO_RESULTS" | translate }}
      </h1>
    </div>
  </div>
</div>
