import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FavouriteSearch, FavouriteSearchInput, FavouriteSearchList } from 'src/app/shared/models/search-favourite.model';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {

  constructor(private http: HttpClient) { }

  getFavourites(): Observable<FavouriteSearch[]> {
    return this.http.get<FavouriteSearchList>(`/rule/search/favourite`)
      .pipe(map(searchFavouriteList => searchFavouriteList.items));
  }

  deleteFavourite(id: string): Observable<void> {
    return this.http.delete<void>(`/rule/search/favourite/${id}`);
  }

  createFavourite(body: FavouriteSearchInput): Observable<void> {
    return this.http.post<void>(`/rule/search/favourite`, body);
  }

  deleteFavourites(): Observable<void> {
    return this.http.delete<void>(`/rule/search/favourite`);
  }
}
