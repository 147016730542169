import { saveAs } from 'file-saver';
import JSZip from 'jszip';

function download(urls: string[]): Promise<Response[]> {
  return Promise.all(
    urls.map(async url => {
      return await fetch(url);
    })
  );
}

function exportZip(res: Response[]) {
  const zip = JSZip();

  res.forEach(doc => {
    const aux = doc.url.split("?", 2)[0];
    const filename = aux.substring(aux.lastIndexOf("/"));
    zip.file(filename, doc.blob());
  });

  zip.generateAsync({ type: 'blob' }).then(zipFile => {
    const currentDate = new Date().getTime();
    const fileName = `export-${currentDate}.zip`;
    return saveAs(zipFile, fileName);
  });
}

export function downloadAndZip(urls: string[]) {
  return download(urls).then(res => exportZip(res));
}
