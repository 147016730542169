import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NotificationService } from "../../notification/notification.service";
import { Router } from "@angular/router";

export const ERROR_HANDLIND = new HttpContextToken(() => true);
export const ERROR_HANDLIND_IGNORE_STATUS = new HttpContextToken(() => []);

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private notif: NotificationService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    if (!request?.context.get(ERROR_HANDLIND)) {
      return next.handle(request)
    }

    return next.handle(request).pipe(
      catchError((error) => {
        const status = error?.status;

        const ignoreStatus = request?.context.get(ERROR_HANDLIND_IGNORE_STATUS) || [];
        if (ignoreStatus.includes(status)) {
          return throwError(error);
        }

        let errorInfo = {
          errorMessage: "",
          errorStatus: null
        };
        switch(status) {
          case 0:{}
          case 503:{
            errorInfo.errorMessage="503 error"
          }
          case 502:{
            errorInfo.errorMessage = "Bad response, pls try later"
            errorInfo.errorStatus = 502
            break;
          }
          case 404:{
            errorInfo.errorMessage = "Not Found"
            errorInfo.errorStatus = 404
            break;
          }
          case 500:{
            errorInfo.errorMessage = "Error"
            errorInfo.errorStatus = 500
            break;
          }
          case 403: {
            errorInfo.errorMessage = "Forbidden"
            errorInfo.errorStatus = 403
            break;
          }
          default: {
            errorInfo.errorMessage=error?.error?.errorMessage || "Error"
          }
        }
        this.router.navigate(['error', errorInfo.errorStatus].filter(v=>!!v),{
          state: { errorInfo }
        })
        return throwError(error);
      })
    );
  }
}
