import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private _translateService: TranslateService) {}

  transform(value: any, pattern = 'mediumDate'): unknown {
    let d = new Date(value);
    if (d instanceof Date && !isNaN(d.getTime())) {
      const datePipe: DatePipe = new DatePipe(this._translateService.currentLang);
      return datePipe.transform(d, pattern);
    }
    return '';
  }

}
