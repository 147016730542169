import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AWSService } from 'src/aws/aws.service';
import { SearchModelInput } from 'src/app/shared/models/search.model';
import { LoggedUserInfo } from 'src/app/shared/models/user.model';

export interface SessionInfo {
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  pageNumber = 1;
  query: any = {};

  constructor(
    private http: HttpClient,
    private awsService: AWSService,
  ) { }

  getSessionInfo(): Observable<SessionInfo | null> {
    return this.awsService.loginStatus.pipe(
      map(ok => {
        if (ok) {
          return { username: '' }
        } else {
          return null;
        }
      })
    )
  }

  getUserDetails(): Observable<LoggedUserInfo> {
    return this.awsService.getUser()
      .pipe(map(res => res.attributes));
  }

  searchUsers(query: SearchModelInput<unknown>): Observable<any> {
    return this.http.post("/user/search", query);
  }

  getUser(idUser: string): Observable<any> {
    return this.http.get(`/admin/users/${idUser}`);
  }

  deleteUser(idUser: number): Observable<any> {
    return this.http.delete(`/user/${idUser}`);
  }

  addUser(body: any): Observable<any> {
    return this.http.post(`/user`, body);
  }

  updateUser(idUser: string, body: any): Observable<any> {
    return this.http.put(`/admin/users/${idUser}`, body);
  }
}
