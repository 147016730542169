import { HttpContext } from "@angular/common/http"
import { ERROR_HANDLIND, ERROR_HANDLIND_IGNORE_STATUS } from "../services/interceptors/error-handling-interceptor/error-handling.interceptor"


export const noErrorHandling = () => {
    return {
        context: new HttpContext().set(ERROR_HANDLIND, false)
    }
}

export const noErrorHandlingForStatus = (status: number[]) => {
    return {
        context: new HttpContext().set(ERROR_HANDLIND_IGNORE_STATUS, status)
    }
}