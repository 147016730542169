export type MasterDataPattern = {
  id: string;
  i18n: { [key: string]: { name: string } };
  active: boolean;
};

export enum MasterDataTypes {
  TYPE = "type",
  PHASE = "phase",
  FIELD = "field",
  AREA = "area",
  ISSUER = "issuer",
  GEO_SCOPE = "geo_scope",
  INITIATIVES = "initiative",
  CLUSTER = "cluster",
}

export interface ClusterRelations {
  issuer: string[],
  geo_scope: string[]
}

interface PhaseRelations {
  type: string[]
}

interface AreaRelations {
  field: string[]
}

export interface MasterDataRelations {
  phase: MasterDataRelation<PhaseRelations>,
  cluster: MasterDataRelation<ClusterRelations>,
  area: MasterDataRelation<AreaRelations>
}

export interface MasterDataFile {
  data: MasterDataEntries,
  relations: MasterDataRelations
}

interface MasterDataFileExtraReference {
  "$ref": MasterDataFileReference
}

interface MasterDataFileReference {
  generated: number,
  hash: string,
  size: number,
  generator: string
}

export interface MasterDataEntries {
  type: MasterData[],
  phase: MasterData[],
  field: MasterData[],
  area: MasterData[],
  issuer: MasterData[],
  geo_scope: MasterData[],
  initiative: MasterData[],
  cluster: MasterData[],
}

export interface MasterData {
  id: string,
  i18n: {
    [key: string]: MasterDatai18n
  },
  active?: boolean,
  deleted?: boolean
}

interface MasterDatai18n {
  name: string;
  description?: string;
}

export interface MasterDataRelation<T> {
  [key: string]: T
}

export interface MasterDataTypesOut {
  types: (keyof MasterDataEntries)[]
}

export interface MasterDataValuesList {
  values: MasterDataValuesOut[]
}

export interface MasterDataValuesOut extends MasterData {
  usages: number,
  active: boolean
}

export interface MasterDataOptions {
  mdTypes: (keyof MasterDataEntries)[],
  mdElements: MasterDataValuesOut[],
  mdRelations: MasterDataRelation<any>
}
