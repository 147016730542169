import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MasterData, MasterDataEntries, MasterDataFile, MasterDataRelation } from '../../models/master-data.model';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  private masterData: MasterDataFile = null;

  constructor(private http: HttpClient, private translateService: TranslateService) { }

  setMasterData(data: MasterDataFile): void {
    this.masterData = data;
  }

  loadMasterData(): Observable<MasterDataFile> {
    if (!environment.masterDataUrl) return;

    return this.http.get<MasterDataFile>(environment.masterDataUrl)
      .pipe(tap(res => {
        this.masterData = res;
      }));
  }

  isLoaded(): boolean {
    return this.masterData !== null;
  }

  // returns masterdata values given a masterdata key
  getData(key: keyof MasterDataEntries): MasterData[] {
    return this.masterData.data[key];
  }

  getFilteredData(key: keyof MasterDataEntries): MasterData[] {
    return this.masterData.data[key].filter(elem => !elem.deleted);
  }

  getActiveData(key: keyof MasterDataEntries): MasterData[] {
    return this.masterData.data[key].filter(elem => !elem.deleted && elem.active);
  }

  // returns masterdata relations given a masterdata key and id
  getAllRelations<T>(parent: keyof MasterDataEntries): MasterDataRelation<T> {
    return this.masterData.relations[parent];
  }

  /* returns masterdata realtion given a masterdata key, id and child key */
  getRelations(parent: keyof MasterDataEntries, parentKey: string, child: keyof MasterDataEntries): string[] {
    if (!parentKey) {
      return [];
    }
    const parentElement = this.masterData.relations[parent];

    return parentElement[parentKey as keyof typeof parentElement][child];
  }

  getFilteredRelations(parent: keyof MasterDataEntries, parentKey: string, child: keyof MasterDataEntries): string[] {
    if (!parentKey) {
      return [];
    }
    const parentElement = this.masterData.relations[parent];
    const relations = parentElement[parentKey as keyof typeof parentElement][child].flat();
    const data = this.getFilteredData(child).flat();

    return data.filter(elem => relations.includes(elem.id) && !elem.deleted)
      .map(elem => elem.id);
  }

  getActiveRelations(parent: keyof MasterDataEntries, parentKey: string, child: keyof MasterDataEntries): string[] {
    if (!parentKey) {
      return [];
    }
    const parentElement = this.masterData.relations[parent];
    const relations = parentElement[parentKey as keyof typeof parentElement][child].flat();
    const data = this.getFilteredData(child).flat();

    return data.filter(elem => relations.includes(elem.id) && !elem.deleted && elem.active )
      .map(elem => elem.id);
  }

  searchParent(child: keyof MasterDataEntries, childKey: string, parent: keyof MasterDataEntries): string {
    const relation = this.getAllRelations(parent);

    const parentEntry = Object.entries<any>(relation).find(([parentKey, relations]) => {
      const childs: string[] = relations[child] || [];
      return childs.includes(childKey);
    });

    return parentEntry?.[0] || null;
  }
}
