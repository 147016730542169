import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MasterDataPattern, MasterDataTypes } from '../../models/master-data.model';
import { MasterDataService } from '../../services/master-data/master-data.service';

@Pipe({
  name: 'getMasterData'
})
export class GetMasterDataPipe implements PipeTransform {

  constructor(private translateService: TranslateService, private masterDataService: MasterDataService) { }

  transform(key: MasterDataTypes, ...args: unknown[]): Array<any> {
    if (!this.masterDataService.isLoaded()) {
      return null;
    }
    return this.masterDataService.getData(key)
      .map((e: MasterDataPattern) => ({
        id: e.id,
        name: e.i18n[this.translateService.currentLang].name,
        active: e.active
      }));
  }

  transformFiltered(key: MasterDataTypes, ...args: unknown[]): Array<any> {
    if (!this.masterDataService.isLoaded()) {
      return null;
    }
    return this.masterDataService.getFilteredData(key)
      .map((e: MasterDataPattern) => ({
        id: e.id,
        name: e.i18n[this.translateService.currentLang].name,
        active: e.active
      }));
  }

  transformActive(key: MasterDataTypes, ...args: unknown[]): Array<any> {
    if (!this.masterDataService.isLoaded()) {
      return null;
    }
    return this.masterDataService.getActiveData(key)
      .map((e: MasterDataPattern) => ({
        id: e.id,
        name: e.i18n[this.translateService.currentLang].name,
        active: e.active
      }));
  }
}
