import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { RulesService } from "src/app/shared/services/api/rules/rules.service";
import { UsersService } from 'src/app/shared/services/admin/users/users.service';
import { ModalComponent } from "../../components/modal/modal.component";
import { PatternTemplate } from "../../interfaces/FieldTemplate";
import { TranslateService } from "@ngx-translate/core";
import { FavouritesService } from "src/app/shared/services/api/favourites/favourites.service";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ExportExcelService } from "src/app/shared/services/api/export/excel/export-excel.service";
import { ExportPdfService } from "src/app/shared/services/api/export/pdf/export-pdf.service";
import { S3Service } from "src/aws/services/s3Service/s3.service";
import { Rule } from "postcss";
import { SearchModelOutput } from "src/app/shared/models/search.model";
import { RuleFilter } from "src/app/shared/models/rules.model";
import { ExportResult, Roles, VALIDATION_STATUS_ENUM } from "src/app/shared/models/other-data.model";
import { tapOnError } from "src/app/shared/utils/rxjsUtils";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@UntilDestroy()
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent {
  @ViewChild("modal") modal: ModalComponent | undefined;
  userId: string;
  userRole: any;
  formFav: UntypedFormGroup;
  searchForm: UntypedFormGroup; // formulario
  // Boolean para expandir formulario de filtros
  expanded = false;
  // Boolean para expandir menu lateral
  expandAside = false;
  // Estado del formulario
  stateForm: PatternTemplate;
  lastSearch: PatternTemplate = null; // los filtros de la busqueda actual
  // Validacion de la modal de favoritos
  isValid = false;
  rulesData: SearchModelOutput<Rule>;
  totalPage = 0;
  itemsPerPage = 6;
  currentPage = 1;
  total = 0;
  emptyForm = true;
  // search
  appliedFilter = false;
  searching = false;
  // reset for checkbox and multiselect
  @Output() resetValues = new EventEmitter();
  // text language
  actualLang: string;

  constructor(
    private router: Router,
    private exportExcelService: ExportExcelService,
    private exportPdfService: ExportPdfService,
    private rulesService: RulesService,
    private usersService: UsersService,
    private t: TranslateService,
    private favourites: FavouritesService,
    private notif: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private s3Service: S3Service,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params: ParamMap) => {
      const userName = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.aws.Auth.userPoolWebClientId}.LastAuthUser`);
      this.userId = JSON.parse(localStorage.getItem(`CognitoIdentityServiceProvider.${environment.aws.Auth.userPoolWebClientId}.${userName}.userData`)).UserAttributes.find(a => a.Name == "sub")?.Value;
      this.usersService.getUserDetail(this.userId).pipe(untilDestroyed(this)).subscribe((res) => {
        this.userRole = Roles.find(role => res.user.groups.includes(role.name.replace('ROLES.','').toLowerCase()));
        let query = {};
        query = this.checkIfHasAproveRole(query);
        query = this.checkIfLastSearch(query);
        this.requestData(query, this.currentPage);
      });
    });
    
    this.formFav = this.formBuilder.group({
      name: [, Validators.required]
    })

    this.stateForm = {};
    this.initLeftPanel();
  }

  requestData(query: RuleFilter, page: number): void {
    localStorage.setItem('lastSearchQuery', JSON.stringify(query));
    this.rulesService
      .searchAndSaveRules({
        query,
        size: this.itemsPerPage,
        page,
      })
      .pipe(untilDestroyed(this))
      .subscribe((list: any) => {
        this.rulesData = list;
        this.totalPage = list.totalPage;
        this.total = list.total;
        this.lastSearch = this.stateForm;
        this.searching = false;
      })
  }

  checkIfHasAproveRole(query: RuleFilter) {
    if (this.userRole?.id === 3 && Object.keys(query).length == 0) {
      this.searching = true;
      this.appliedFilter = true;

      const mappedJson: any = {};
      mappedJson.validated = false;
      return mappedJson;
    }
    return query;
  }

  checkIfLastSearch(query: RuleFilter) {
    let _lastSearchQuery = JSON.parse(localStorage.getItem('lastSearchQuery'));
    if (_lastSearchQuery && Object.values(_lastSearchQuery).flat().length > 0) {
      query = _lastSearchQuery;
      this.searching = true;
      this.appliedFilter = true;
    }
    return query;
  }

  formChanged(event: UntypedFormGroup): void {
    let _lastSearchQuery = JSON.parse(localStorage.getItem('lastSearchQuery'));

    this.isValid = event.status === "VALID";
    this.searchForm = event;

    const fields = event.value;
    const publication_date = [];
    const start_date: string | '' = fields.publication_date.start_date;
    const end_date: string | '' = fields.publication_date.end_date;
    if (start_date !== "" || end_date !== "") {
      publication_date.push(start_date);
      publication_date.push(end_date);
    }
    fields.publication_date = publication_date;
    fields.initiatives = this.refreshIfIsObject(fields.initiatives);
    fields.issuers = this.refreshIfIsObject(fields.issuers);
    fields.areas = this.refreshIfIsObject(fields.areas);
    fields.fields = this.refreshIfIsObject(fields.fields);

    this.stateForm = fields;
    if (this.isValid) {
      this.searching = !Object.values(fields).filter(Boolean).every((elem: string | any[]) => elem.length === 0);
    }
    if (_lastSearchQuery && (_lastSearchQuery?.validated === true || _lastSearchQuery?.validated === false)) {
      this.searching = true;
    }
  }

  private refreshIfIsObject(fieldsData: any) {
    let data = []
    fieldsData.forEach(element => {
      if (typeof element == 'object') {
        data.push(element.id);
      } else {
        data.push(element);
      }
    });
    return data;
  }

  exportpdf(): void {
    this.exportPdfService
      .exportPdfSearch(this.mapJson())
      .pipe(
        untilDestroyed(this),
        tapOnError(err => {
          console.log("Error exporting result to pdf", err);
          this.notif.error("NOTIF.GENERAL_ERROR");
        }),
        tap(url => {
          this.s3Service.downloadFile(url);
        })
      )
      .subscribe();
  }

  exportexcel(): void {
    this.exportExcelService
      .exportExcelSearch(this.mapJson())
      .pipe(
        untilDestroyed(this),
        tapOnError(err => {
          console.log("Error exporting result to excel", err);
          this.notif.error("NOTIF.GENERAL_ERROR");
        }),
        tap((res: ExportResult) => {
          const docs: string[] = res.documents.map(elem => elem.link);
          docs.push(res.url);
          this.s3Service.downloadDocuments(docs);
        })
      )
      .subscribe();
  }

  currentLang() {
    return this.t.currentLang
  }

  sendAlert() {
    this.stateForm = this.lastSearch;
    this.router.navigate(["alerts", "register-alert"], {
      state: {
        lang: this.t.currentLang,
        withExport: true,
        filters: this.mapJson()
      }
    });
  }

  search(): void {
    this.stateForm = this.searchForm.value;
    this.rulesData = null;
    if (
      Object.values(this.stateForm)
        .filter(Boolean)
        .every((elem: string | any[]) => elem.length === 0)
    ) {
      this.appliedFilter = false;
      this.searching = false;
    } else {
      this.appliedFilter = true;
    }
    this.requestData(this.mapJson(), 1);
    this.currentPage = 1;
    this.initLeftPanel();
  }

  resetFilter(): void {
    localStorage.removeItem("lastSearchQuery");
    this.rulesData = null;
    this.stateForm = {};
    this.resetValues.emit();
    this.requestData({}, 1);
    this.currentPage = 1;
    this.appliedFilter = false;
    this.searching = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  showRule(rule: any): void {
    // Nos dirigimos a la vista show-rule del texto clickeado
    this.router.navigate(["rules", "show-rule", rule?.id]);
  }

  addToFavourites(): void {
    // Abrimos la modal para registrar un favorito
    this.modal?.open();
  }

  changePage(event: any) {
    this.rulesData = null;
    this.stateForm = this.lastSearch;
    this.requestData(this.mapJson(), event);
    this.currentPage = event;
  }

  confirmAddToFavourites(): void {
    const current_datetime = new Date().getTime();
    const body = { filters: this.mapJson(), title: this.formFav.get('name').value, datetime: current_datetime }

    this.favourites.createFavourite(body).subscribe(() => {
      this.closeModal();
      this.notif.success("NOTIF.SAVE_FAV_SEARCH")
      this.formFav.reset()
    })
  }

  closeModal(): void {
    // Cerramos modal
    this.modal?.close();
  }

  mapJson(): any {
    const mappedJson: any = {};
    // create body with filled values
    if (this.stateForm.title && this.stateForm.title !== "") {
      mappedJson.title = this.stateForm.title;
    }
    this.actualLang = this.stateForm.lang;
    if (this.stateForm.lang && this.stateForm.lang.length > 0) {
      mappedJson.lang = this.stateForm.lang;
    }
    if (this.stateForm.validated) {
      mappedJson.validated = this.stateForm.validated === VALIDATION_STATUS_ENUM.VALIDATED;
    }
    if (this.stateForm.areas && this.stateForm.areas.length > 0) {
      mappedJson.areas = this.stateForm.areas;
    }
    if (this.stateForm.fields && this.stateForm.fields.length > 0) {
      mappedJson.fields = this.stateForm.fields;
    }
    if (this.stateForm.initiatives && this.stateForm.initiatives.length > 0) {
      mappedJson.initiatives = this.stateForm.initiatives;
    }
    if (this.stateForm.geo_scopes && this.stateForm.geo_scopes !== "") {
      mappedJson.geo_scopes = [this.stateForm.geo_scopes];
    }
    if (this.stateForm.issuers && this.stateForm.issuers.length > 0) {
      mappedJson.issuers = this.stateForm.issuers;
    }
    if (this.stateForm.phases && this.stateForm.phases.length > 0) {
      mappedJson.phases = [this.stateForm.phases];
    }
    if (this.stateForm.processing_status) {
      mappedJson.processing_status = Number(this.stateForm.processing_status);
    }
    if (this.stateForm.publication_date && this.stateForm.publication_date.length > 0) {
      mappedJson.publication_date = this.stateForm.publication_date;
    }
    return mappedJson;
  }

  private initLeftPanel() {
    this.expandAside = screen.availWidth >= 1024;
  }
}
