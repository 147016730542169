<div class="container relative md:pb-0">
  <!-- Cabecera -->
  <div class="flex">
    <div *ngIf="!appliedFilter" class="w-full m-0 flex flex-col-reverse md:flex-row md:h-full md:bg-white">
      <div class="w-full flex md:w-7/12 xl:w-6/12">
        <div class="pl-4 py-2">
          <h1 class="font-bold text-2xl">
            {{ "MESSAGES.WELCOME" | translate }}
          </h1>
          <div class="w-11/12 text-sm">
            {{ "MESSAGES.DESCRIPTION" | translate }}
          </div>
        </div>
      </div>
      <div class="w-full md:w-5/12 xl:w-6/12">
        <img class="w-full h-28" src="../../../assets/imgs/home.png" alt="imagen portada" />
      </div>
    </div>
  </div>

  <div *ngIf="!rulesData" class="w-full flex justify-center mt-8" style="width: 100vw">
    <app-loader></app-loader>
  </div>

  <div class="container flex-row flex items-stretch">
    <div [ngClass]="expandAside ? '' : 'hidden'"
      class="w-full md:w-3/12 lg:w-2/12 absolute md:relative block md:flex-auto top-0 bottom-0 left-0 bg-white z-20 shadow-lg">
      <!-- Formulario de filtros -->
      <div *ngIf="rulesData" class="px-3 overflow-scroll scrollbar relative min-h-screen bg-white pb-6 transition-all">
        <app-button class="absolute top-0 right-0 lg:hidden" theme="toggle" (click)="expandAside = !expandAside">
          <img src="assets/svg/Icon-Close@2x.svg" />
        </app-button>

        <p class="title text-2xl mt-2 text-black text-bold">
          {{ "SIDEFILTER.SEARCH" | translate }}
        </p>

        <div class="flex flex-wrap md:flex-nowrap mt-5 md:gap-1" style="justify-content: space-evenly">
          <app-button 
            theme="home_filter" 
            (click)="(!searching && !appliedFilter) ? '' : search()" 
            text="APPLY_FILTERS" 
            [disabled]="!searching"
          >
          </app-button>
          <app-button 
            theme="home_filter_strech" 
            (click)="(!searching && !appliedFilter) ? '' : resetFilter()" 
            text="RESET_FILTERS" 
            [disabled]="(!searching && !appliedFilter)"
          >
          </app-button>
        </div>

        <div class="home-search-filters">
          <div class="w-full">
            <app-home-search [expanded]="expanded" (formChanged)="formChanged($event)" [reset]="resetValues">
            </app-home-search>
          </div>
        </div>

        <div class="sticky-options">
          <div class="mt-5">
            <app-button theme="deloitteunderline" text="{{
                  !expanded ? 'VIEW_MORE_FILTERS' : 'VIEW_LESS_FILTERS'
                }}" (click)="expanded = !expanded"></app-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Icono Lupa -->
    <div *ngIf="rulesData" class="block z-20 lg:hidden md:flex-auto text-center pt-8 transition-all">
      <app-button class="absolute top-3 right-3" theme="icon" (click)="expandAside = !expandAside"
        [disabled]="!rulesData">
        <img src="assets/imgs/search.png" />
      </app-button>
    </div>

    <!-- Listado de normas y paginacion -->
    <div *ngIf="rulesData" [ngClass]="
          expandAside
            ? 'md:w-9/12 lg:w-10/12 border-l-0'
            : 'md:w-11/12'
        " class="w-full md:block md:flex-auto px-4">
      <!-- Boton Favoritos y Exportación -->
      <div *ngIf="appliedFilter"
        class="relative w-full py-4 px-8 bg-white md:mb-4 md:flex md:flex-wrap justify-between mt-7 z-10 md:z-auto">
        <div class="w-full md:flex md:flex-wrap md:justify-center gap-4 md:items-center items-stretch">
          <div class="hidden md:flex" matTooltip="{{'TOOLTIP.EXPORT_LIMIT' | translate}}">
            <app-button (click)="exportpdf()" text="EXPORTSEARCHPDF" theme="export_home">
              <img class="inline-block pr-2" src="assets/imgs/exportar-pdf.png" />
            </app-button>
            <app-button
              class="hidden md:block"
              (click)="exportexcel()"
              text="EXPORTSEARCHEXCEL"
              theme="export_home"
              [tooltip]="EXPORT_LIMIT"
            >
              <img class="inline-block pr-2 mb-1" src="assets/imgs/exportar-excel.png" />
            </app-button>
          </div>

          <div *ngIf="appliedFilter" class="md:flex gap-2 md:justify-end">
            <app-button class="hidden md:block" theme="main_block" text="SEND_ALERT" (click)="sendAlert()"></app-button>
            <app-button theme="main_block" text="ADD_FAVOURITE" (click)="addToFavourites()"></app-button>
          </div>
        </div>
      </div>
      <div class="title mt-4">
        {{
        "LABEL.TOTAL_RECORDS" | translate: { totalRecords: rulesData.total }
        }}
      </div>

      <div class="w-full">
        <!-- Lista normas -->
        <div class="overflow-auto mt-2">
          <app-rule *ngFor="
              let rule of rulesData?.items
                | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: currentPage,
                      totalItems: total
                    }
            " [actualLang]="actualLang" [rule]="rule" [index]="rule?.id"></app-rule>
        </div>
        <div class="w-full block text-center mt-8 mb-8">
          <pagination-controls (pageChange)="changePage($event)" [directionLinks]="true" [responsive]="true"
            previousLabel="PREV" nextLabel="NEXT" [autoHide]="true"></pagination-controls>
        </div>

        <div *ngIf="rulesData && rulesData?.items.length === 0" class="flex justify-center mt-8">
          <h1 class="p-3 font-bold text-2xl md:text-3xl">
            {{ "LABEL.NO_RESULTS" | translate }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal favorito -->
<app-modal [formGroup]="formFav" #modal>
  <div (click)="closeModal()" class="absolute top-3 right-3">
    <img class="scale-75" src="assets/svg/Icon-Close@2x.svg" />
  </div>
  <div class="text-2xl mb-6 block font-bold text-left">
    <span>{{ "SECTION.ADD_FAVOURITE" | translate }}</span>
  </div>
  <div style="text-align: center">
    <input type="text" class="input-form mb-1" formControlName="name" />

    <app-button theme="main" (click)="confirmAddToFavourites()" [disabled]="formFav.get('name').hasError('required')"
      text="SAVE"></app-button>
  </div>
</app-modal>
