<form [formGroup]="searchForm" (change)="readForm($event)">
  <div>
    <div>
      <label for="title">
        {{ "label.title" | uppercase | translate }}
      </label>
      <input
        type="text"
        id="title"
        name="title"
        value=""
        formControlName="title"
      />
    </div>
  </div>

  <div>
    <div>
      <label for="lang">
        {{ "label.language" | uppercase | translate }}
      </label>

      <div>
        <select name="lang" id="lang" formControlName="lang">
          <option value="{{ null }}">{{ "LABEL.SHOW_ALL" | translate }}</option>
          <option *ngFor="let option of options.lang" value="{{ option }}">
            {{ "LANGUAGE." + option | uppercase | translate }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div *ngIf="showValidatedOptions">
    <div id="validation_status">
      <label for="validated">
        {{ "label.validation_status" | uppercase | translate }}
      </label>

      <input type="radio" value="{{ null }}" formControlName="validated" name="validated" />
      <span>{{ "LABEL.SHOW_ALL" | uppercase | translate }}</span>

      <div *ngFor="let status of options.validated; index as i">
        <input [id]="'status' + i" [value]="status" name="validated" type="radio" formControlName="validated"/>
        <span>{{ status | uppercase | translate }}</span>
      </div>
    </div>
  </div>

  <div class="masterdata-group">
    <div>
      <label for="areas">
        {{ "label.areas" | uppercase | translate }}
      </label>

      <app-multiselect
        [items]="options.areas"
        (valueChanged)="setFieldValue($event, 'areas')"
        [customInputCSS]="
          'w-full border rounded focus:outline-none appearance-none py-1 px-2 border-deloitte_gray9 text-sm'
        "
        [value]="searchForm.get('areas').value"
      >
      </app-multiselect>
    </div>

    <div>
      <label for="fields">
        {{ "LABEL.fields" | uppercase | translate }}
      </label>

      <app-multiselect
        [items]="options.fields"
        (valueChanged)="setFieldValue($event, 'fields')"
        [customInputCSS]="
          'w-full border rounded focus:outline-none appearance-none py-1 px-2 border-deloitte_gray9 text-sm'
        "
        [value]="searchForm.get('fields').value"
      >
      </app-multiselect>
    </div>

    <div>
      <label for="initiatives">
        {{ "LABEL.initiatives" | uppercase | translate }}
      </label>

      <app-multiselect
        [items]="options.initiatives"
        (valueChanged)="setFieldValue($event, 'initiatives')"
        [customInputCSS]="
          'w-full border rounded focus:outline-none appearance-none py-1 px-2 border-deloitte_gray9 text-sm'
        "
        [value]="searchForm.get('initiatives').value"
      >
      </app-multiselect>
    </div>
  </div>

  <div ngClass="{{ expanded ? 'block' : 'hidden' }}">
    <div formGroupName="publication_date">
      <div>
        <label for="end_date">
          {{ "label.publication_date" | uppercase | translate }}
        </label>
      </div>

      <div id="dates">
        <div>
          <app-datepicker
            id="start_date"
            name="start_date"
            [value]="searchForm.get('publication_date.start_date').value"
            (change)="
              setFieldValueByGroup($event, 'publication_date', 'start_date')
            "
            [reset]="resetValues"
            placeholder="from"
            [customInputCSS]="
              'w-full border rounded focus:outline-none appearance-none py-1 px-2 border-deloitte_gray9 text-sm'
            "
          ></app-datepicker>
        </div>

        <div>
          <app-datepicker
            id="end_date"
            name="end_date"
            [value]="searchForm.get('publication_date.end_date').value"
            [reset]="resetValues"
            (change)="
              setFieldValueByGroup($event, 'publication_date', 'end_date')
            "
            placeholder="to"
            [customInputCSS]="
              'w-full border rounded focus:outline-none appearance-none py-1 px-2 border-deloitte_gray9 text-sm'
            "
          ></app-datepicker>
        </div>
      </div>
    </div>
  </div>

  <div class="masterdata-group" ngClass="{{ expanded ? 'block' : 'hidden' }}">
    <div>
      <label for="geo_scopes">
        {{ "label.geoscope" | uppercase | translate }}
      </label>

      <div>
        <select name="geo_scopes" id="geo_scopes" formControlName="geo_scopes" (change)="changeGeoScope()">
          <option value="{{ null }}">{{ "LABEL.SHOW_ALL" | translate }}</option>
          <option
            *ngFor="let option of options.clusters"
            value="{{ option.id }}"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <label for="issuers">
        {{ "LABEL.ISSUING_BODY" | uppercase | translate }}
      </label>

      <app-multiselect
        [items]="options.issuers"
        (valueChanged)="setFieldValue($event, 'issuers')"
        [customInputCSS]="
          'w-full border rounded focus:outline-none appearance-none py-1 px-2 border-deloitte_gray9 text-sm'
        "
        [value]="searchForm.get('issuers').value"
      >
      </app-multiselect>
    </div>
  </div>

  <div ngClass="{{ expanded ? 'block' : 'hidden' }}">
    <div>
      <label for="phases">
        {{ "label.TYPE_RULE" | uppercase | translate }}
      </label>

      <div>
        <select name="phases" id="phases" formControlName="phases">
          <option value="{{ null }}">{{ "LABEL.SHOW_ALL" | translate }}</option>
          <option *ngFor="let option of options.phases" value="{{ option.id }}">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div ngClass="{{ expanded ? 'block' : 'hidden' }}">
    <div id="processing_status">
      <label for="processing_status">
        {{ "label.processing_status" | uppercase | translate }}
      </label>

      <input type="radio" value="{{ null }}" formControlName="processing_status" name="processing_status" />
      <span>{{ "LABEL.SHOW_ALL" | uppercase | translate }}</span>

      <div *ngFor="let ps of options.processing_status; index as i">
        <input
          id="{{ 'ps' + i }}"
          [value]="ps['id']"
          type="radio"
          formControlName="processing_status"
          name="processing_status"
        />
        <span>{{ ps["name"] | uppercase | translate }}</span>
      </div>
    </div>
  </div>
</form>
