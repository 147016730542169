import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataTypes } from '../../models/master-data.model';
import { GetMasterDataPipe } from '../get-masterdata/get-masterdata.pipe';

@Pipe({
  name: 'getMasterdataElementName'
})
export class GetMasterdataElementNamePipe implements PipeTransform {

  constructor(
    private getMasterData: GetMasterDataPipe,
  ) { }

  transform(key1: MasterDataTypes, key2: string): any {
    const masterdata = this.getMasterData.transform(key1);
    const res = masterdata.find((elem) => elem.id === key2);
    return res?.name;
  }

}
