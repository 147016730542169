<div *ngIf="!data" class="flex justify-center mt-6">
  <app-loader></app-loader>
</div>

<!-- Edit Form -->

<div class="container">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div class="col-start-0 md:col-start-2 col-span-4 md:col-span-3 px-4 md:px-1 text-black text-2xl font-bold"
        style="margin-top: 20px">
        {{ "MESSAGES.EDIT_RULE" | uppercase | translate }}
      </div>
    </div>

    <app-rule-form #form></app-rule-form>

  </div>
</div>
<!-- Options -->
<div class="container mb-8">
  <div class="mx-0 xl:mx-12">
    <div class="md:grid md:grid-cols-4 gap-4 mb-8 inline-block w-full">
      <div class="md:col-start-2 md:col-span-4 px-4 md:px-1 gap-2 flex flex-col md:flex-row md:mb-8">
        <app-button theme="main" (click)="back()" text="RETURN"></app-button>
        <app-button theme="main" (click)="resetForm()" text="RESET"></app-button>
        <app-button theme="main" [disabled]="form.form.invalid" (click)="submit()" text="SAVE"></app-button>
        <div class="self-center">
          <app-button *ngIf="actions?.DELETE" theme="delete_red" text="DELETE" (click)="confirmDelete()"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>


<app-modal #deleteModal class="h-auto">
  <div class="px-12">
    <div class="text-2xl font-bold text-center text-black mb-4">
      {{ "MESSAGES.CONFIRM_DELETE_RULE" | uppercase | translate }}
    </div>
    <div class="text-sm font-bold text-center justify-start text-deloitte_black mb-14">
      {{ "MESSAGES.PERM_DELETE" | uppercase | translate }}
    </div>
    <div class="grid grid-cols-2 gap-2 md:gap-6">
      <app-button theme="main_block" (click)="deleteRule()" text="YES"></app-button>
      <app-button theme="main_block" (click)="closeDeleteModal()" text="NO"></app-button>
    </div>
  </div>
</app-modal>

<app-modal #validationModal>
  <div (click)="closeValidationModal()" class="absolute top-3 right-3 md:right-6">
    <img class="scale-75 cursor-pointer" src="assets/svg/Icon-Close@2x.svg" />
  </div>
  <div class="text-2xl mx-4 mb-6 block font-bold text-center">
    {{ "MESSAGES.CONFIRM_CREATE_RULE" | translate }}
  </div>
  <div class="space-around mt-5 md:mt-8">
    <app-button (click)="updateAndValidateRule()" text="PUBLISH&SAVE"></app-button>
    <app-button (click)="updateRule()" text="SAVE_UNVALIDATED"></app-button>
  </div>
</app-modal>
