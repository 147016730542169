<div *ngIf="!companyData" class="flex justify-center mt-6">
  <app-loader></app-loader>
</div>

<div class="container" *ngIf="companyData">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div class="
            col-start-0
            md:col-start-2
            col-span-4
            md:col-span-3
            px-4
            md:px-1
          ">
        <app-breadcrum></app-breadcrum>
      </div>
    </div>
  </div>

  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div class="
            col-start-0
            md:col-start-2
            col-span-4
            md:col-span-3
            px-4
            md:px-1
            text-black text-2xl
            font-bold
          ">
        {{ "MESSAGES.EDIT_COMPANY" | uppercase | translate }}
      </div>
    </div>

    <app-company-form (formChanged)="formChanged($event)" [companyId]="companyId" [companyData]="companyData">
    </app-company-form>
  </div>

  <div class="mx-0 xl:mx-12">
    <div class="md:grid md:grid-cols-4 gap-4 mb-8 inline-block w-full">
      <div class="
            md:col-start-2 md:col-span-4
            px-4
            md:px-1
            gap-2
            flex flex-col
            md:flex-row
          ">
        <app-button [disabled]="!isValid" theme="main" (click)="isValid ? updateCompany() : ''" text="SAVE_CHANGE">
        </app-button>

        <app-button
          *ngIf="!hasUsers()"
          theme="delete_red"
          class="self-center"
          (click)="deleteCompany()"
          text="DELETE"
        ></app-button>

        <app-button *ngIf="companyData.active"
          theme="delete_red" class="self-center"
          (click)="deactiveCompany()"
          text="DEACTIVATE"
        ></app-button>

        <app-button *ngIf="!companyData.active"
          theme="delete" class="self-center"
          (click)="activateCompany()"
          text="ACTIVATE"
        ></app-button>
      </div>
    </div>
  </div>
</div>
