import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppLanguageService {

  constructor(private translateService: TranslateService, private router: Router) { }

  init(): Observable<any> {
    this.translateService.addLangs(this.getLangs());
    let lang = this.getSetUpLanguage();
    if (lang === null || !environment.appLangs.includes(lang)) {
      lang = this.getDefaultLang();
    }
    this.setLanguage(lang);
    this.translateService.currentLang = lang;
    return this.translateService.use(lang);
  }

  private setLanguage(language: string) {
    localStorage.setItem("app-lang", language);
    this.translateService.setDefaultLang(language);
  }

  changeAppLanguage(lang: string) {
    if (!environment.appLangs.includes(lang)) {
      return;
    }
    this.setLanguage(lang);
    this.translateService.use(lang);
    this.reloadCurrentRoute();
  }

  getSetUpLanguage(): string {
    return localStorage.getItem("app-lang") ? localStorage.getItem("app-lang") : "es";
  }

  getDefaultLang(): string {
    return environment.defaultLang;
  }

  getLangs(): string[] {
    return environment.appLangs;
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([currentUrl]);
  }
}
