import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "src/app/core/components/modal/modal.component";
import { AlertDetail, TextDetail } from "src/app/shared/models/alert.model";
import { AlertsService } from "src/app/shared/services/api/alerts/alerts.service";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { getDate } from "src/app/shared/utils/DateUtils";
import { S3Service } from "src/aws/services/s3Service/s3.service";
import { environment } from "src/environments/environment";

@UntilDestroy()
@Component({
  selector: "app-show-alert",
  templateUrl: "./show-alert.component.html",
  styleUrls: ["./show-alert.component.css"],
})
export class ShowAlertComponent implements OnInit {
  alert: AlertDetail;
  idAlert: string;
  enviroment = environment;
  @ViewChild("deleteModal") deleteModal: ModalComponent | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    private translateService: TranslateService,
    private s3Service: S3Service,
    private notif: NotificationService,
  ) {
    localStorage.removeItem("lastSearchQuery");
    
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params: ParamMap) => {
      this.idAlert = params.get("id");
    });
  }

  ngOnInit(): void {
    this.alertsService.getAlert(this.idAlert)
      .pipe(untilDestroyed(this))
      .subscribe((res: AlertDetail) => {
        this.alert = res
      });
  }

  currentLang(): string {
    return this.translateService.currentLang;
  }

  getTextLink(text: TextDetail) {
    return `${this.enviroment.selfUrl}rules/show-rule/${text.id}`;
  }

  getFormatES(date: string): string {
    return getDate(date, this.currentLang());
  }

  downloadPdf() {
    this.s3Service.downloadFile(this.alert.documents[0]);
  }

  downloadExcel() {
    this.s3Service.downloadFile(this.alert.documents[1]);
  }
}
