import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "src/app/core/components/modal/modal.component";
import { FavouritesService } from "src/app/shared/services/api/favourites/favourites.service";
import { RulesService } from "src/app/shared/services/api/rules/rules.service";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { getDate } from "src/app/shared/utils/DateUtils";
import { ProcessingStatus, ValidationStatus, ProcessingState, VALIDATION_STATUS_ENUM } from 'src/app/shared/models/other-data.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@UntilDestroy()
@Component({
  selector: "app-show-record",
  templateUrl: "./show-record.component.html",
  styleUrls: ["./show-record.component.css"],
})
export class ShowRecordComponent implements OnInit {
  search_record_id!: string;
  search_record!: { id: string; filters: any, result: null | string[], datetime: any };// search filters
  isFavourite = false;
  rulesData: any = null; // search result
  // pagination
  totalPage = 0;
  itemsPerPage = 6;
  currentPage = 1;
  total = 0;

  expanded = false; // left panel
  expandAside = false;

  actual_lang: string | "es";// pipe getMetadata

  // favourites
  @ViewChild("addFavModal") addFavModal: ModalComponent | undefined;
  addFavForm: UntypedFormGroup;
  @ViewChild("removeFavModal") removeFavModal: ModalComponent | undefined;
  processingStates = ProcessingStatus;
  validate = ValidationStatus;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private rulesService: RulesService,
    private favService: FavouritesService,
    private translateService: TranslateService,
    public notif: NotificationService,
  ) {
    this.route.paramMap
      .pipe(untilDestroyed(this))
      .subscribe((params: ParamMap) => {
        this.search_record_id = params.get("id") as string;
      });

    this.isFavourite = this.router.getCurrentNavigation()?.extras?.state?.favourite;
    this.search_record = this.router.getCurrentNavigation()?.extras?.state?.record;

    if (!this.search_record) {
      const parentUrl = router.url.includes("favourite") ? "/search/search-favourites" : "/search/search-record";
      this.router.navigate([parentUrl]);
    }
  }

  ngOnInit(): void {
    this.actual_lang = this.translateService.currentLang;
    this.requestData(this.currentPage);
    this.addFavForm = this.formBuilder.group({
      name: [, Validators.required]
    })
  }

  toggle(): void {
    this.expandAside = !this.expandAside;
  }

  expand(): void {
    this.expanded = !this.expanded;
  }

  getState(state: number) {
    state = Number(state);
    return this.processingStates.find((e: ProcessingState) => e.id === state ? e : '')?.name;
  }

  getValidated(validated: boolean) {
    return validated ? VALIDATION_STATUS_ENUM.VALIDATED : VALIDATION_STATUS_ENUM.PENDING;
  }

  requestData(page: number): void {
    this.rulesData = null;
    const query = this.isFavourite ? this.search_record.filters : { ids: this.search_record.result ? this.search_record.result : [''] };
    this.rulesService.searchRules({
      query,
      size: this.itemsPerPage,
      page: page,
      preventSave: true,
    })
      .pipe(untilDestroyed(this))
      .subscribe((list: any) => {
        this.rulesData = list;
        this.totalPage = list.totalPage;
        this.total = list.total;
      });
  }

  changePage(event: any) {
    this.requestData(event);
    this.currentPage = event;
  }

  addToFavourites(): void {
    this.addFavModal?.open();
  }

  removeFromFavourites(): void {
    this.favService.deleteFavourite(this.search_record_id)
      .pipe(untilDestroyed(this)).subscribe(() => {
        this.removeFavModal.close();
        this.rulesData = null;
        this.notif.success("NOTIF.DELETE_SUCCESS")
        this.router.navigate(["search", "search-favourites"]);
      });
  }

  confirmAddToFavourites(): void {
    // add to favourites
    this.addFavModal.close();
    const body = {
      title: this.addFavForm.value.name,
      filters: this.search_record.filters,
      datetime: new Date().getTime()
    }
    this.addFavForm?.reset();
    this.favService.createFavourite(body)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notif.success("NOTIF.SAVE_FAV_SEARCH");
        this.rulesData = null;
        this.router.navigate(["search", "search-favourites"]);
      });
  }

  getFormatES(): string {
    const date = new Date(parseInt(this.search_record.datetime)).toISOString();
    return getDate(date.split("T")[0], this.translateService.currentLang);
  }

  wasFilteredByValidation(): boolean {
    return this.search_record?.filters?.validated !== undefined;
  }

  closeModal() {
    this.addFavModal.close();
  }
}
