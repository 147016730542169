import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import json from "../../config/button";
import { ThemeButtonTemplate, ThemeButtonsTemplate } from "../../interfaces/ThemeTemplate";


@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.css"],
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input()
  text: string | undefined = undefined;
  @Input()
  disabled: boolean | undefined = false;
  @Input()
  theme = "main";
  @Input()
  textClass = "";

  public ngOnChanges(changes: SimpleChanges): void {
    if ("theme" in changes) {
      this.updateTheme();
    }
  }

  class = "";
  themeB: ThemeButtonTemplate | undefined;
  jsonThemes: ThemeButtonsTemplate = json;

  ngOnInit(): void {
    this.updateTheme();
  }

  updateTheme(): void {
    this.themeB = this.jsonThemes[this.theme];
    this.class = `${this.themeB?.style} ${this.themeB?.state}`;
  }
}
