import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AWSService } from 'src/aws/aws.service';

@Injectable({
  providedIn: 'root'
})
export class NoAccessGuard  {
  constructor(
    private aws: AWSService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.aws.getUserGroups().pipe(
      map(groups => !!groups.length),
      map(hasGroups => {
        if (hasGroups) {
          return true;
        }

        return this.router.createUrlTree(['no-access']);
      })
    )
  }
}
