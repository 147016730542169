<div class="footer">
  <div>
    <span>&copy; {{ getCurrentYear() }} Deloitte S.L.</span>
    <span>&#124;</span>
    <a routerLink="/legal-notice">{{"LABEL.LEGAL_NOTICE" | translate}}</a>
  </div>

  <div class="social-media-icons">
    <ul>
      <li>
        <a class="icon-fb" title="Facebook" href="https://www.facebook.com/Tufuturoendeloitte" target="_blank"></a>
      </li>
      <li>
        <a class="icon-tw" title="Twitter" href="http://www.twitter.com/Deloitte_Spain" target="_blank"></a>
      </li>
      <li>
        <a class="icon-lin" title="LinkedIn" href="https://www.linkedin.com/company/deloitte-espana"
          target="_blank"></a>
      </li>
      <li>
        <a class="icon-yt" title="Youtube" href="http://www.youtube.com/user/DeloitteES" target="_blank"></a>
      </li>
      <li>
        <a class="icon-blog" title="Blog de Deloitte Legal en www.legaltoday.com"
          href="http://www.legaltoday.com/blogs/fiscal/blog-deloitte-abogados" target="_blank"></a>
      </li>
    </ul>
  </div>
</div>
