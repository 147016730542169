import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/shared/services/admin/users/users.service';
import { CognitoService } from 'src/aws/services/cognito/cognito.service';
import { Location as ngLocation } from '@angular/common';
import { CompanyService } from 'src/app/shared/services/api/company/company.service';
import { TestRequest } from '@angular/common/http/testing';

@UntilDestroy()
@Component({
  selector: 'app-show-user',
  templateUrl: './show-user.component.html',
  styleUrls: ['./show-user.component.scss']
})
export class ShowUserComponent{
  user: any
  idUser: string;
  loadingCompany: boolean=true;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private companyService: CompanyService,
    public translateService: TranslateService,
    private router: Router,
    private location: ngLocation,
  ) {
    localStorage.removeItem("lastSearchQuery");

      this.loadingCompany = true
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params: ParamMap) => {

      this.idUser = params.get("id");
    this.usersService.getUserDetail(this.idUser).pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = {
        id: res.appData?.id,
        interests:res.appData?.field_interest,
        name: res.user.attributes.name,
        lastname: res.user.attributes.familyName,
        email: res.user.attributes.email,
        groups: res.user?.groups,
        receiveAlerts: res.user.attributes.receiveAlerts,
        emailNewsletter: res.user.attributes.emailNewsletter,
        registration_date: res.user.UserCreateDate,
      };
      if(!this.user.id){
        this.user.notInDB = true
      }
      if(res.appData?.company){
        this.user.company= this.getCompany(res.appData?.company)
      }
      else{
        this.user.company = res.appData?.company
        this.loadingCompany = false
      }
    });

  })

   }

   getCompany(companyId:string){
    this.companyService
    .getCompany(companyId)
    .pipe(untilDestroyed(this))
    .subscribe(res => {
      this.user.company = res.name;
      this.loadingCompany = false
    })
   }
  currentLang() {
    return this.translateService.currentLang;
  }
  editUser(): void {
    this.router.navigate(["administration", "edit-user", this.idUser]);
  }
  back(){
    this.location.back();
  }
}

