import { Component, EventEmitter, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AlertFilter, SearchAlertOutput, AlertOut } from "src/app/shared/models/alert.model";
import { AlertsService } from "src/app/shared/services/api/alerts/alerts.service";

@UntilDestroy()
@Component({
  selector: "app-alert-record",
  templateUrl: "./alert-record.component.html",
  styleUrls: ["./alert-record.component.css"],
})
export class AlertRecordComponent {
  @Output() resetValues = new EventEmitter(); // reset for multiselect
  searchAlertsForm: UntypedFormGroup; // search form
  expandAside = false; // Boolean para expandir menu lateral
  totalPage = 0;
  itemsPerPage = 6;
  currentPage = 1;
  total = 0;
  alertData: AlertOut[] | null;

  constructor(private alertsService: AlertsService,) {
    localStorage.removeItem("lastSearchQuery");
    this.requestData({}, this.currentPage);
  }


  toggle(): void {
    this.expandAside = !this.expandAside;
  }

  formChanged(event: UntypedFormGroup): void {
    this.searchAlertsForm = event;
  }

  search(): void {
    this.requestData(this.searchAlertsForm ? this.searchAlertsForm.value : {}, 1);
    this.currentPage = 1;
  }

  changePage(event: any) {
    this.alertData = null;
    this.requestData(this.searchAlertsForm ? this.searchAlertsForm.value : {}, event);
    this.currentPage = event;
  }


  requestData(filters: AlertFilter, page: number): void {
    this.alertData = null;

    const query = {};
    if (filters.title) query['title'] = filters.title;
    if (filters.language) query['language'] = filters.language;
    if (filters.area) query['area'] = filters.area;
    if (filters.fields?.length > 0) query['fields'] = filters.fields;
    if (filters.recipient) query['recipient'] = filters.recipient;
    if (filters.date?.length > 0) query['date'] = filters.date;

    this.alertsService
      .searchAlerts({
        query,
        size: this.itemsPerPage,
        page,
      })
      .pipe(untilDestroyed(this))
      .subscribe((list: SearchAlertOutput<AlertOut>) => {
        console.log("ebaena: list.items: ", list.items);
        this.alertData = list.items;
        this.totalPage = list.totalPage;
        this.total = list.total;
      })
  }

  resetFilter(): void {
    this.alertData = null;
    this.resetValues.emit();
    this.requestData({}, 1);
    this.currentPage = 1;
  }

}
