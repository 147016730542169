import { Component, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "src/app/core/components/modal/modal.component";
import { ProcessingStatus, RecipientOption, Recipients } from 'src/app/shared/models/other-data.model';
import { Router } from "@angular/router";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { AlertsService } from "src/app/shared/services/api/alerts/alerts.service";
import { AppLanguageService } from "src/app/shared/services/app-language/app-language.service";
import { AlertTextInfo, Recipient, RecipientType } from "src/app/shared/models/alert.model";
import validators from "src/app/core/config/validators";
import { CompanyService } from "src/app/shared/services/api/company/company.service";
import { UsersService } from "src/app/shared/services/admin/users/users.service";
import { tapOnError } from "src/app/shared/utils/rxjsUtils";
import { tap } from "rxjs/operators";
import { EvolutionTitleInfo, RuleFilter } from "src/app/shared/models/rules.model";
import { ExportPdfService } from "src/app/shared/services/api/export/pdf/export-pdf.service";
import { ExportExcelService } from "src/app/shared/services/api/export/excel/export-excel.service";
import { S3Service } from "src/aws/services/s3Service/s3.service";

@UntilDestroy()
@Component({
  selector: "app-register-alert",
  templateUrl: "./register-alert.component.html",
  styleUrls: ["./register-alert.component.scss"],
})
export class RegisterAlertComponent {
  @ViewChild("modal") modal: ModalComponent | undefined;
  processingStates = ProcessingStatus;
  evolutionsOptions: string = "";
  registerAlertForm: UntypedFormGroup;
  recipient_type: number;

  // recipients
  recipient_types = Recipients;
  recipientOptions;
  users;
  companies;
  emailAddress = null;
  creatingAlert: boolean = false

  constructor(
    public translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private notif: NotificationService,
    private alertService: AlertsService,
    private appLangService: AppLanguageService,
    private companyService: CompanyService,
    private usersService: UsersService,
    private exportExcelService: ExportExcelService,
    private exportPdfService: ExportPdfService,
    private s3Service: S3Service
  ) {
    localStorage.removeItem("lastSearchQuery");
    
    this.buildForm();
    if (this.router.getCurrentNavigation()?.extras.state) {
      const state = this.router.getCurrentNavigation()?.extras.state;

      if (state.rule) {
        const lang = state.lang ? state.lang : state.rule.info.defaultLang;
        this.fillForm(lang, state.rule);
      }
      else if (state.withExport) {
        this.registerAlertForm.removeControl("texts");
        this.registerAlertForm.addControl("documents", this.formBuilder.control([], Validators.required));
        this.loadExportedDocuments(state.filters);
      }
    }

    this.loadCompanies();
    this.loadUsers();
  }

  private buildForm() {
    this.registerAlertForm = this.formBuilder.group({
      title: [, Validators.required],
      description: [, Validators.required],
      language: [this.translateService.currentLang, Validators.required],
      recipient: [[], Validators.required],
    });

    this.registerAlertForm.addControl("texts", this.formBuilder.control([]));
  }

  private fillForm(lang: string, item: any) {
    this.registerAlertForm.get('description')
      .setValue(item.info.lang_info[lang].title + "\n\n" + item.info.lang_info[lang].description);

    this.registerAlertForm.get('texts').value.push({
      id: item?.id,
      title: this.getTextTitles(item),
      state: Number(item?.info?.state),
      publication_date: item?.publication_date,
      defaultLang: item?.info?.defaultLang
    })
  }

  private loadExportedDocuments(filters: RuleFilter) {
    this.exportPdfService.exportPdfSearch(filters)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onExportPdfError(err)),
        tap(res => {
          this.registerAlertForm.get('documents').value[0] = res;
          this.registerAlertForm.get('documents').updateValueAndValidity();
        })
      )
      .subscribe();

    this.exportExcelService.exportExcelSearch(filters)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onExportExcelError(err)),
        tap(res => {
          this.registerAlertForm.get('documents').value[1] = res.url;
          this.registerAlertForm.get('documents').updateValueAndValidity();
        })
      )
      .subscribe();
  }

  private onExportPdfError(err: unknown) {
    console.log("Error while exporting to pdf", err);
    this.notif.error("NOTIF.EXPORT_PDF_ERROR");
  }

  private onExportExcelError(err: unknown) {
    console.log("Error while exporting to excel", err);
    this.notif.error("NOTIF.EXPORT_EXCEL_ERROR");
  }

  private getTextTitles(item): EvolutionTitleInfo {
    const langs = Object.keys(item?.info?.lang_info);
    const res = {};
    langs.forEach(lang => {
      const textLang = lang.toLowerCase();
      res[textLang] = item?.info?.lang_info?.[textLang]?.title
    });
    return res;
  }

  private loadUsers() {
    this.usersService.getAllUsers()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.users = res
          .filter(user => user.attributes.receiveAlerts)
          .map(user => ({
            type: RecipientType.USER,
            value: user.id,
            name: `${user.attributes.name}, ${user.attributes.familyName}`
          }));
      })
  }

  private loadCompanies() {
    this.companyService.getCompanies()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.companies = res.map((c) => ({
          type: RecipientType.COMPANY,
          value: c.id,
          name: c.name
        }));
      })
  }

  get evolutions(): UntypedFormArray {
    return this.registerAlertForm.get('texts') as UntypedFormArray
  }

  get recipients(): UntypedFormArray {
    return this.registerAlertForm.controls['recipient'] as UntypedFormArray
  }
  updateCreatingAlert(){
    if(this.creatingAlert || !this.registerAlertForm.valid ) return;
    this.creatingAlert = true
    this.sendAlert()
  }
  sendAlert() {

    if(this.creatingAlert===false) return;
    if (!this.registerAlertForm.valid) {
      console.log('Invalid form', this.registerAlertForm.value);
      return;
    }

    let body = this.registerAlertForm.value;

    if (body.texts) {
      body.texts = body.texts.map(ev => ({
        id: ev.id,
        title: ev.title[this.getTextLanguage(ev, body.language)],
        path: "rules/show-rule/:id"
      }));
    }

    this.alertService.sendAlert(body)
      .pipe(
        untilDestroyed(this),
        tapOnError((err) => this.onSendAlertError(err)),
        tap(() => this.onSendAlertSuccess())
      )
      .subscribe();
  }

  private onSendAlertError(err: unknown) {
    console.log("Error while sending alert", err);
    this.notif.error("NOTIF.GENERAL_ERROR");
    this.creatingAlert = false
  }

  private onSendAlertSuccess() {
    this.notif.success("NOTIF.CREATE_ALERT_SUCCESS")
    this.router.navigate(["alerts", "alert-record"])
    this.creatingAlert = false
  }

  private getTextLanguage(text: AlertTextInfo, language: string): string {
    let lang = text.title[language] ? language : this.translateService.currentLang;
    if (!text.title[lang]) {
      lang = text.defaultLang;
    }
    return lang;
  }

  closeModal() {
    this.modal?.close();
  }

  addText<T>(control: AbstractControl, item: T) {
    control.setValue([...(control.value || []), item]);
  }

  removeText<T>(control: AbstractControl, index: number) {
    if (control instanceof UntypedFormArray) {
      control.removeAt(index);
    } else {
      control.setValue((control.value || []).filter((_, i) => i != index));
    }
  }

  addToRecipients(item: Recipient) {
    const repeated = this.recipients.value.find(element => element.type === item.type && element.value === item.value);
    if (repeated) {
      return;
    }

    this.recipients.value.push(item);
    this.recipients.updateValueAndValidity();
  }

  private isValidEmail(): boolean {
    return validators.mail.test(this.emailAddress);
  }

  addEmailToRecipients() {
    if (!this.isValidEmail()) {
      return;
    }

    const mail = this.emailAddress;
    const repeated = this.recipients.value.find(element => element.value === mail);
    if (repeated) {
      return;
    }

    this.recipients.value.push({
      type: RecipientType.EMAIL,
      value: mail
    });

    this.recipients.updateValueAndValidity();
    this.emailAddress = null;
  }

  onRecipientTypeChange(value: RecipientOption) {
    this.recipient_type = value.id;

    if (this.recipient_type == RecipientType.COMPANY) {
      this.recipientOptions = this.companies;
    }
    else if (this.recipient_type === RecipientType.USER) {
      this.recipientOptions = this.users;
    }
  }

  searchRecipients(event: any) {
    const value = event.target.value;

    if (this.recipient_type === RecipientType.COMPANY) {
      this.recipientOptions = value === "" ?
        this.companies :
        this.companies.filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
    }
    else if (this.recipient_type === RecipientType.USER) {
      this.recipientOptions = value === "" ?
        this.users :
        this.users.filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  removeRecipient(index: number) {
    this.recipients.value.splice(index, 1);
    this.recipients.updateValueAndValidity();
  }

  getLangs(): string[] {
    return this.appLangService.getLangs();
  }

  get searchRecipientsMesssage(): string {
    let searchMsg = '';
    if (this.recipient_type === RecipientType.COMPANY || this.recipient_type === RecipientType.USER) {
      searchMsg = this.translateService.instant('MESSAGES.SEARCH_RECIPIENTS')
        .replace('$recipient_type', this.translateService.instant(this.getRecipientTypeName()));
    }
    return searchMsg;
  }

  getRecipientTypeName(): string {
    return this.recipient_types.filter(elem => elem.id === this.recipient_type)[0].name;
  }

  isCompanyOrUser(): boolean {
    return this.recipient_type === RecipientType.COMPANY || this.recipient_type === RecipientType.USER;
  }

  onEmailChange(event: any) {
    this.emailAddress = event.target.value;
  }

  isEmail(): boolean {
    return this.recipient_type == RecipientType.EMAIL;
  }

  downloadPdf() {
    this.s3Service.downloadFile(this.registerAlertForm.get('documents').value[0]);
  }

  downloadExcel() {
    this.s3Service.downloadFile(this.registerAlertForm.get('documents').value[1]);
  }
}
