import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormArray,
  AbstractControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { S3Service, tapOnUploadComplete, tapOnUploadProgress } from "src/aws/services/s3Service/s3.service";
import { GetMasterdataElementNamePipe } from 'src/app/shared/pipes/get-masterdata-element-name/get-masterdata-element-name.pipe';
import { GetMasterDataPipe } from 'src/app/shared/pipes/get-masterdata/get-masterdata.pipe';

import { ProcessingStatus, Impacts } from 'src/app/shared/models/other-data.model';
import { MasterDataService } from 'src/app/shared/services/master-data/master-data.service';
import { MasterDataTypes } from 'src/app/shared/models/master-data.model';
import { buildLinkForm, ControlKey, DateRelationError, FormModel, RuleFormHelper } from './rule-form.form';
import { RuleFormData } from './rule-form.model';
import { Observable } from 'rxjs';
import { distinctUntilChanged, finalize, map, tap } from 'rxjs/operators';
import { datesByPhase } from './rule-form.config';
import { tapOnError } from 'src/app/shared/utils/rxjsUtils';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UploadTask } from '@aws-amplify/storage';
import { UsersService } from 'src/app/shared/services/admin/users/users.service';
import { environment } from 'src/environments/environment';

interface Option {
  id: string,
  name: string,
}

type UploadTaskUI = { task?: UploadTask, name: string };

@UntilDestroy()
@Component({
  selector: 'app-rule-form',
  templateUrl: './rule-form.component.html',
  styleUrls: ['./rule-form.component.scss'],
})
export class RuleFormComponent implements OnInit {
  // form vars
  form: UntypedFormGroup;
  formHelper: RuleFormHelper;

  // MD Options
  areas = this.getMasterData.transformActive(MasterDataTypes.AREA);
  clusters = this.getMasterData.transformActive(MasterDataTypes.CLUSTER);
  phases = this.getMasterData.transformActive(MasterDataTypes.PHASE);
  fields: Observable<Option[]> = null;
  initiatives = this.getMasterData.transformActive(MasterDataTypes.INITIATIVES);
  geo_scopes: Observable<Option[]> = null;
  issuers: Observable<Option[]> = null;
  types: Observable<Option[]> = null;

  // Other Options
  datesToShow?: ControlKey[] = null;
  availableLangs: string[];
  processingStates = ProcessingStatus;
  impacts = Impacts;
  requiredAction: Observable<Option[]> = null;

  // Validated value, will be saved when assigned and returned on getData
  validated: boolean;

  // Aux inputs
  otherLinkForm: UntypedFormGroup;

  // Other elements
  uploadDocumentTask: UploadTaskUI = null;
  uploadOtherDocumentTasks: UploadTaskUI[] = [];

  user_interests: string[]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private masterDataService: MasterDataService,
    private s3Service: S3Service,
    private t: TranslateService,
    private notif: NotificationService,
    private getMasterdataElementName: GetMasterdataElementNamePipe,
    private getMasterData: GetMasterDataPipe,
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private usersService: UsersService

  ) {
    this.availableLangs = this.t.getLangs();
    const userName = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.aws.Auth.userPoolWebClientId}.LastAuthUser`)
    const user_id= JSON.parse(localStorage.getItem(`CognitoIdentityServiceProvider.${environment.aws.Auth.userPoolWebClientId}.${userName}.userData`)).UserAttributes.find(a => a.Name == "sub")?.Value

    this.usersService.getUserAppData(user_id)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.user_interests = res.field_interest;
        if (this.user_interests?.length > 0) {
          const areas = this.user_interests.map(field => this.masterDataService.searchParent("field", field, "area"))
          this.areas = this.areas.filter(area => areas.includes(area.id));
        }
      })
  }


  ///////////////////////////////////////////////////////////////////
  //  Init / Setup
  ///////////////////////////////////////////////////////////////////

  ngOnInit(): void {
    this.formHelper = new RuleFormHelper(this.formBuilder, this.t);
    this.form = this.formHelper.build();

    this.otherLinkForm = buildLinkForm(this.formBuilder);
    this.otherLinkForm.disable();

    this.setupRx();
  }

  setupRx() {

    // Setup relations
    this.fields = this.createRelationObservable('areas', 'fields', MasterDataTypes.AREA, MasterDataTypes.FIELD, updateMultiValueControl);
    this.geo_scopes = this.createRelationObservable('cluster', 'geo_scope', MasterDataTypes.CLUSTER, MasterDataTypes.GEO_SCOPE, updateSimpleControl);
    this.issuers = this.createRelationObservable('cluster', 'issuer', MasterDataTypes.CLUSTER, MasterDataTypes.ISSUER, updateSimpleControl);
    this.types = this.createRelationObservable('phase', 'type', MasterDataTypes.PHASE, MasterDataTypes.TYPE, updateSimpleControl);

    // Dates to show based on type
    this.form.get('phase').valueChanges.pipe(
      untilDestroyed(this),
      map(type => datesByPhase[type] || null),
      tap(dates => {
        dates.forEach(name => {
          console.log("ebaena ", name);
          return this.form.get(name)?.reset();
        });
        return this.datesToShow = dates;
      })
    ).subscribe();

    // Reset some dates based on phase and state
    // combineLatest([
    //   this.form.get('phase').valueChanges,
    //   this.form.get('state').valueChanges,
    // ]).pipe(
    //   untilDestroyed(this),
    //   map(values => {
    //     const phase: string = values[0];
    //     const state: number = values[1];

    //     return this.checkDatesToReset(phase, state);
    //   }),
    //   tap(toReset => toReset.forEach(name => this.form.get(name)?.reset()))
    // ).subscribe();
  }

  createRelationObservable(parentForm: ControlKey, childForm: ControlKey, parentMD: MasterDataTypes, childMD: MasterDataTypes, childUpdater: ((control: AbstractControl, options: Option[]) => boolean) = undefined): Observable<Option[]> {
    const parentControl = this.form.get(parentForm)
    const childControl = this.form.get(childForm);

    childControl.disable();

    return parentControl.valueChanges.pipe(
      distinctUntilChanged(),
      // Standarize. whatever value is to array (of ids)
      map((value: string | string[]) => {
        if (!value) return [];

        if (Array.isArray(value)) {
          if (!value.length) return [];
          return value;
        }
        else if (typeof value == 'string') {
          return [value];
        } else {
          // Unrecognized value
          console.error(`Unrecognized value on ${parentForm} control`, value);
          return [];
        }
      }),
      // parent ID'S to child ID'S
      map(ids => ids.map(id => {
        if (childMD === "field") {
          if (this.user_interests?.length > 0) {
            return this.masterDataService.getActiveRelations(parentMD, id, childMD).filter(field => this.user_interests.includes(field))
          }
        }
        return this.masterDataService.getActiveRelations(parentMD, id, childMD)
      }

      ).flat()),
      // Remove duplicates
      map(options => [... new Set(options)]),
      // From ID to option with id and name
      map(options => options.map((id) => ({
        id,
        name: this.getMasterdataElementName.transform(childMD, id) as string,
      }))),
      // Sort by name
      map(options => options.sort((a, b) => a.name.localeCompare(b.name))),
      tap(options => { // Sync child control value
        console.log(parentControl.valueChanges);

        if (!options?.length) {
          childControl.disable();
        } else {
          childControl.enable();
        }

        if (childUpdater && childUpdater(childControl, options)) {

        } else {
          childControl.reset();
        }

        const currentValue = childControl.value;
        console.log(this.form.value);
      })
    )
  }


  ///////////////////////////////////////////////////////////////////
  //  Data in/out
  ///////////////////////////////////////////////////////////////////

  getData(): RuleFormData {
    const formValue = this.form.value;

    return this.formToData(formValue);
  }

  setData(data: RuleFormData) {
    this.validated = data.info.validated

    const formValue = this.dataToForm(data);

    this.formHelper.prepareLangs(Object.keys(formValue.lang_info));

    this.form.patchValue(formValue);

    // Force one change detection to make sure rx-related elements are properly sync'd on UI
    this.changeDetectorRef.detectChanges();
  }

  dataToForm(data: RuleFormData): FormModel {
    const lang = data.info?.defaultLang?.toLowerCase() || Object.keys(data.info.lang_info)[0];

    return {
      type: data.type,
      phase: data.phase,
      fields: data.fields,
      areas: data.areas,
      issuer: data.issuer,
      geo_scope: data.geo_scope,
      initiatives: data.initiatives,
      cluster: this.masterDataService.searchParent(MasterDataTypes.GEO_SCOPE, data.geo_scope, MasterDataTypes.CLUSTER),

      impact: data.info.impact,
      requiredAction: data.info.requiredAction,
      state: data.info.state,

      publication_date: data.info.dates.publication_date,
      effective_date: data.info.dates.effective_date || null,
      approval_date: data.info.dates.approval_date || null,
      closing_date: data.info.dates.closing_date || null,
      application_date: data.info.dates.application_date || null,

      evolutions: data.evolutions,

      selectedLang: lang,
      defaultLang: lang,
      lang_info: data.info.lang_info,
    }
  }

  formToData(formValue: FormModel): RuleFormData {
    let dates = {
      publication_date: formValue.publication_date,
      effective_date: formValue.effective_date,
      approval_date: formValue.approval_date,
      closing_date: formValue.closing_date,
      application_date: formValue.application_date,
    };

    const data: RuleFormData = {
      info: {
        defaultLang: formValue.defaultLang,
        lang_info: formValue.lang_info,
        state: formValue.state,
        impact: formValue.impact,
        requiredAction: formValue.requiredAction,
        dates,
        validated: this.validated,
      },
      type: formValue.type,
      phase: formValue.phase,
      fields: formValue.fields,
      areas: formValue.areas,
      issuer: formValue.issuer,
      geo_scope: formValue.geo_scope,
      initiatives: formValue.initiatives,
      evolutions: formValue.evolutions.map((ev) => ({
        id: ev.id,
        state: ev.state,
      }))
    };
    return data;
  }


  ///////////////////////////////////////////////////////////////////
  //  Aux methods
  ///////////////////////////////////////////////////////////////////

  private checkDatesToReset(phase: string, state: number): ControlKey[] {
    const toReset: Set<ControlKey> = new Set();

    if (!(phase === 'PH_IN' && state === 2)) {
      toReset.add('closing_date');
    }

    if (!(phase === 'PH_IN' || phase === 'PH_NOR')) {
      toReset.add('approval_date');
    }

    if (
      !(
        (phase === 'PH_NOR' || phase === 'PH_CI') &&
        state === 1
      )
    ) {
      toReset.add('effective_date');
    }

    if (
      !(
        phase === 'PH_IN' || phase === 'PH_NOR'
      )
    ) {
      toReset.add('application_date');
    }

    return [...toReset];
  }

  ///////////////////////////////////////////////////////////////////
  //  Aux methods
  ///////////////////////////////////////////////////////////////////

  removeLang(lang: string) {
    this.formHelper.removeLang(lang, true);
    this.formHelper.syncLang();
  }

  uploadDocument(control: UntypedFormControl, event: any) {
    const file: File = event.target.files[0];
    const { name } = file;

    this.uploadDocumentTask = {
      name,
    }
    this.s3Service.uploadPdfFile(file)
      .pipe(
        untilDestroyed(this),
        tapOnUploadProgress(task => {
          if (this.uploadDocumentTask) {
            this.uploadDocumentTask.task = task;
          }
        }),
        tapOnUploadComplete(v => control.setValue({ link: v, title: name })),
        tapOnError(e => {
          this.notif.error('NOTIF.UPLOAD_DOC_ERROR'); // TODO: i18n
        }),
        finalize(() => {
          this.uploadDocumentTask = null;
        })
      )
      .subscribe();
  }

  cancelUploadTask(task: UploadTaskUI) {
    const uploadTask = task.task;
    if (uploadTask) {
      this.s3Service.cancelUpload(uploadTask);
    }
  }

  addOtherLinks(form: UntypedFormGroup, control: UntypedFormArray) {
    if (form.invalid) {
      return;
    }

    const link = form.value;
    this.addItem(control, link);
    form.disable();
  }

  uploadOtherDocuments(control: UntypedFormArray, event: any) {
    [...(event?.target?.files || [])].forEach(file => {
      const { name } = file;
      const uploadTask: UploadTaskUI = {
        name,
      }

      this.uploadOtherDocumentTasks.push(uploadTask);

      this.s3Service.uploadPdfFile(file)
        .pipe(
          untilDestroyed(this),
          tapOnUploadProgress(task => {
            uploadTask.task = task;
          }),
          tapOnUploadComplete(url => {
            const doc = {
              link: url,
              title: name
            }
            this.addItem(control, doc)
          }),
          tapOnError(e => {
            this.notif.error('NOTIF.UPLOAD_DOC_ERROR'); // TODO: i18n
          }),
          finalize(() => {
            // Remove task from list
            this.uploadOtherDocumentTasks = this.uploadOtherDocumentTasks.filter(task => task !== uploadTask);
          })
        )
        .subscribe();
    });
  }

  // open document in new window
  openDocument(docElement: any) {
    this.s3Service.downloadFile(docElement.link);
  }

  addItem<T>(control: AbstractControl, item: T) {
    control.setValue([...(control.value || []), item]);
  }

  removeItem<T>(control: AbstractControl, index: number) {
    if (control instanceof UntypedFormArray) {
      control.removeAt(index);
    } else {
      control.setValue((control.value || []).filter((_, i) => i != index));
    }
  }

  changeEvolutionState(event) {
    let rules = this.form.get('evolutions').value;

    if (!rules) {
      return
    }
    rules[event.index]["state"] = Number(event.value);
    this.form.get('evolutions').setValue(rules)

  }

  openOtherLinkForm() {
    this.otherLinkForm.reset();
    this.otherLinkForm.enable();

    this.changeDetectorRef.detectChanges();
    this.elementRef.nativeElement.querySelector('form.linkForm input')?.focus()
  }



  ///////////////////////////////////////////////////////////////////
  // UI aux methods
  ///////////////////////////////////////////////////////////////////

  getLangStatus(lang: string): string {
    const formGroup = this.formHelper.langInfo.get(lang);
    const valid = formGroup?.valid;

    if (this.formHelper.selectedLang == lang) {
      if (valid) {
        return 'active-valid';
      } else {
        return 'active-invalid';
      }
    }

    if (!this.formHelper.hasLang(lang)) {
      return 'notAdded';
    }

    if (formGroup.valid) {
      return 'valid';
    } else {
      return 'invalid';
    }
  }

  localizeDateRelationError(err: DateRelationError): { [key in keyof DateRelationError]: string } {
    return {
      relation: this.t.instant(`VALIDATORS.COMPARISON_LABELS.${err.relation}`),
      refName: this.t.instant(`LABEL.${err.refName}`.toUpperCase()),
      refValue: err.refValue,
    }
  }
}

function updateSimpleControl(control: AbstractControl, options: Option[]): boolean {
  const currentValue = control.value;

  if (options?.find(opt => opt.id === currentValue)) {
    // DO nothing
    //console.log('Relation', `${parentForm} -> ${childForm}`, options, 'Same value', currentValue);
    return true;
  } else if (options?.length == 1) {
    // Only one, select
    //console.log('Relation', `${parentForm} -> ${childForm}`, options, 'Single option', options[0].id);
    control.setValue(options[0].id);
    return true;
  }

  return false;
}

function updateMultiValueControl(control: AbstractControl, options: Option[]): boolean {
  const currentValue: string[] = control.value || [];

  const existingOpts = options.filter(opt => currentValue.includes(opt.id));

  if (currentValue.length && currentValue.length == existingOpts.length) {
    // DO nothing
    //console.log('Relation', `${parentForm} -> ${childForm}`, options, 'Same value', currentValue);
    return true;
  } else if (existingOpts.length) {
    const ids = existingOpts.map(opt => opt.id);
    //console.log('Relation', `${parentForm} -> ${childForm}`, options, 'partial values', ids);
    control.setValue(ids);
    return true;
  } else if (options?.length == 1) {
    // Only one, select
    const ids = options.map(opt => opt.id);
    //console.log('Relation', `${parentForm} -> ${childForm}`, options, 'Single option', ids);
    control.setValue(ids);
    return true;
  }

  return false;
}
