export interface RegisterAlert {
  title: string;
  description: string;
  texts?: AlertText[];
  documents?: string[];
  recipient: Recipient[];
  language: string;
}

export interface AlertText {
  id: string,
  title: string,
  path: string
}

export interface Recipient {
  type: RecipientType,
  value: string,
  name?: string
}

export enum RecipientType {
  USER = 1,
  COMPANY = 2,
  EMAIL = 3
}

export interface SearchAlertInput<T> {
  query: T;
  page: number;
  size: number;
}

export interface SearchAlertOutput<T> {
  items: Array<T>;
  page: number;
  pageSize: number;
  total: number;
  totalPage: number;
}

export interface AlertFilter {
  title?: string;
  recipient?: string;
  language?: string;
  area?: string;
  fields?: string[];
  date?: string[];
}
export interface Alert extends RegisterAlert {
  id: string;
}

export interface AlertOut {
  id: string;
  title: string;
  recipients: Recipient[];
  language: string;
  areas: string[];
  fields: string[];
  creation_date: number;
  description?: string;
}
export interface AlertDetail extends AlertOut {
  description: string;
  texts?: TextDetail[];
  documents?: string[];
}

export interface TextDetail {
  id: string,
  title: string
}

export interface AlertTextInfo {
  id: number;
  title: TextTitleInfo;
  state: number;
  publication_date: Date;
  defaultLang: string;
}

export type TextTitleInfo = Partial<{
  "es": string,
  "en": string,
  "pt": string
}>
