import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Company } from 'src/app/shared/models/company.model';
import { CompanyService } from 'src/app/shared/services/api/company/company.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { tapOnError } from 'src/app/shared/utils/rxjsUtils';
import { CognitoUser } from 'src/aws/services/cognito/cognito.model';
import { S3Service } from 'src/aws/services/s3Service/s3.service';

@UntilDestroy(this)
@Component({
  selector: 'app-show-company',
  templateUrl: './show-company.component.html',
  styleUrls: ['./show-company.component.scss']
})
export class ShowCompanyComponent {
  companyId: string;
  companyData: Company;
  loading: boolean;
  logo: string;
  users: CognitoUser[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private notif: NotificationService,
    private s3Service: S3Service
  ) {
    localStorage.removeItem("lastSearchQuery");
    
    this.route.paramMap.pipe(untilDestroyed(this))
      .subscribe((params: ParamMap) => {
        this.companyId = params.get("id");
        this.loadCompanyData();
      });
  }

  loadCompanyData() {
    this.loading = true;
    this.companyService
      .getCompany(this.companyId)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.companyData = res;
        this.loadCompanyLogo();
        this.loadCompanyUsers();
        this.loading = false;
      })
  }

  loadCompanyLogo() {
    this.s3Service.getFileURL(this.companyData?.logo.link)
      .pipe(untilDestroyed(this))
      .subscribe(url => {
        this.logo = url;
      });
  }

  loadCompanyUsers() {
    this.companyService.getCompanyUsers(this.companyId)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.users = res;
      })
  }

  editCompany() {
    this.router.navigate(["administration", "edit-company", this.companyId]);
  }

  hasUsers(): boolean {
    return this.companyData.num_users > 0;
  }

  showUser(user: CognitoUser) {
    this.router.navigate(["administration", "users", user.id]);
  }

  getUserName(user: CognitoUser): string {
    return `${user.attributes.name}, ${user.attributes.familyName}`;
  }

  activateCompany(): void {
    this.companyService.updateActive(this.companyId, true)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onActivateError(err)),
        tap(_ => this.onActivateSuccess())
      )
      .subscribe();
  }

  deactiveCompany(): void {
    this.companyService.updateActive(this.companyId, false)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onDeactivateError(err)),
        tap(_ => this.onDeactivateSuccess())
      )
      .subscribe();
  }

  deleteCompany(): void {
    this.companyService
      .deleteCompany(this.companyId)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => this.onDeleteError(err)),
        tap(_ => this.onDeleteSuccess())
      )
      .subscribe();
  }

  onActivateError(err: unknown) {
    console.log('Error activating company', err);
    this.notif.error("NOTIF.ACTIVATE_ERROR");
  }

  onActivateSuccess() {
    this.notif.success("NOTIF.ACTIVATE_SUCCESS");
    this.router.navigate(["administration", "show-company", this.companyId]);
  }

  onDeactivateError(err: unknown) {
    console.log('Error deactivating company', err);
    this.notif.error("NOTIF.DEACTIVATE_ERROR");
  }

  onDeactivateSuccess() {
    this.notif.success("NOTIF.DEACTIVATE_SUCCESS");
    this.router.navigate(["administration", "show-company", this.companyId]);
  }

  onDeleteError(err: unknown) {
    console.log('Error deleting company', err);
    this.notif.error("NOTIF.DELETE_ERROR");
  }

  onDeleteSuccess() {
    this.notif.success("NOTIF.DELETE_SUCCESS");
    this.router.navigate(["administration", "search-company"]);
  }
}
