import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ErrorHandlingInterceptor } from './services/interceptors/error-handling-interceptor/error-handling.interceptor';
import { HttpInterceptor } from './services/interceptors/http-interceptor/http.interceptor';
import { NotificationService } from './services/notification/notification.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule as MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from '../app-routing.module';
import { NgxPaginationModule } from "ngx-pagination";
import { FavouritesService } from './services/api/favourites/favourites.service';
import { SearchHistoryService } from './services/api/search-history/search-history.service';
import { GetMasterdataElementNamePipe } from './pipes/get-masterdata-element-name/get-masterdata-element-name.pipe';
import { GetMasterdataElementNameListPipe } from './pipes/get-masterdata-element-name-list/get-masterdata-element-name-list.pipe';
import { ExportExcelService } from './services/api/export/excel/export-excel.service';
import { ExportPdfService } from './services/api/export/pdf/export-pdf.service';

//Material
@NgModule({
  declarations: [
    LocalizedDatePipe,
    GetMasterdataElementNamePipe,
    GetMasterdataElementNameListPipe,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    TranslateModule.forChild(),
    HttpClientModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    LocalizedDatePipe,
  ],
  providers: [
    HttpClient,
    NotificationService,
    SearchHistoryService,
    FavouritesService,
    ExportExcelService,
    ExportPdfService,
  ],
})
export class SharedModule { }
