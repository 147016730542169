import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Translate module
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AWSModule } from 'src/aws/aws.module';
import { GetMasterDataPipe } from './shared/pipes/get-masterdata/get-masterdata.pipe';
import { GetMasterdataElementNamePipe } from './shared/pipes/get-masterdata-element-name/get-masterdata-element-name.pipe';
import { GetMasterdataElementNameListPipe } from './shared/pipes/get-masterdata-element-name-list/get-masterdata-element-name-list.pipe';
import { HttpInterceptor } from './shared/services/interceptors/http-interceptor/http.interceptor';
import { ErrorHandlingInterceptor } from './shared/services/interceptors/error-handling-interceptor/error-handling.interceptor';
import { UserActionsService } from './shared/services/actions/user-actions.service';
import { MasterDataService } from './shared/services/master-data/master-data.service';
import { Observable, of } from 'rxjs';
import { AWSService } from 'src/aws/aws.service';

import { tap } from 'rxjs/operators';
import { MasterDataFile } from './shared/models/master-data.model';
import { AppLanguageService } from './shared/services/app-language/app-language.service';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

function initializeUserActions(userActions: UserActionsService, aws: AWSService): () => Observable<boolean> {
  return () => {
    aws.loginStatus.pipe(
      tap(isLogged => {
        if (isLogged) {
          userActions.loadUserActions();
        }
        else {
          userActions.clearUserActions();
        }
      })
    ).subscribe();

    return of(true);
  };
}
export function appInitializerFactory(appLangService: AppLanguageService): () => Observable<any> {
  return () => appLangService.init();
}

function initializeMasterData(masterDataService: MasterDataService): () => Observable<MasterDataFile> {
  return () => masterDataService.loadMasterData();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AWSModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor, multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUserActions,
      deps: [UserActionsService, AWSService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeMasterData,
      deps: [MasterDataService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppLanguageService],
      multi: true
    },
    GetMasterDataPipe,
    AWSModule.interceptor(),
    GetMasterdataElementNamePipe,
    GetMasterdataElementNameListPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
