<div *ngIf="!alert" class="flex justify-center mt-6">
  <app-loader></app-loader>
</div>

<div *ngIf="alert" class="container">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div class="
            col-start-0
            col-span-4
            md:col-span-3
            px-28
            md:px-28
          ">
        <app-breadcrum></app-breadcrum>
        <div class="w-full my-4">
          <span class="flex w-full md:w-auto pb-3 mb-2 md:pb-0 text-black text-2xl font-bold">{{
            "MENU_OPTIONS.SHOW-ALERT" | translate
            }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="mx-0 xl:mx-12">
      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.TITLE" | uppercase | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ alert?.title }}</div>
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full md:flex mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.ALERT_BODY" | uppercase | translate }}</label>
          </div>

          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ alert?.description }}</div>
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full md:flex mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.LANGUAGE" | uppercase | translate }}</label>
          </div>

          <div class="w-full md:w-9/12">
            <div class="text-sm"> {{ "LANGUAGE." + alert?.language | uppercase | translate }}</div>
          </div>
        </div>
      </div>

      <div *ngIf="alert?.texts" class="mt-12 pb-6 block border border-b-deloitte_gray9">
        <div class="md:flex mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28 flex align-center">
            <label>{{ "LABEL.AREA" | uppercase | translate }}</label>
          </div>

          <div style="display: flex; flex-wrap: wrap;">
            <div *ngFor="let area of alert?.areas" class="mt-3 mb-3 mr-2">
              <span class="tag py-2 px-2">
                {{ area | getMasterDataElement: 'area':currentLang() }}
              </span>
            </div>

          </div>
        </div>

        <div class="md:flex mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.FIELDS" | uppercase | translate }}</label>
          </div>

          <div class="md:w-9/12">
            <div class="md:flex md:flex-wrap">
              <div class="mr-8" *ngFor="let field of alert?.fields">
                {{ field | getMasterDataElement: 'field':currentLang() }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div *ngIf="alert?.texts" class="w-full md:flex mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "SECTION.LINKS" | uppercase | translate }}</label>
          </div>

          <div class="md:w-9/12">
            <ul class="text-sm">
              <li *ngFor="let text of alert?.texts">
                <div class="flex" style="align-items: center;">
                  <span class="dot mr-3"></span>
                  <a [href]="getTextLink(text)" target="_blank" class="underline">
                    {{text.title}}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="alert?.documents" class="w-full md:flex mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "SECTION.DOCUMENTS" | uppercase | translate }}</label>
          </div>

          <div class="md:w-9/12">
            <div class="flex" style="align-items: center;">
              <app-button class="hidden md:block pl-0" (click)="downloadPdf()" text="PDF" theme="export_home">
                <img class="inline-block pr-2" src="assets/imgs/exportar-pdf.png" />
              </app-button>

              <app-button class="hidden md:block" (click)="downloadExcel()" text="EXCEL" theme="export_home">
                <img class="inline-block pr-2 mb-1" src="assets/imgs/exportar-excel.png" />
              </app-button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full md:flex mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "SECTION.RECIPIENTS" | uppercase | translate }}</label>
          </div>

          <div class="md:w-9/12">
            <ul class="text-sm flex flex-wrap">
              <li class="mr-1" *ngFor="let recipient of alert?.recipients">
                  <span>{{ recipient.name ? recipient.name : recipient.value }} &#59;&nbsp;</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 mb-8 flex">
        <div class="w-full md:flex mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{
              "LABEL.COMMUNICATION_DATE" | uppercase | translate
              }}</label>
          </div>

          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ getFormatES(alert?.creation_date) }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

