import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ErrorViewComponent } from "./core/components/error-view/error-view.component";
import { LogoutComponent } from "./core/pages/auth/logout/logout.component";
import { LogoutGuard } from "./core/pages/auth/logout/logout.guard";
import { NoAccessComponent } from "./core/pages/auth/no-access/no-access.component";
import { NoAccessGuard } from "./core/pages/auth/no-access/no-access.guard";
import { HomeComponent } from "./core/pages/home/home.component";
import { LegalNoticeComponent } from "./core/pages/legal-notice/legal-notice.component";
import { MainGuard } from "./shared/guards/main.guard";
import { ErrorResolver } from "./shared/resolvers/error.resolver";
const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [MainGuard],
  },
  {
    path: "legal-notice",
    component: LegalNoticeComponent,
    canActivate: [MainGuard]
  },
  {
    path: "logout",
    component: LogoutComponent,
    canActivate: [LogoutGuard],
  },
  {
      path: "rules",
      loadChildren: () => import('./core/modules/rules/rules.module').then((m) => m.RulesModule),
  },
  {
    path: "administration",
    loadChildren: () => import('./core/modules/administration/administration.module').then((m) => m.AdministrationModule),
  },
  {
    path: "search",
    loadChildren: () => import('./core/modules/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: "alerts",
    loadChildren: () => import('./core/modules/alerts/alerts.module').then((m) => m.AlertsModule),
  },
  {
    path: "error",
    component: ErrorViewComponent,
    resolve: {
      error: ErrorResolver
    }
  },
  {
    path: "error/403",
    component: ErrorViewComponent,
    canActivate: [NoAccessGuard],
    resolve: {
      error: ErrorResolver
    }
  },
  {
    path: "error/:status",
    component: ErrorViewComponent,
    resolve: {
      error: ErrorResolver
    }
  },
  {
    path: "no-access",
    component: NoAccessComponent
  },
  {
    path: "**",
    redirectTo: "/",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
