import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "src/app/core/components/modal/modal.component";
import { GetMasterdataElementNameListPipe } from "src/app/shared/pipes/get-masterdata-element-name-list/get-masterdata-element-name-list.pipe";
import { GetMasterdataElementNamePipe } from "src/app/shared/pipes/get-masterdata-element-name/get-masterdata-element-name.pipe";
import { FavouritesService } from "src/app/shared/services/api/favourites/favourites.service";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { getDate } from "src/app/shared/utils/DateUtils";
import { ProcessingStatus, ProcessingState, VALIDATION_STATUS_ENUM, ExportResult } from 'src/app/shared/models/other-data.model'
import { MasterDataTypes } from "src/app/shared/models/master-data.model";
import { FavouriteSearch } from "src/app/shared/models/search-favourite.model";
import { ExportExcelService } from "src/app/shared/services/api/export/excel/export-excel.service";
import { ExportPdfService } from "src/app/shared/services/api/export/pdf/export-pdf.service";
import { S3Service } from "src/aws/services/s3Service/s3.service";
import { tap } from "rxjs/operators";
import { tapOnError } from "src/app/shared/utils/rxjsUtils";
import { RuleFilter } from "src/app/shared/models/rules.model";

@UntilDestroy()
@Component({
  selector: "app-search-favourites",
  templateUrl: "./search-favourites.component.html",
  styleUrls: ["./search-favourites.component.css"],
})
export class SearchFavouritesComponent implements OnInit {
  @ViewChild("modal") modal: ModalComponent | undefined;
  toggleOptions = false;
  favouritesData: FavouriteSearch[] = null;
  processingStates = ProcessingStatus;

  term: string;
  backUp;

  constructor(
    private router: Router,
    private t: TranslateService,
    private favourites: FavouritesService,
    private notif: NotificationService,
    private translateService: TranslateService,
    private getMasterdataElementNameList: GetMasterdataElementNameListPipe,
    private getMasterdataElementName: GetMasterdataElementNamePipe,
    private exportExcelService: ExportExcelService,
    private exportPdfService: ExportPdfService,
    private s3Service: S3Service
  ) {
  }
  ngOnInit(): void {
    localStorage.removeItem("lastSearchQuery");
    this.getFavouritesList()
  }

  getFavouritesList() {
    this.favourites.getFavourites()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.favouritesData = data
      })
  }

  getFilters(item: RuleFilter) {
    const res = [];
    if (item.title) {
      res.push(item.title)
    }
    if (item.lang) {
      res.push(this.translateService.instant("LANGUAGE." + item.lang.toUpperCase()));
    }
    if (item.validated !== undefined && item.validated !== null) {
      let currentValidate = item.validated ? VALIDATION_STATUS_ENUM.VALIDATED : VALIDATION_STATUS_ENUM.PENDING;
      res.push(this.translateService.instant(currentValidate));
    }
    if (item.geo_scopes) {
      res.push(this.getMasterdataElementName.transform(MasterDataTypes.CLUSTER, item.geo_scopes[0]));
    }
    if (item.areas) {
      res.push(this.getMasterdataElementNameList.transform(MasterDataTypes.AREA, item.areas));
    }
    if (item.fields) {
      res.push(...this.getMasterdataElementNameList.transform(MasterDataTypes.FIELD, item.fields));
    }
    if (item.issuers) {
      res.push(...this.getMasterdataElementNameList.transform(MasterDataTypes.ISSUER, item.issuers));
    }
    if (item.phases) {
      res.push(this.getMasterdataElementName.transform(MasterDataTypes.PHASE, item.phases[0]));
    }
    if (item.processing_status) {
      let currentPS;
      this.processingStates.forEach((elem: ProcessingState) => {
        if (elem.id == item.processing_status)
          currentPS = elem.name
      })
      res.push(this.translateService.instant(currentPS));
    }
    if (res.length == 0 && item.initiatives) {
      res.push(...this.getMasterdataElementNameList.transform(MasterDataTypes.INITIATIVES, item.initiatives));
    }
    if (res.length == 0 && item.publication_date) {
      res.push(...item.publication_date
        .filter(elem => !!elem)
        .map(element => new Date(parseInt(element)).toISOString().split("T")[0]));
    }
    return res
  }

  showFavourite(item: FavouriteSearch): void {
    this.router.navigate(["search", "show-favourite", item?.id], {
      state: { favourite: true, record: item },
    });
  }

  deleteElement(id): void {
    this.favourites.deleteFavourite(id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notif.success("NOTIF.DELETE_SUCCESS")
        this.favouritesData = null;
        this.getFavouritesList()

      })
  }

  deleteFavouriteHistory(): void {
    this.modal?.open();
  }

  deleteAll(): void {
    this.closeModal();
    this.favourites
      .deleteFavourites()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notif.success("NOTIF.DELETE_SUCCESS");
        this.favouritesData = null;
        this.getFavouritesList()
      });
  }

  getFormatES(timestamp: string): string {
    const date = new Date(parseInt(timestamp)).toISOString();
    return getDate(date.split("T")[0], this.t.currentLang);
  }

  closeModal(): void {
    this.modal.close();
  }

  exportpdf(fav: FavouriteSearch): void {
    this.exportPdfService.exportPdfSearch(fav.filters)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => {
          console.log("Error exporting search favourite result to pdf", err);
          this.notif.error("NOTIF.GENERAL_ERROR");
        }),
        tap(url => {
          this.s3Service.downloadFile(url);
        })
      )
      .subscribe();
  }

  exportexcel(fav: FavouriteSearch): void {
    this.exportExcelService.exportExcelSearch(fav.filters).pipe(
      untilDestroyed(this),
      tapOnError(err => {
        console.log("Error exporting search favourite result to excel", err);
        this.notif.error("NOTIF.GENERAL_ERROR");
      }),
      tap((res: ExportResult) => {
        const docs: string[] = res.documents.map(elem => elem.link);
        docs.push(res.url);
        this.s3Service.downloadDocuments(docs);
      })
    )
      .subscribe();
  }

  filterByName(event: any) {
    this.term = event.target.value.toLowerCase();
    if (this.term && this.term === '') {
      this.favouritesData = this.backUp;
    }
    else {
      if (!this.backUp) {
        this.backUp = this.favouritesData;
      }

      this.favouritesData = this.backUp.filter(elem => elem.title.toLowerCase().includes(this.term));
    }
  }
}
