import { environment as base_env } from './environment.aws-dev';


/**
 * The normal environment of FE will work against the aws dev environment
 * For local mock usage see localmock env
 */
const env = base_env;

env.production = false;
env.selfUrl = env.selfUrl;
env.aws.Auth.oauth.redirectSignIn = env.selfUrl;
env.aws.Auth.oauth.redirectSignOut = `${env.selfUrl}${env.aws.Auth.oauth.redirectSignOut.split('/').slice(-2).join('/')}`;



export const environment = env;
