<div *ngIf="!companyData" class="flex justify-center mt-6">
  <app-loader></app-loader>
</div>

<div class="container" *ngIf="companyData">
  <div class="mx-0 xl:mx-12">
    <div class="grid grid-cols-4 gap-4 mb-5 md:mb-10">
      <div class="
            col-start-0
            col-span-4
            md:col-span-3
            px-28
            md:px-28
          ">
        <app-breadcrum></app-breadcrum>
        <div class="w-full my-4">
          <span class="font-bold">{{
            "MENU_OPTIONS.SHOW-COMPANY" | translate
            }}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="companyData" class="container">
    <div class="mx-0 xl:mx-12">
      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.NAME" | uppercase | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ companyData?.name }}</div>
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.DOMAIN" | uppercase | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ companyData?.domain }}</div>
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.LOGO" | uppercase | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <img src="{{ logo }}" alt="{{companyData.logo.title}}" />
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.USERS" | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div *ngIf="users && users.length > 0 ">
              <div class="text-sm user-item cursor-pointer" *ngFor="let item of users" (click)="showUser(item)">{{ getUserName(item) + ';' }}</div>
            </div>

            <div *ngIf="!users || users.length === 0">
              <div class="text-sm">
                {{ "LABEL.NO_USERS" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-12 pb-6 flex border border-b-deloitte_gray9">
        <div class="w-full flex flex-wrap mx-4 md:mx-2">
          <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-28">
            <label>{{ "LABEL.REGISTRATION_DATE" | translate }}</label>
          </div>
          <div class="w-full md:w-9/12">
            <div class="text-sm">{{ companyData?.entry_date }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full hidden md:block">
    <div class="w-full md:flex md:flex-row md:justify-around px-2.5 py-5 md:gap-2 lg:px-12 xl:px-40">
      <div class="flex flex-col md:flex-row md:flex-wrap gap-2 md:items-center">
        <app-button theme="main" text="EDIT" (click)="editCompany()"></app-button>

        <app-button
          *ngIf="!hasUsers()"
          theme="delete_red"
          class="self-center"
          (click)="deleteCompany()"
          text="DELETE"
        ></app-button>

        <app-button *ngIf="companyData.active"
          theme="delete_red" class="self-center"
          (click)="deactiveCompany()"
          text="DEACTIVATE"
        ></app-button>

        <app-button *ngIf="!companyData.active"
          theme="delete" class="self-center"
          (click)="activateCompany()"
          text="ACTIVATE"
        ></app-button>

      </div>
    </div>
  </div>
</div>
