import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { CompanyOut } from "src/app/shared/models/company.model";
import { CompanyService } from "src/app/shared/services/api/company/company.service";
import { getDate } from "src/app/shared/utils/DateUtils";
import { CognitoUser } from "src/aws/services/cognito/cognito.model";

@UntilDestroy()
@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.css"],
})
export class CompanyComponent implements OnInit {
  @Input() company!: CompanyOut;
  @Input() index!: number;
  users: CognitoUser[];

  headers = [
    this.translateService.instant("HEAD.NAME"),
    this.translateService.instant("HEAD.DOMAIN"),
    this.translateService.instant("HEAD.NUM_USERS"),
    this.translateService.instant("HEAD.REGISTRATION_DATE"),
  ];

  constructor(private translateService: TranslateService, private router: Router, private companyService: CompanyService) {
  }

  ngOnInit(): void {
    this.companyService.getCompanyUsers(this.company.id)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.users = res;
      })
  }

  showUser(userId: string): void {
    this.router.navigate(["administration", "edit-user", userId]);
  }

  showCompany(): void {
    // edit company
    this.router.navigate(["administration", "show-company", this.company.id]);
  }

  getFormatES(date: string): string {
    return getDate(date, this.translateService.currentLang);
  }

  getUserName(user: CognitoUser): string {
    return `${user.attributes.name}, ${user.attributes.familyName}`;
  }
}
