<form [formGroup]="searchAlertsForm">
  <div>
    <div>
      <label for="title">
        {{ "label.title" | uppercase | translate }}
      </label>
      <input
        type="text"
        id="title"
        name="title"
        value=""
        formControlName="title"
      />
    </div>
  </div>
  <div>
    <div>
      <label for="recipient">
        {{ "label.recipient" | uppercase | translate }}
      </label>
      <input
        type="text"
        id="recipient"
        name="recipient"
        value=""
        formControlName="recipient"
      />
    </div>
  </div>

  <div>
    <div>
      <label for="lang">
        {{ "label.language" | uppercase | translate }}
      </label>

      <div>
        <select name="lang" id="lang" formControlName="language">
          <option *ngFor="let option of options.langs" value="{{ option}}">
            {{ "LANGUAGE." + option | uppercase | translate }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="masterdata-group">
    <div>
      <label for="area">
        {{ "label.area" | uppercase | translate }}
      </label>

      <div>
        <select name="area" id="area" formControlName="area" (change)="setFieldValues()">
          <option *ngFor="let option of options.areas" value="{{ option.id }}">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <label for="fields">
        {{ "LABEL.fields" | uppercase | translate }}
      </label>

      <app-multiselect
        [reset]="resetValues"
        [items]="options.fields"
        (valueChanged)="setFieldValue($event)"
      >
      </app-multiselect>
    </div>
  </div>

  <div>
    <div >
      <div>
        <label for="end_date">
          {{ "label.communication_date" | uppercase | translate }}
        </label>
      </div>

      <div id="dates">
        <div>
          <app-datepicker
            id="startDate"
            name="starDate"
            (change)="
            start_date($event)
            "
            [reset]="resetValues"
            placeholder="from"
          ></app-datepicker>
        </div>

        <div>
          <app-datepicker
            id="endDate"
            name="endDate"
            [reset]="resetValues"
            (change)="
            finish_date($event)
            "
            placeholder="to"
          ></app-datepicker>
        </div>
      </div>
    </div>
  </div>
  <!-- search input recipients -->
  <!-- recipients added list -->
</form>

