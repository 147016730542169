<div class="flex">
  <div class="w-3/12 md:w-3/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[0] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
          link
        "
        (click)="showCompany()"
      >
        {{ company?.name | uppercase }}
      </div>
    </div>
  </div>

  <div class="w-3/12 md:w-3/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[1] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
          link
        "
        (click)="showCompany()"
      >
        {{ company?.domain }}
      </div>
    </div>
  </div>

  <div class="w-3/12 md:w-3/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[3] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
          link
        "
        (click)="showCompany()"
      >
      {{ getFormatES(company?.entry_date) }}
      </div>
    </div>
  </div>

  <div class="w-3/12 md:w-3/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span class="inline-block truncate w-full px-4">{{ headers[2] }}</span>
    </div>
    <div class="py-2 bg-white flex h-full">
      <div class="w-full text-xs px-4 inline-block">
        {{company?.num_users }}
      </div>
    </div>
  </div>

</div>
