<div class="relative" [formGroup]="group">
  <input
    ngClass="{{
      customInputCSS ? customInputCSS :
      'w-full border rounded focus:outline-none appearance-none py-3 px-2 border-deloitte_gray9 text-sm'
    }}"
    readonly="true" id="{{ id_datepicker }}" value="{{ getFormatES() }}"
    (mouseover)="!pikadayLoaded ? loadPikaday() : ''" formControlName="date"
    placeholder="{{ (placeholder ? 'PLACEHOLDER.' + placeholder  : '') | uppercase | translate }}"
  />
  <img
    ngClass="{{ customInputCSS ? 'absolute right-0 top-0 p-1' : 'absolute right-0 top-0 p-3' }}"
    src="assets/imgs/calendar.png"
  />
</div>
