<div *ngIf="!searchData" class="loader">
  <app-loader></app-loader>
</div>

<div *ngIf="searchData" class="container flex-col md:flex-row flex items-stretch">
  <div class="w-full pb-20 md:pb-8 md:px-8 block md:flex-auto">
    <app-breadcrum></app-breadcrum>

    <div class="w-full flex flex-col md:flex-row justify-between mt-8 mb-3">
      <div class="
            flex
            bg-white
            justify-between
            w-full
            md:mb-0 md:w-6/12
            p-4
            border
            border-t-input_search
            border-r-input_search
            border-l-input_search
            border-b-deloitte
            search_input
            text-sm
            placeholder:text-sm
          ">
        <input (input)="filterSearchItems($event)" class="w-10/12 focus:outline-none appearance-none text-lg" type="text"
          placeholder="{{ 'PLACEHOLDER.SEARCH' | translate }}" /><img src="assets/svg/search_input.svg" />
      </div>

      <div class="
            w-full
            md:w-1/2
            z-10
            md:z-auto
            py-5
            border-t
            border-t-deloitte_gray9
            bottom-0
            md:bottom-auto
            fixed
            md:static md:border-t-0
            bg-white
            md:bg-background
            text-sm
          ">
        <app-button (click)="deleteHistory()" text="DELETE_HISTORY" theme="delete">
          <img class="inline-block pr-4" src="assets/svg/trash_green.svg" />
        </app-button>
      </div>
    </div>

    <div *ngIf="searchData.length > 0">
      <div class="w-full bg-white text-black shadow rounded" *ngFor="let search_history of searchData; index as i">
        <div *ngIf="!searching()" class="w-full hidden md:block mt-8 p-4 border-b-2 border-b-deloitte_gray9">
          <span class="font-bold text-sm md:text-2xl">{{
            getFormatES(search_history.datetime)
            }}</span>
        </div>

        <div class="w-full relative border-b border-b-deloitte_gray9 text-sm"
          *ngFor="let history_item of search_history.data; index as j">
          <div class="w-full flex flex-row p-2 md:p-4">
            <a (click)="showRecord(history_item)" class="cursor-pointer ml-4 w-full">
              <ul class="
                    w-full
                    flex flex-col
                    md:flex-row
                    text-[0.625rem]
                    md:text-sm
                  ">
                <li class="w-full md:w-1/5 text-deloitte_gray9">
                  {{ getFormatES(search_history.datetime) }}
                </li>

                <li class="w-full md:w-4/5 flex flex-col">
                  <div class="flex flex-row flex-wrap">
                    <span class="mr-2 md:mr-6 float-left" *ngFor="let filter of getFilters(history_item.filters)">
                      {{ filter }}
                    </span>
                  </div>
                </li>
              </ul>
            </a>

            <span mat-button [matMenuTriggerFor]="menu" class="three-dots">&#xFE19;</span>
            <mat-menu class="text-white text-tiny" #menu="matMenu">
              <div mat-menu-item matTooltip="{{'TOOLTIP.EXPORT_LIMIT' | translate}}">
                <img class="inline-block pr-2" src="assets/imgs/exportar-pdf.png" />
                <button (click)="exportpdf(history_item)">{{
                  "BUTTON.EXPORTPDF" | translate
                  }}</button>
              </div>

              <div mat-menu-item matTooltip="{{'TOOLTIP.EXPORT_LIMIT' | translate}}">
                <img class="inline-block pr-2" src="assets/imgs/exportar-excel.png" />
                <button (click)="exportexcel(history_item)">{{
                  "BUTTON.EXPORTEXCEL" | translate
                  }}</button>
              </div>

              <div mat-menu-item>
                <img class="inline-block pr-2" src="assets/svg/trash_white.svg" />
                <button (click)="deleteElement(history_item)">{{
                  "BUTTON.DELETE" | translate
                  }}</button>
              </div>
            </mat-menu>

          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isSearchDataEmpty()" class=" flex justify-center mt-8">
      <h1 class="p-3 font-bold text-2xl md:text-3xl">
        {{ "LABEL.NO_HISTORY" | translate }}
      </h1>
    </div>
  </div>
</div>

<!-- Modal confirm delete -->
<app-modal #modal class="h-auto">
  <div class="px-12">
    <div class="text-2xl font-bold text-center text-black mb-4">
      {{ "MESSAGES.CONFIRM_DELETE_HISTORY" | uppercase | translate }}
    </div>
    <div class="
          text-sm
          font-bold
          text-center
          justify-start
          text-deloitte_black
          mb-14
        ">
      {{ "MESSAGES.PERM_DELETE" | uppercase | translate }}
    </div>
    <div class="grid grid-cols-2 gap-2 md:gap-6">
      <app-button theme="main_block" (click)="deleteAll()" text="YES"></app-button>
      <app-button theme="main_block" (click)="closeModal()" text="NO"></app-button>
    </div>
  </div>
</app-modal>
