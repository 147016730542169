import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ModalComponent } from 'src/app/core/components/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { RulesService } from 'src/app/shared/services/api/rules/rules.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

import {
  ActionParams,
  RuleActionsService,
} from 'src/app/shared/services/actions/rule-actions.service';
import { tapOnError } from 'src/app/shared/utils/rxjsUtils';
import { mergeMap, tap } from 'rxjs/operators';
import { RuleFormComponent } from 'src/app/core/components/forms/rule-form/rule-form.component';
import { Location as NgLocation } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-edit-rule',
  templateUrl: './edit-rule.component.html',
  styleUrls: ['./edit-rule.component.css'],
})
export class EditRuleComponent {
  @ViewChild("form") form: RuleFormComponent;
  @ViewChild('deleteModal') deleteModal: ModalComponent | undefined;
  @ViewChild('validationModal') validationModal: ModalComponent | undefined;

  actions: ActionParams;
  textId: string = null;

  data: any; // TODO: Type

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notif: NotificationService,
    private rulesService: RulesService,
    private t: TranslateService,
    private ruleActionsService: RuleActionsService,
    private location: NgLocation,
  ) {
    localStorage.removeItem("lastSearchQuery");

    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.textId = params['id'];
      this.load();
    });
  }

  load() {
    this.getActions();
    this.getRuleData();
  }

  getActions() {
    this.ruleActionsService.getUserActions(this.textId)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.actions = data;
      });
  }

  getRuleData() {
    this.rulesService
      .getRule(this.textId)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.data = data;
        this.resetForm();
      })
  }

  back() {
    this.location.back();
  }

  resetForm() {
    // Make sure to copy, so we are sure the data is not modified
    const data = JSON.parse(JSON.stringify(this.data));
    this.form.setData(data);
  }

  confirmDelete(): void {
    this.deleteModal?.open();
  }

  closeDeleteModal(): void {
    this.deleteModal?.close();
  }

  deleteRule(): void {
    this.closeDeleteModal();

    this.rulesService
      .deleteRule(this.textId)
      .pipe(
        untilDestroyed(this),
        tap(_ => this.onRuleDeleted()),
      )
      .subscribe();
  }

  submit() {
    if (!this.form.form.valid) {
      console.log('Invalid form', this.form.form.value);
      return;
    }

    if (this.actions.VALIDATE) {
      this.openValidationModal()
    } else {
      this.updateRule()
    }
  }

  openValidationModal() {
    this.validationModal.open();
  }

  closeValidationModal() {
    this.validationModal.close();
  }

  updateRule(): void {
    const data = this.form.getData();

    this.rulesService.editRule(this.textId, data)
      .pipe(
        untilDestroyed(this),
        tap(
          id => this.onRuleUpdated(id),
          err => this.onUpdateError(err)
        ),
      )
      .subscribe()
  }

  updateAndValidateRule(): void {
    const data = this.form.getData()

    this.rulesService.editRule(this.textId, data)
      .pipe(
        tapOnError(err => this.onUpdateError(err)),
        mergeMap((id) => this.rulesService.validate(id).pipe(
          tapOnError(err => this.onValidationError(err, id)),
        )),
        tap(id => this.onRuleUpdated(id))
      )
      .subscribe()
  }

  onRuleDeleted() {
    // TODO: i18n
    this.notif.success('NOTIF.DELETE_RULE_SUCCESS');
    this.router.navigate(['/']);
  }

  onRuleUpdated(id: string) {
    // TODO: i18n !
    this.notif.success("NOTIF.UPDATE_RULE_SUCCESS");
    this.router.navigateByUrl(this.rulesService.getPathToRule(id));
  }

  onUpdateError(e: unknown) {
    // TODO: i18n !
    // TODO: Improve error messaging
    console.log('Error updating rule', e);
    this.notif.error("NOTIF.CREATE_RULE_ERROR");
    this.deleteModal.close();
  }

  onValidationError(e: unknown, id: string) {
    console.log('Error validating rule', e);
    this.notif.warning("NOTIF.VALIDATE_UPDATE_ERROR");
    this.router.navigateByUrl(this.rulesService.getPathToRule(id));
  }
}
