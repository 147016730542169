import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, merge, Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { UpdateUserIn, UserAppData, UserCognitoData } from "src/app/shared/models/user_admin.model";
import { noErrorHandling, noErrorHandlingForStatus } from "src/app/shared/utils/RequestUtils";
import { CognitoUser } from "src/aws/services/cognito/cognito.model";
import { CognitoService } from "src/aws/services/cognito/cognito.service";


export type User = CognitoUser;


@Injectable({
    providedIn: 'root'
})
export class UsersService {
    
    constructor(
        private httpClient: HttpClient,
        private cognitoService: CognitoService,
    ) {

    }

    getUserAppData(user: string | User): Observable<UserAppData | null> {
        const id = typeof user == 'string' ? user : user.id;
        
        return this.httpClient.get<UserAppData>(`/admin/users/${id}`, {
            ...noErrorHandlingForStatus([404])
        })
                .pipe(
                    catchError(e => {
                        if (e.status == 404) {
                            // User not yet on DB
                            return of(null);
                        }

                        return throwError(e);
                    })
                )
    }

    saveUser(user: User, data: Omit<UserAppData, 'id'> & UserCognitoData) {
        const id = user.id;
        const body: UpdateUserIn = {
            ...data,
            id: id,
            username: user.Username,
            email: user.attributes.email,
        };

        return this.httpClient.put<{}>(`/admin/users/${id}`, {
            ...noErrorHandling()
        }).pipe(
            tap(_ => {
                const attributes = {
                    inApp: true,
                }
                this.cognitoService.updateUser(user, data.groups, attributes);
            })
        )
    }

    getAllUsers(): Observable<User[]> {
        return this.cognitoService.getUsers();
    }

    getUserDetail(user: string): Observable<{user: User, appData: UserAppData}> {
        return combineLatest([
            this.cognitoService.getUser(user),
            this.getUserAppData(user)
        ]).pipe(
            map(([user, appData]) => {
                return {
                    user,
                    appData,
                }
            })
        )
    }
}