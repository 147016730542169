import { Component } from '@angular/core';
import { Location as ngLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.scss']
})
export class ErrorViewComponent  {
  errorInfo:any={
    errorMessage:"",
    errorStatus:0

  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: ngLocation,
  ) {

  }

  ngOnInit() {
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(data => {
        this.errorInfo = data.error;
      })
    ).subscribe()    
  }

  redirectToHome(){
    this.router.navigate([""]);
  }

  back(){
    this.location.back();
  }

}
