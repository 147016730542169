<div [formGroup]="form">
  <!--evolutions-->
  <div>
    <div class="align-center">
      <span class="label">{{ "LABEL.HISTORICAL" | translate }}</span>
      <span class="red" *ngIf="requiredField && evolutions.hasError('required') ">*</span>
    </div>
    <div class="flex">
      <div class="w-full input-form-historic align-center mt-1">
        <mat-form-field class="form-field" [ngClass]="{ disabled: evolutionLoading }">
          <input
            autocomplete="off"
            matInput type="text"
            placeholder="Busca un texto por su titulo para añadirlo"
            [(ngModel)]="inputEvolutions"
            [ngModelOptions]="{standalone: true}"
            (input)="searchEvolutions($event)"
          />

          <mat-select #historic>
            <mat-option *ngFor="let option of evolutionsOptions" (click)="addToEvolutions(option)">
              {{ getInfo(option) }}
            </mat-option>
          </mat-select>

          <div *ngIf="evolutionLoading" class="evo-loading">
            <app-loader [diameter]="40"></app-loader>
          </div>
        </mat-form-field>
      </div>

      <div class="filters-icon" class="align-center">
        <img class="filters-icon-img"
          matTooltip="{{ 'TOOLTIP.EVOLUTION_FILTERS' | translate }}"
          matTooltipClass="tooltip-width"
          src="../../../assets/imgs/filter.png"
          (click)="showEvolutionFilterModal()"
        />
      </div>
    </div>
  </div>

  <!-- historic list -->
  <div class="input-margin-top">
    <span class="label">{{ "LABEL.EVOLUTIONS" | translate }}</span>
  </div>
  <div class="no-evo-text" *ngIf="evolutions.value.length === 0">
    <h2>{{ "LABEL.NO_EVOLUTIONS" | translate }}</h2>
  </div>
  <div *ngIf="evolutions.value.length !== 0">
    <div class="evo-list" *ngFor="let evolution of evolutions.value; let index = index">
      <div class="space-between">
        <div class="evo-title">
          <li class="break-all">
            {{ getEvolutionInfo(evolution) }}
          </li>
        </div>
        <div *ngIf="withState" class="evo-state">
          <select [(ngModel)]="evolution.state" [ngModelOptions]="{ standalone: true }"
            (change)="changeState(index,$event)">
            <option *ngFor="let item of processingStatus" [value]="item.id">
              {{ item.name | translate }}
            </option>
          </select>
        </div>

        <button (click)="removeOthersItem(index)">
          <img src="assets/svg/remove.svg" />
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal [big]="true" #evolutionFilter class="h-auto">
  <div>
    <div (click)="closeEvolutionFilterModal()" class="close-icon absolute top-3 right-3">
      <img class="scale-75" src="assets/svg/Icon-Close@2x.svg" />
    </div>
    <div class="text-2xl font-bold text-center text-black">
      {{ "MESSAGES.EVOLUTION_FILTER" | uppercase | translate }}
    </div>

    <div class="space-between">
      <!-- phase -->
      <div class="filter">
        <div class="input-margin-top">
          <span class="label">{{ "LABEL.TYPE_RULE" | translate }}</span>
        </div>
        <div>
          <select [(ngModel)]="filterModalPhase" name="phases-modal" id="phases-modal">
            <option *ngFor="let option of phases" value="{{ option.id }}">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- area -->
      <div class="filter">
        <div class="input-margin-top">
          <span class="label">{{ "LABEL.AREA" | translate }}</span>
        </div>
        <div>
          <select [(ngModel)]="filterModalArea" name="areas-modal" id="areas-modal">
            <option *ngFor="let option of areas" value="{{ option.id }}">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- cluster -->
      <div class="filter">
        <div class="input-margin-top">
          <span class="label">{{ "LABEL.TYPE_GEOSCOPE" | translate }}</span>
        </div>
        <div>
          <select [(ngModel)]="filterModalCluster" name="clusters-modal" id="clusters-modal">
            <option *ngFor="let option of clusters" value="{{ option.id }}">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </div>


    <div>
      <div class="input-margin-top">
        <span class="label">{{ "LABEL.TITLE" | translate }}</span>
      </div>

      <div class="w-full flex flex-wrap lg:flex-nowrap gap-1 justify-between">
        <div class="w-full filter-title">
          <input class="input-form" [(ngModel)]="filterModalTitle" type="text" />
        </div>

        <div class="options">
          <app-button theme="main_block" text="RESET" (click)="resetFilters()"></app-button>
          <app-button theme="main_black" text="APPLY_FILTERS" (click)="searchEvolutionsModal()"></app-button>
        </div>
      </div>
    </div>

    <div *ngIf="!evolutionModalLoading" class="modal-list">
      <div *ngFor="let option of evolutionsOptionsModal  | paginate
      : {
          itemsPerPage: itemsPerPage,
          currentPage: currentPage,
          totalItems: total
        }
      ">
        <div class="modal-list-elem" (click)="addToEvolutionsModal(option)">
          {{ getInfo(option) }}
        </div>
      </div>

      <div class="w-full block text-center mt-8 mb-8">
        <pagination-controls
          (pageChange)="changePage($event)"
          [directionLinks]="true"
          [responsive]="true"
          previousLabel="PREV" nextLabel="NEXT"
          [autoHide]="true"
        ></pagination-controls>
      </div>
    </div>
    <div *ngIf="evolutionModalLoading" class="modal-list center w-full">
      <app-loader></app-loader>
    </div>
  </div>
</app-modal>
