/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "src/app/core/components/modal/modal.component";
import { RulesService } from "src/app/shared/services/api/rules/rules.service";
import { NotificationService } from "src/app/shared/services/notification/notification.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActionParams, RuleActionsService } from "src/app/shared/services/actions/rule-actions.service";
import { ProcessingStatus, Impacts, ProcessingState, Impact, ExportResult } from 'src/app/shared/models/other-data.model';
import { S3Service } from "src/aws/services/s3Service/s3.service";
import { ExportExcelService } from "src/app/shared/services/api/export/excel/export-excel.service";
import { ExportPdfService } from "src/app/shared/services/api/export/pdf/export-pdf.service";
import { UserActionsService } from "src/app/shared/services/actions/user-actions.service";
import { RuleDetail } from "src/app/shared/models/rules.model";
import { tapOnError } from "src/app/shared/utils/rxjsUtils";
import { tap } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-show-rule",
  templateUrl: "./show-rule.component.html",
  styleUrls: ["./show-rule.component.css"],
})
export class ShowRuleComponent {
  @ViewChild("validationModal") validationModal: ModalComponent | undefined;
  @ViewChild("modal") modal: ModalComponent | undefined;
  idRule: string;
  loadingData = false;
  data: RuleDetail;
  linkSection: any[] = [];
  languages: any[] = [];
  actual_lang: string;
  processingStates = ProcessingStatus;
  impacts = Impacts;
  actions: ActionParams;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private exportExcelService: ExportExcelService,
    private exportPdfService: ExportPdfService,
    private rulesService: RulesService,
    private notif: NotificationService,
    private translateService: TranslateService,
    private ruleActionsService: RuleActionsService,
    private userActionsService: UserActionsService,
    private s3Service: S3Service
  ) {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params: ParamMap) => {
      this.idRule = params.get("id");
    });

    this.actual_lang = this.router.getCurrentNavigation()?.extras?.state?.lang;
    this.actions = {}

    this.ruleActionsService.getUserActions(this.idRule)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.actions = { ...data, ...this.actions };
      });

    this.userActionsService.canAccess("alert")
      .pipe(untilDestroyed(this))
      .subscribe((canSendAlert) => {
        this.actions["SEND_ALERT"] = canSendAlert;
      })

    this.rulesService.getRule(this.idRule)
      .pipe(untilDestroyed(this))
      .subscribe((data: any) => {
        if (!this.actual_lang) {
          this.actual_lang = data.info.defaultLang.toLowerCase();
        }

        this.linkSection = Object.keys(
          data?.info?.lang_info[this.actual_lang]
        ).filter(
          (elem) =>
            elem === "link" ||
            elem === "document" ||
            elem === "other_links" ||
            elem === "other_documents"
        );
        this.data = data;

        this.languages = Object.keys(data?.info?.lang_info);
      });
  }

  changeLang(lang: string) {
    this.actual_lang = lang;
  }

  currentLang() {
    return this.translateService.currentLang;
  }

  getImpact(impact: number) {
    return this.impacts.find((e: Impact) => e.id === impact ? e : '')?.name;
  }

  getRequiredAction() {
    let requiredAction = "Sí";
      if (!this.data?.info?.requiredAction) {
        requiredAction = "No"
      }
    return requiredAction;
  }

  getState(state: number) {
    return this.processingStates.find((e: ProcessingState) => e.id === Number(state) ? e : '')?.name;
  }

  exportpdf(): void {
    this.exportPdfService.exportToPdf(this.idRule)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => {
          console.log("Error exporting text to pdf", err);
          this.notif.error("NOTIF.GENERAL_ERROR");
        }),
        tap(url => {
          this.s3Service.downloadFile(url);
        })
      )
      .subscribe();
  }

  confirmDelete(): void {
    this.modal?.open();
  }

  deleteRule(): void {
    this.closeModal();
    this.rulesService.deleteRule(this.idRule)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notif.success("NOTIF.DELETE_SUCCESS");
        this.router.navigate(["/"]);
      });
  }

  exportexcel(): void {
    this.exportExcelService.exportToExcel(this.idRule)
      .pipe(
        untilDestroyed(this),
        tapOnError(err => {
          console.log("Error exporting text to excel", err);
          this.notif.error("NOTIF.GENERAL_ERROR");
        }),
        tap((res: ExportResult) => {
          const docs: string[] = res.documents.map(elem => elem.link);
          docs.push(res.url);
          this.s3Service.downloadDocuments(docs);
        })
      )
      .subscribe();
  }

  editRule(): void {
    // edit text in same language as selected
    this.router.navigate(["rules", "edit-rule", this.idRule], { state: { lang: this.actual_lang } });
  }

  sendAlert(): void {
    // register alert with text title and description
    this.router.navigate(["alerts", "register-alert"], { state: { rule: this.data, lang: this.actual_lang } });
  }

  closeModal(): void {
    this.modal?.close();
  }

  openValidationModal(): void {
    this.validationModal.open();
  }

  closeValidationModal(): void {
    this.validationModal.close();
  }

  refreshAccordion(id: string): void {
    const info: any = document.getElementById("rd1");
    const desc: any = document.getElementById("rd2");
    const links: any = document.getElementById("rd3");
    switch (id) {
      case "rd1":
        desc.checked = false;
        links.checked = false;
        break;
      case "rd2":
        info.checked = false;
        links.checked = false;
        break;
      case "rd3":
        info.checked = false;
        desc.checked = false;
        break;
    }
  }

  validateRule() {
    this.rulesService.validate(String(this.idRule)).pipe(untilDestroyed(this))
      .subscribe((_) => {

        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
          this.notif.success("NOTIF.VALIDATE_SUCCESS");
        });
      }
      )
  }

  showRule(id: any): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(["rules", "show-rule", id]);
  }

  downloadDocument(docElement: any) {
    this.s3Service.downloadFile(docElement.link);
  }

  getImpactClass(impact: number): string {
    return this.getImpact(impact).split('.')[1].toLowerCase();
  }

  getRequiredActionClass(): string {
    let requiredActionClass = "yes";
    if (!this.data?.info?.requiredAction) {
      requiredActionClass = "no";
    }
    return requiredActionClass;
  }

  getEvolutionTitle(evo): string {
    let lang = this.actual_lang ? this.actual_lang.toLowerCase() : this.currentLang();
    lang = evo.title[lang] ? lang : evo.defaultLang;
    return evo.title[lang];
  }
}
