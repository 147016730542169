import { Component, Inject, Input } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
export interface Notification {
  message: string;
  level: "Success" | "Error" | "Warning";
}

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent {
  @Input() message!: string;

  constructor(
    public snackBarRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: Notification
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }
}
