import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-breadcrum",
  templateUrl: "./breadcrum.component.html",
  styleUrls: ["./breadcrum.component.css"],
})
export class BreadcrumComponent implements OnInit {
  @Input()
  name!: string;

  constructor(private router: Router) {
    this.pathnames = this.router.url
      .toLowerCase()
      .replace(/\/|\?|&|=|\./g, ",")
      .split(",");
  }

  paths: string[] = [];
  pathnames: string[] = [];

  ngOnInit(): void {
    this.pathnames.map(async (path: string) => {
      if (path !== "" && path !== "rules" && path != "show-record") {
        if (!/\d/.test(path)) {
          const path_translated = `MENU_OPTIONS.${path.split(";")[0].toUpperCase()}`;
          this.paths.push(path_translated);
        }
      }
    });
    this.name && this.paths.push(this.name);
  }
}
