<div class="container flex-col md:flex-row flex items-stretch relative main-div" >
  <div [ngClass]="expandAside ? '' : '-left-full'" class="
        w-full
        md:w-3/12
        absolute
        top-0
        bottom-0
        left-0
        block
        md:flex-auto
        bg-white
        z-20
      ">
    <!-- buscador -->
    <div class="px-3 relative overflow-auto scrollbar h-full shadow-lg pb-6">
      <app-button class="absolute top-0 right-0" theme="toggle" (click)="toggle()">
        <img src="assets/svg/Icon-Close@2x.svg" />
      </app-button>
      <p class="title text-2xl mt-2 text-black">
        {{ "SIDEFILTER.FILTER_ALERTS" | translate }}
      </p>

      <app-search-alerts (formChanged)="formChanged($event)" [reset]="resetValues"></app-search-alerts>

      <div class="flex flex-wrap mt-5 md:gap-2" style="justify-content: space-around;">
        <app-button theme="main_black" (click)="!searchAlertsForm?.dirty  ? '' : search()" text="APPLY_FILTERS"
          [disabled]="!searchAlertsForm?.dirty"></app-button>
        <app-button theme="main_block_strech" (click)="!searchAlertsForm?.dirty ? '' : resetFilter()"
          text="RESET_FILTERS" [disabled]="!searchAlertsForm?.dirty"></app-button>
      </div>

    </div>
  </div>
  <div [ngClass]="expandAside ? 'md:w-3/12 section_title' : 'md:w-1/12'" class="block md:flex-auto mt-8 text-center">
    <app-button theme="toggle" (click)="toggle()"><img src="assets/imgs/search.png" /></app-button>
  </div>

  <div [ngClass]="expandAside ? 'md:w-9/12 hidden' : 'md:w-11/12'" class="px-4 md:block md:flex-auto">
    <app-breadcrum></app-breadcrum>

    <div class="w-full my-4">
      <span class="flex w-full md:w-auto pb-3 mb-2 md:pb-0 md:px-4 text-black text-2xl font-bold">
        {{ "MENU_OPTIONS.ALERT-RECORD" | translate }}</span>
    </div>
    <!-- lista de alertas / comunicaciones -->

    <div *ngIf="!alertData" class="flex justify-center mt-8">
      <app-loader></app-loader>
    </div>

    <div *ngIf="alertData" class="overflow-auto">
      <app-alert *ngFor="
            let alert of alertData
              | paginate: { itemsPerPage: itemsPerPage,
                currentPage: currentPage,
                totalItems: total };
            let i = index
          " [alert]="alert" [index]="i"></app-alert>
    </div>
    <div *ngIf="alertData && alertData.length === 0" class="flex justify-center mt-8">
      <h1 class="p-3 font-bold text-2xl md:text-3xl">
        {{ "LABEL.NO_ALERTS" | translate }}
      </h1>
    </div>
    <!-- paginacion -->
    <div *ngIf="alertData" class="w-full block text-center mt-8">
      <pagination-controls (pageChange)="changePage($event)" [directionLinks]="true" [responsive]="true"
        previousLabel="PREV" nextLabel="NEXT" [autoHide]="true"></pagination-controls>
    </div>
  </div>
</div>
