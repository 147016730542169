import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { debounce } from 'lodash';
import { GetMasterDataPipe } from 'src/app/shared/pipes/get-masterdata/get-masterdata.pipe';
import { RulesService } from 'src/app/shared/services/api/rules/rules.service';
import { ModalComponent } from '../modal/modal.component';
import { ProcessingStatus } from 'src/app/shared/models/other-data.model';
import { MasterDataTypes } from 'src/app/shared/models/master-data.model';
import { MatSelect as MatSelect } from '@angular/material/select';
import { EvolutionTitleInfo, Rule } from 'src/app/shared/models/rules.model';

// TODO: Make Reactive
// TODO: No use FormArray
@UntilDestroy()
@Component({
  selector: 'app-evolution-filter',
  templateUrl: './evolution-filter.component.html',
  styleUrls: ['./evolution-filter.component.scss']
})
export class EvolutionFilterComponent implements OnInit {

  /**
   * Evolution filter component has both input and modal to search texts and display them in a list. You can change the state of the text with a optional selector
   * Params:
   * [evolutionsLoaded] load evolutions if you want the component to have evolutions when it appears
   * [textLang] specify lang to display titles
   * [requiredField] add a red * span and a validator if evolution are required in the form
   * [withState] if it is false state is not displayed and you cannot change it
   * (valueChanged) emit an event with the value added
   * (elementRemoved) emit an event with the index of the value removed
   * (stateChanged) emit an event if the state of an evolution change
   */
  processingStatus = ProcessingStatus;

  // TODO: Type !!
  private _evolutionsLoaded = [];

  get evolutionsLoaded(): any[] {
    return this._evolutionsLoaded;
  }

  @Input() set evolutionsLoaded(v: any[]) {
    this._evolutionsLoaded = v || [];

    while (this.evolutions.value.length) {
      this.evolutions.removeAt(0);
    }

    this.evolutionsLoaded.forEach((evolutionBody) => {
      const evolutionItem = this.formBuilder.group(evolutionBody)
      this.evolutions.push(evolutionItem)
    })
  }

  @Input() textLang: string;
  @Input() requiredField: boolean = false;
  @Input() withState: boolean = true;
  @Output() valueChanged = new EventEmitter();
  @Output() elementRemoved = new EventEmitter();
  @Output() stateChanged = new EventEmitter();

  // form
  form!: UntypedFormGroup;
  // modal
  @ViewChild('evolutionFilter') evolutionFilterModal:
    | ModalComponent
    | undefined;
  @ViewChild('historic') select: | MatSelect
    | undefined;


  // modal selectors
  areas = this.getMasterData.transform(MasterDataTypes.AREA).filter(elem => elem.active);
  clusters = this.getMasterData.transform(MasterDataTypes.CLUSTER);
  phases = this.getMasterData.transform(MasterDataTypes.PHASE);

  // input
  inputEvolutions: string = "";
  evolutionsOptions: Rule[] = [];
  evolutionLoading = false;

  // modal filters
  filterModalPhase: string = "";
  filterModalArea: string = "";
  filterModalCluster: string = "";
  filterModalTitle: string = "";
  evolutionsOptionsModal: any = [];

  evolutionModalLoading = false

  // pagination
  totalPage = 0;
  itemsPerPage = 10;
  currentPage = 1;
  total = 0;

  constructor(
    private rulesService: RulesService,
    private t: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private getMasterData: GetMasterDataPipe,

  ) {
    this.form = this.formBuilder.group({
      evolutions: this.formBuilder.array([])
    })
    this.searchEvolutions = debounce(this.searchEvolutions, 1000)
  }

  ngOnInit() {
    this.requiredField ? this.evolutions.addValidators(Validators.required) : '';
  }

  get evolutions() {
    return this.form.controls['evolutions'] as UntypedFormArray;
  }

  removeOthersItem(index: number) {
    this.evolutions.removeAt(index)
    this.elementRemoved.emit(index)
  }

  showEvolutionFilterModal(): void {
    this.evolutionFilterModal?.open();
  }

  closeEvolutionFilterModal(): void {
    this.filterModalPhase = "";
    this.filterModalArea = "";
    this.filterModalCluster = "";
    this.filterModalTitle = "";
    this.evolutionsOptionsModal = [];
    this.evolutionFilterModal?.close();
  }

  addToEvolutionsModal(item) {
    const evolutionBody = {
      id: item?.id,
      title: this.getTextTitles(item),
      state: Number(item?.info?.state),
      publication_date: item?.publication_date,
      defaultLang: item?.info?.defaultLang
    }
    if (!this.evolutions.value.find(elem => elem.id === evolutionBody.id)) {
      const evolutionItem = this.formBuilder.group(evolutionBody)
      this.evolutions.push(evolutionItem);
      this.valueChanged.emit(evolutionBody);
    }
    this.evolutionFilterModal?.close();
  }

  searchEvolutions(event: any) {
    this.evolutionLoading = true
    const body = {
      query: { title: event.target.value },
      page: 1, size: 100,
      preventSave: true
    }
    this.rulesService.searchRules(body)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((historic) => {
        this.evolutionLoading = false
        this.evolutionsOptions = historic.items
        this.totalPage = historic.totalPage;
        this.total = historic.total;
        this.select.toggle()
      },
        () => { },// ERROR this.historicLoading=false
        () => {
          this.evolutionLoading = false
        }// COMPLETE this.historicLoading=false
      )
  }

  searchEvolutionsModal() {
    let query = {};

    this.filterModalTitle !== "" ? query["title"] = this.filterModalTitle : ''
    this.filterModalArea !== "" ? query["areas"] = [this.filterModalArea] : ''
    this.filterModalCluster !== "" ? query["geo_scopes"] = [this.filterModalCluster] : ''
    this.filterModalPhase !== "" ? query["phases"] = [this.filterModalPhase] : ''
    this.evolutionModalLoading = true
    const body = {
      query,
      page: 1, size: 100,
      preventSave: true
    }
    this.rulesService.searchRules(body)
      .pipe(untilDestroyed(this))
      .subscribe((historic) => {
        this.evolutionModalLoading = false
        this.evolutionsOptionsModal = historic.items
      },

        () => { },// ERROR this.historicLoading=false
        () => {
          this.evolutionModalLoading = false
        }// COMPLETE this.historicLoading=false
      )
  }

  // add to evolution
  addToEvolutions(item) {
    const evolutionBody = {
      id: item?.id,
      title: this.getTextTitles(item),
      state: Number(item?.info?.state),
      publication_date: item?.publication_date,
      defaultLang: item?.info?.defaultLang
    };
    if (!this.evolutions.value.find((elem) => elem.id === evolutionBody.id)) {
      const evolutionItem = this.formBuilder.group(evolutionBody);
      this.evolutions.push(evolutionItem);
      this.valueChanged.emit(evolutionBody);
    }
  }

  changeState(index, event) {
    this.evolutions.at(index).get('state').patchValue(Number(event.target.value))
    this.stateChanged.emit({ index, value: Number(event.target.value) })
  }

  resetFilters() {
    this.filterModalPhase = "";
    this.filterModalArea = "";
    this.filterModalCluster = "";
    this.filterModalTitle = "";
  }

  private getTextTitles(item): EvolutionTitleInfo {
    const langs = Object.keys(item?.info?.lang_info);
    const res = {};
    langs.forEach(lang => {
      const textLang = lang.toLowerCase();
      res[textLang] = item?.info?.lang_info?.[textLang]?.title
    });
    return res;
  }

  private getTitle(item): string {
    const titles = this.getTextTitles(item);
    let lang = this.textLang ? this.textLang.toLowerCase() : this.t.currentLang?.toLowerCase();
    if (!item?.info?.lang_info[lang]) lang = item?.info?.defaultLang?.toLowerCase();
    return titles?.[lang];
  }

  private getEvolutionTitle(evo): string {
    let lang = this.textLang ? this.textLang.toLowerCase() : this.t.currentLang?.toLowerCase();
    lang = evo.title[lang] ? lang : evo.defaultLang;
    return evo.title[lang];
  }

  getInfo(item): string {
    return `${item.id} - ${this.getTitle(item)}`;
  }

  getEvolutionInfo(evo): string {
    return `${evo.id} - ${this.getEvolutionTitle(evo)}`;
  }

  changePage(event: any) {
    this.currentPage = event;
  }
}
