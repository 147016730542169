<div *ngIf="!favouritesData" class="loader">
  <app-loader></app-loader>
</div>
<div *ngIf="favouritesData" class="container flex-col md:flex-row flex items-stretch">
  <div class="w-full pb-20 md:pb-8 md:px-8 block md:flex-auto">
    <app-breadcrum></app-breadcrum>

    <div class="w-full flex flex-col md:flex-row justify-between mt-8 mb-3">
      <div class="
            flex
            bg-white
            justify-between
            w-full
            md:mb-0 md:w-6/12
            p-4
            border
            border-t-input_search
            border-r-input_search
            border-l-input_search
            border-b-deloitte
            text-sm
            placeholder:text-sm
            search_input
          ">
        <input (input)="filterByName($event)" class="w-10/12 focus:outline-none appearance-none text-lg"
          type="text" placeholder="{{ 'PLACEHOLDER.SEARCH_BY_NAME' | translate }}" />
        <img src="assets/svg/search_input.svg" />
      </div>

      <div class="
            w-full
            md:w-1/2
            z-10
            md:z-auto
            py-5
            fixed
            md:static
            border-t
            border-t-deloitte_gray9
            md:border-t-0
            bottom-0
            md:bottom-auto
            bg-white
            md:bg-background
          ">
        <app-button class="w-full md:right-0 text-deloitte text-sm" (click)="deleteFavouriteHistory()"
          text="DELETEALLFAVORITES" theme="delete">
          <img class="inline-block pr-4" src="assets/svg/trash_green.svg" />
        </app-button>
      </div>
    </div>

    <div class="panel_item w-full inline-block bg-white mt-8">

      <head class="
            w-full
            inline-block
            font-bold
            p-4
            mb-4
            border-b-2
            border-b-deloitte_gray9
            text-black text-sm
            md:text-2xl
          ">
        {{
        "MESSAGES.SEARCH_FAVOURITES" | uppercase | translate
        }}
      </head>

      <div *ngIf="favouritesData?.length === 0" class=" flex justify-center">
        <h1 class="p-3 font-bold text-2xl md:text-3xl">
          {{ "LABEL.NO_HISTORY" | translate }}
        </h1>
      </div>

      <!-- Filas de la lista de busqueda -->
      <div *ngIf="favouritesData?.length > 0" style="padding: 50px 50px;">
        <div class="favourite-card margin-bottom-card" *ngFor="let favourite of favouritesData; index as i">
          <div class="width-distance date">
            {{favourite?.datetime ? getFormatES(favourite?.datetime) : '25 noviembre 2021'}}

          </div>
          <div style="display: flex; justify-content: space-between; width: 100%;">

            <div style="width: 100%;" (click)="showFavourite(favourite)">
              <div class="title-style margin-bottom-title ">
                {{favourite?.title}}
              </div>
              <div style="display: flex; flex-wrap: wrap; max-width: max-content;">

                <span class="margin-right-card" *ngFor="let filter of getFilters(favourite?.filters)">
                  {{filter}}
                </span>
              </div>

            </div>
            <div>
              <span mat-button [matMenuTriggerFor]="menu" class="three-dots">&#xFE19;</span>
              <mat-menu class="text-white text-tiny" #menu="matMenu">
                <div mat-menu-item matTooltip="{{'TOOLTIP.EXPORT_LIMIT' | translate}}">
                  <img class="inline-block pr-2" src="assets/imgs/exportar-pdf.png" />
                  <button (click)="exportpdf(favourite)">{{
                    "BUTTON.EXPORTPDF" | translate
                    }}</button>
                </div>

                <div mat-menu-item matTooltip="{{'TOOLTIP.EXPORT_LIMIT' | translate}}">
                  <img class="inline-block pr-2" src="assets/imgs/exportar-excel.png" />
                  <button (click)="exportexcel(favourite)">{{
                    "BUTTON.EXPORTEXCEL" | translate
                    }}</button>
                </div>

                <div mat-menu-item>
                  <img class="inline-block pr-2" src="assets/svg/trash_white.svg" />
                  <button (click)="deleteElement(favourite?.id)">{{
                    "LABEL.DELETE_FAVOURITE" | translate
                    }}</button>
                </div>
              </mat-menu>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirm delete -->
<app-modal #modal class="h-auto">
  <div class="px-12">
    <div class="text-2xl font-bold text-center text-black mb-4">
      {{ "MESSAGES.CONFIRM_DELETE_HISTORY" | uppercase | translate }}
    </div>
    <div class="
          text-sm
          font-bold
          text-center
          justify-start
          text-deloitte_black
          mb-14
        ">
      {{ "MESSAGES.PERM_DELETE" | uppercase | translate }}
    </div>
    <div class="grid grid-cols-2 gap-2 md:gap-6">
      <app-button theme="main_block" (click)="deleteAll()" text="YES"></app-button>
      <app-button theme="main_block" (click)="closeModal()" text="NO"></app-button>
    </div>
  </div>
</app-modal>
