<form [formGroup]="userForm">
  <div>
    <div class="section">
      <div class="aside"></div>

      <div class="content">
        <span class="font-medium mb-1" for="role">
          {{"label.role" | uppercase | translate}}
        </span>
        <span class="red" *ngIf="userForm.get('role').hasError('required')"
        >*</span
      >
        <div class="masterdata-radio">
          <div>
            <div *ngFor="let role of options.roles;">
              <input [value]="role['id']" name="role" type="radio" formControlName="role" />
              <span>{{role["name"] | translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="aside"></div>
      <div class="content name-group">
        <div>
          <span class="font-medium" for="name">
            {{"label.name" | uppercase | translate}}
          </span>
        <div class="w-full md:w-9/12 mt-2 text-m">
          {{ userForm.get('name').value}}
        </div>
      </div>

        <div>
          <span class="font-medium" for="surname">
            {{"label.surname" | uppercase | translate}}
          </span>
          <div class="w-full md:w-9/12 mt-2 text-m">
            {{ userForm.get('surname').value}}
          </div>
          </div>
      </div>
    </div>

    <div class="section">
      <div class="aside"></div>

      <div class="content">
        <span class="font-medium" for="email">
          {{"label.email" | uppercase | translate}}
        </span>
        <div class="w-full md:w-9/12 mt-2">
          <div class="text-m">{{ userForm.get('email').value}}</div>
        </div>
         </div>
    </div>

    <div class="section">
      <div class="aside"></div>
      <div class="content">
        <div>
          <span class="font-medium" for="company">
            {{"label.company" | uppercase | translate}}
          </span><span class="red" *ngIf="userForm.get('company').hasError('required')"
          >*</span
        >
        <div class="flex justify-center">
          <app-loader *ngIf="loadingCompany" diameter="30"></app-loader>
        </div>

          <div *ngIf="!loadingCompany">
            <select name="company" id="company" formControlName="company">
              <option *ngFor="let option of options.companies" [value]="option['id']">{{option.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

<div class="section">
  <div class="aside"></div>
  <div class="content">
    <span class="font-medium" for="areas">
      {{"label.areas" | uppercase | translate}}
    </span >
    <span class="red" *ngIf="userForm.get('areas').hasError('required')"
      >*</span
    >
    <app-multiselect
      [showAll]="false"
      [items]="options.areas"
      (valueChanged)="userForm.get('areas').setValue($event)"
      [value]="userForm.get('areas').value"
    >
    </app-multiselect></div></div>


    <div class="section">
      <div class="aside"></div>
      <div class="content">
      <span class="font-medium"  for="fields">
        {{"label.fields" | uppercase | translate}}
      </span>
      <span class="red" *ngIf="userForm.get('fields').hasError('required') || userForm.hasError('fieldsEmpty')"
        >*</span
      >

    <app-multiselect
      [showAll]="false"
      [attr.disabled]="userForm.get('fields').disabled || null"
      [items]="options.fields | async"
      (valueChanged)="userForm.get('fields').setValue($event)"
      [value]="userForm.get('fields').value"
    >
    </app-multiselect>
  </div></div>

  <div class="section">
    <div class="aside"></div>

    <div class="content">
      <span class="font-medium mb-1" for="role">
        {{"label.alerts" | uppercase | translate}}
      </span>
      <span class="red" *ngIf="userForm.get('receiveAlerts').hasError('required')"
      >*</span
    >
      <div class="masterdata-radio">
        <div>
          <div>
            <input [value]="true" name="receiveAlerts" type="radio" formControlName="receiveAlerts" />
          <span>{{"BUTTON.YES" | translate}}</span>
        </div>

          <div>
            <input [value]="false" name="receiveAlerts" type="radio" formControlName="receiveAlerts" />
          <span>{{"BUTTON.NO" | translate}}</span>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="aside"></div>

    <div class="content">
      <span class="font-medium mb-1" for="role">
        {{"LABEL.NEWSLETTER" | uppercase | translate}}
      </span>
      <span class="red" *ngIf="userForm.get('emailNewsletter').hasError('required')"
      >*</span
    >
      <div class="masterdata-radio">
        <div>
          <div>
            <input [value]="true" name="emailNewsletter" type="radio" formControlName="emailNewsletter" />
          <span>{{"BUTTON.YES" | translate}}</span>
        </div>

          <div>
            <input [value]="false" name="emailNewsletter" type="radio" formControlName="emailNewsletter" />
          <span>{{"BUTTON.NO" | translate}}</span>
          </div>

        </div>
      </div>
    </div>
  </div>

  </div>

</form>

