<!-- <div class="lds-roller">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div> -->
<mat-spinner [diameter]="diameter" [color]="color"></mat-spinner>
