import { Pipe, PipeTransform } from "@angular/core";
import { MasterDataEntries, MasterDataPattern } from "../../models/master-data.model";
import { MasterDataService } from "../../services/master-data/master-data.service";

@Pipe({
  name: "getMasterDataElement",
})
export class GetMasterDataElementPipe implements PipeTransform {
  constructor(private masterDataService: MasterDataService) { };

  transform(value: string, key1: keyof MasterDataEntries, lang: string): any {
    if (!this.masterDataService.isLoaded()) {
      return null;
    }
    return this.getMasterData(key1, lang)?.find(
      (elem: { id: string; name: string; active: boolean }) => elem.id == value
    ).name;
  }

  getMasterData = (key: keyof MasterDataEntries, lang: string): any => {
    return this.masterDataService.getData(key)?.map((e: MasterDataPattern) => {
      return {
        id: e?.id,
        name: e?.i18n[lang]?.name,
        active: e.active
      };
    });
  };
}
