<nav class="bg-grey-light rounded font-sans w-full pt-8 hidden md:block">
  <ol class="list-reset flex text-grey-dark">
    <li
      *ngFor="let path of paths; let i = index"
      class="text-sm leading-5"
      [ngClass]="{ 'font-bold truncate max-width': i === paths.length - 1 }"
    >
      <span class="truncate" [ngClass]="{ block: i === paths.length - 1 }"
        >{{ path | translate
        }}<img
          *ngIf="i !== paths.length - 1"
          class="inline-block mx-4"
          src="assets/svg/breadcrum.svg"
      /></span>
    </li>
  </ol>
</nav>
