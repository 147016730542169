import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { MasterData, MasterDataEntries, MasterDataFile, MasterDataRelation, MasterDataTypesOut, MasterDataValuesList, MasterDataValuesOut } from 'src/app/shared/models/master-data.model';
import { noErrorHandling, noErrorHandlingForStatus } from 'src/app/shared/utils/RequestUtils';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root'
})
export class MasterdataManagementService {

  constructor(private http: HttpClient, private mdService: MasterDataService) { }

  updateMDFile(): Observable<Object> {
    return this.http.post('/admin/master-data-json', {}, { ...noErrorHandling() });
  }

  getMasterDataList(): Observable<(keyof MasterDataEntries)[]> {
    return this.http.get<MasterDataTypesOut>('/admin/master-data')
      .pipe(map(res => res.types));
  }

  getElements(mdType: keyof MasterDataEntries): Observable<MasterDataValuesOut[]> {
    return this.http.get<MasterDataValuesList>(`/admin/master-data/${mdType}/data`)
      .pipe(map(res => res.values));
  }

  addElement(mdType: keyof MasterDataEntries, body: MasterData): Observable<string> {
    return this.http.post<{ id: string }>(`/admin/master-data/${mdType}/data`, body, { ...noErrorHandling() })
      .pipe(map(res => res.id));
  }

  updateActive(mdType: keyof MasterDataEntries, mdId: string, active: boolean): Observable<any> {
    return this.http.put(`/admin/master-data/${mdType}/data/${mdId}/active`, { active: active }, { ...noErrorHandling() });
  }

  updateElement(mdType: keyof MasterDataEntries, mdId: string, body: MasterData): Observable<void> {
    return this.http.put<void>(`/admin/master-data/${mdType}/data/${mdId}`, body, { ...noErrorHandling() });
  }

  deleteElement(mdType: keyof MasterDataEntries, mdId: string): Observable<void> {
    return this.http.delete<void>(`/admin/master-data/${mdType}/data/${mdId}`, { ...noErrorHandling() });
  }

  getRelations(mdType: keyof MasterDataEntries): Observable<MasterDataRelation<any>> {
    return this.http.get<MasterDataRelation<any>>(`/admin/master-data/${mdType}/relation`, {
      ...noErrorHandlingForStatus([400])
    })
      .pipe(
        catchError(e => {
          if (e.status == 400) {
            // masterdata type has no relations
            return of({});
          }
          return of(e);
        })
      );
  }

  updateRelation(mdType: keyof MasterDataEntries, mdType2: keyof MasterDataEntries, body): Observable<void> {
    return this.http.put<void>(`/admin/master-data/${mdType}/relation/${mdType2}`, body, { ...noErrorHandling() });
  }
}
