import {
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PatternTemplate } from "src/app/core/interfaces/FieldTemplate";
import { UserService } from "src/app/shared/services/api/user/user.service";
import { Location as ngLocation } from '@angular/common';


@Component({
  selector: "app-register-user",
  templateUrl: "./register-user.component.html",
  styleUrls: ["./register-user.component.css"],
})
export class RegisterUserComponent {
  // Validacion del formulario
  isValid = false;
  // Estado del formulario
  stateForm: PatternTemplate | undefined;
  userForm: UntypedFormGroup; // formulario

  constructor(
    private router: Router,
    private location: ngLocation,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    localStorage.removeItem("lastSearchQuery");
  }

  formChanged(event: UntypedFormGroup): void {
    this.userForm = event;
    this.isValid = this.userForm.status === "VALID";
  }

  addUser(): void {
    // añadir usuario
    // validar datos
    // relizar peticion
    // this.userService.addUser(data);
    // restablecer formulario

  }
  redirectToHome(){
    this.router.navigate([""]);
  }

  back(){
    this.location.back();
  }
}
