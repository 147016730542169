import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExportResult } from 'src/app/shared/models/other-data.model';
import { RuleFilter } from 'src/app/shared/models/rules.model';
import { noErrorHandling } from 'src/app/shared/utils/RequestUtils';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor(private http: HttpClient) { }

  exportExcelSearch(filters: RuleFilter): Observable<ExportResult> {
    return this.http.post<ExportResult>(`/search/export/excel`, filters, { ...noErrorHandling() });
  }

  exportToExcel(textId: string): Observable<ExportResult> {
    return this.http.get<ExportResult>(`/rule/${textId}/export/excel`, { ...noErrorHandling() });
  }
}
