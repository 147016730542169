import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";


export interface Error {
    errorMessage: string,
    errorStatus: number,
}

@Injectable({ providedIn: 'root' })
export class ErrorResolver  {
    constructor(
        private router: Router,
    ) {}
  
    resolve(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<Error> | Promise<Error> | Error {
      return this.router.getCurrentNavigation().extras?.state?.errorInfo
    }
  } {

}