<div *ngIf="!data" class="flex justify-center mt-6">
  <app-loader></app-loader>
</div>

<!-- Detalle texto -->
<div *ngIf="data" class="container">
  <div class="mx-2.5 xl:mx-12">
    <!-- Idioma, validado, impacto -->
    <div class="w-full lg:w-10/12 xl:w-9/12 flex flex-wrap py-8 space-x-2 justify-end">
      <div class="inline-block px-2 md:px-4 py-3 text-left text-deloitte_black text-sm bg-deloitte_gray2"
        [ngClass]="{ 'border-custom': lang === actual_lang }" *ngFor="let lang of languages" (click)="changeLang(lang)">
        <span class="font-bold">{{ lang | uppercase }}</span>
      </div>

      <div class="inline-block px-2 md:px-4 py-3 text-left text-white text-sm"
        [ngClass]="data.info.validated ? 'validado' : 'borrador'">
        <span class="font-bold">{{
          (data.info.validated ? "VALIDATION_STATUS.VALIDATED" : "VALIDATION_STATUS.PENDING")
          | translate
          }}</span>
      </div>

      <div class="inline-block px-2 md:px-4 py-3 text-left text-white text-sm"
        ngClass="{{ getImpactClass(data?.info?.impact) }}">
        <span class="break-normal">{{
          "LABEL.IMPACT" | uppercase | translate
          }}</span>
        <span *ngIf="actual_lang" class="font-bold">&#58;&nbsp;
          {{ getImpact(data?.info?.impact) | translate }}
        </span>
      </div>

      <div 
        class="inline-block px-2 md:px-4 py-3 text-left text-white text-sm"
        ngClass="{{ getRequiredActionClass() }}"
      >
        <span class="break-normal">
          {{ "LABEL.REQUIRED_ACTION" | uppercase | translate }}  
        </span>
        <span *ngIf="actual_lang" class="font-bold">&#58;&nbsp;
          {{ getRequiredAction() | translate }}
        </span>
      </div>
    </div>

    <!-- Titulo  {{ rule?.title }} -->
    <div class="pb-8">
      <div class="text-black text-lg md:text-3xl break-words font-bold leading-9 pl-0 lg:pl-16 xl:pl-28 lg:w-10/12"
        *ngIf="data?.info?.lang_info[actual_lang]?.title">
        {{ data?.info?.lang_info[actual_lang]?.title }}
      </div>
    </div>

    <div class="w-full tabs">
      <div class="w-full tab">
        <input class="w-full hidden" type="checkbox" id="rd1" name="rd" />
        <label (click)="refreshAccordion('rd1')" class="w-full block md:hidden tab-label" for="rd1">
          {{ "LABEL.GENERAL_INFO" | uppercase | translate }}
        </label>
        <div class="w-full tab-content mt-4">
          <!-- Area -->
          <div class="w-full flex flex-wrap pb-8">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-2 md:mb-0">
              <span class="break-normal">{{
                "LABEL.AREAS" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 lg:w-7/12 md:px-0 text-sm">
              <ul class="grid grid-cols-1 gap-1">
                <li class="text-sm" *ngFor="let area of data?.areas">
                  {{ area | getMasterDataElement: "area":currentLang() }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Materias -->
          <div class="w-full flex flex-wrap pb-8 border-b border-b-deloitte_gray9">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-2 md:mb-0">
              <span class="break-normal">{{
                "LABEL.FIELDS" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 lg:w-7/12 md:px-0 text-sm">
              <ul class="grid grid-cols-1 gap-1">
                <li class="text-sm" *ngFor="let field of data?.fields">
                  {{ field | getMasterDataElement: "field":currentLang() }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Iniciativas -->
          <div class="w-full flex flex-wrap py-8 border-b border-b-deloitte_gray9">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-2 md:mb-0">
              <span class="break-normal">{{
                "LABEL.INITIATIVES" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 lg:w-7/12 md:px-0 text-sm">
              <ul class="grid grid-cols-1 gap-1">
                <li class="text-sm" *ngFor="let initiative of data?.initiatives">
                  {{
                  initiative | getMasterDataElement: "initiative":currentLang()
                  }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Ámbito y órgano -->
          <div class="w-full flex flex-wrap py-8 border-b border-b-deloitte_gray9">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-4">
              <span class="break-normal">{{
                "LABEL.GEOSCOPE" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 lg:w-7/12 md:px-0 text-sm mb-2">
              <span class="bg-deloitte_gray2 p-1 px-2 inline-block">{{
                data?.geo_scope | getMasterDataElement: "geo_scope":currentLang()
                }}</span>
            </div>

            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-2 md:mb-0">
              <span class="break-normal">{{
                "LABEL.ISSUING_BODY" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 lg:w-7/12 md:px-0 text-sm">
              <span>{{
                data?.issuer | getMasterDataElement: "issuer":currentLang()
                }}</span>
            </div>
          </div>

          <!-- Tipologia y estado -->
          <div class="w-full flex flex-wrap py-8 border-b border-b-deloitte_gray9">
            <div class="w-full lg:w-1/2 flex flex-wrap mb-2">
              <div class="w-full md:w-3/12 lg:w-6/12 font-bold pl-0 lg:pl-16 xl:pl-28">
                <span class="break-normal">{{
                  "SECTION.RULE_TYPE" | uppercase | translate
                  }}</span>
              </div>
              <div class="text-sm">
                <span>{{
                  data?.phase | getMasterDataElement: "phase":currentLang()
                  }}</span>
              </div>
            </div>

            <div class="w-full lg:w-1/2 flex flex-wrap mb-2">
              <div class="w-full md:w-3/12 lg:w-1/2 font-bold pl-0 lg:pl-16 xl:pl-28">
                <span class="break-normal">{{
                  "SECTION.RULE_SUBTYPE" | uppercase | translate
                  }}</span>
              </div>
              <div class="text-sm">
                <span>{{
                  data?.type | getMasterDataElement: "type":currentLang()
                  }}</span>
              </div>
            </div>

            <div class="w-full lg:w-1/2 flex flex-wrap mb-2 md:mb-0">
              <div class="w-full md:w-3/12 lg:w-6/12 font-bold pl-0 lg:pl-16 xl:pl-28">
                <span class="break-normal">{{
                  "LABEL.PROCESSING_STATUS" | uppercase | translate
                  }}</span>
              </div>
              <div *ngIf="actual_lang" class="text-sm">
                <span>
                  {{
                  getState(data?.info?.state) | translate
                  }}</span>
              </div>
            </div>
          </div>

          <!-- Fechas -->
          <div class="w-full flex flex-wrap py-8 border border-b-deloitte_gray9">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 pr-23 mb-2 md:mb-0">
              <span class="break-normal">{{
                "LABEL.DATES" | uppercase | translate
                }}</span>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 w-full md:w-9/12 lg:w-7/12 px-2 md:px-0">
              <div class="w-5/6 flex flex-col md:flex-row justify-between">
                <span class="text-base text-black font-bold mb-2 md:mb-0">{{
                  "LABEL.PUBLICATION_DATE" | uppercase | translate
                  }}</span>
                <span class="text-sm text-black">{{
                  data?.info?.dates?.publication_date
                  }}</span>
              </div>
              <div *ngIf="data?.info?.dates?.effective_date" class="w-5/6 flex flex-col md:flex-row justify-between">
                <span class="text-base text-black font-bold mb-2 md:mb-0">{{
                  "LABEL.EFFECTIVE_DATE" | uppercase | translate
                  }}</span>
                <span class="text-sm text-black">{{
                  data?.info?.dates?.effective_date
                  }}</span>
              </div>
              <div *ngIf="data?.info?.dates?.approval_date" class="w-5/6 flex flex-col md:flex-row justify-between">
                <span class="text-base text-black font-bold mb-2 md:mb-0">{{
                  "LABEL.APPROVAL_DATE" | uppercase | translate
                  }}</span>
                <span class="text-sm text-black">{{
                  data?.info?.dates?.approval_date
                  }}</span>
              </div>
              <div *ngIf="data?.info?.dates?.application_date" class="w-5/6 flex flex-col md:flex-row justify-between">
                <span class="text-base text-black font-bold mb-2 md:mb-0">{{
                  "LABEL.APPLICATION_DATE" | uppercase | translate
                  }}</span>
                <span class="text-sm text-black">{{
                  data?.info?.dates?.application_date
                  }}</span>
              </div>
              <div *ngIf="data?.info?.dates?.closing_date" class="w-5/6 flex flex-col md:flex-row justify-between">
                <span class="text-base text-black font-bold mb-2 md:mb-0">{{
                  "LABEL.CLOSING_DATE" | uppercase | translate
                  }}</span>
                <span class="text-sm text-black">{{
                  data?.info?.dates?.closing_date
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full tab">
        <input class="w-full hidden" type="checkbox" id="rd2" name="rd" />
        <label (click)="refreshAccordion('rd2')" class="w-full block md:hidden tab-label" for="rd2">
          {{ "LABEL.DESCRIPTION" | uppercase | translate }}
        </label>
        <div class="w-full tab-content mt-4">
          <!-- Descripcion -->
          <div class="w-full flex md:flex-wrap md:pt-8 pb-8 border-deloitte_gray9" [ngClass]="{
              'border-b':
                linkSection.length !== 0 || data?.evolutions.length !== 0
            }">
            <div class="w-full hidden md:block md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 pr-23 mb-2 md:mb-0">
              <span class="break-normal">{{
                "LABEL.DESCRIPTION" | uppercase | translate
                }}</span>
            </div>
            <div style="white-space: pre-line;" class="w-full md:w-9/12 lg:w-7/12 md:px-0 text-sm break-words">
              {{ data?.info?.lang_info[actual_lang]?.description }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-full tab">
        <input class="w-full hidden" type="checkbox" id="rd3" name="rd" />
        <label (click)="refreshAccordion('rd3')" class="w-full block md:hidden tab-label" for="rd3">
          {{ "LABEL.LINKS" | uppercase | translate }}
        </label>

        <div class="w-full tab-content mt-4">
          <!-- Enlace a la norma -->
          <div *ngIf="data?.info?.lang_info[actual_lang]?.link; let link" class="w-full flex flex-wrap mb-4">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-2 md:mb-0">
              <span class="break-normal">{{
                "SECTION.LINKS" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 lg:w-7/12 md:px-0 md:pl-4 text-sm px-4">
              <ul class="list-disc">
                <li>
                  <a [href]="link?.link" target="_blank" class="text-sm underline break-all">
                    {{ link?.link }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Otros enlaces de interes -->
          <div *ngIf="data?.info?.lang_info[actual_lang]?.other_links; let links" class="w-full flex flex-wrap pb-8">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-2 md:mb-0">
              <span class="break-normal">{{
                "SECTION.OTHER_LINKS" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 md:px-0 md:pl-4 px-4 text-sm">
              <ul class="list-disc grid grid-cols-1 gap-4">
                <li *ngFor="let link of links" class="text-sm underline">
                  <a [href]="link?.link" target="_blank" class="text-sm underline">
                    {{ link?.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Documento de la norma -->
          <div *ngIf="data?.info?.lang_info[actual_lang]?.document; let document" class="w-full flex flex-wrap mb-4">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-2 md:mb-0">
              <span class="break-normal">{{
                "SECTION.DOCUMENT" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 lg:w-7/12 md:px-0 md:pl-4 text-sm px-4">
              <ul class="list-disc">
                <li class="text-sm underline cursor-pointer" (click)="downloadDocument(document)">
                  {{ document.title }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Otros documentos de interes -->
          <div *ngIf="data?.info?.lang_info[actual_lang]?.other_documents; let documents"
            class="w-full flex flex-wrap pb-8">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 mb-2 md:mb-0">
              <span class="break-normal">{{
                "SECTION.OTHER_DOCUMENTS" | uppercase | translate
                }}</span>
            </div>
            <div class="w-full md:w-9/12 md:px-0 md:pl-4 px-4 text-sm">
              <ul class="list-disc grid grid-cols-1 gap-4">
                <li *ngFor="let document of documents" class="text-sm underline cursor-pointer"
                  (click)="downloadDocument(document)">
                  {{ document?.title }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Historico -->
          <div *ngIf="data?.evolutions.length > 0" class="w-full flex flex-wrap py-8 border-b border-deloitte_gray9"
            [ngClass]="{ 'border-t': linkSection.length !== 0 }">
            <div class="w-full md:w-3/12 font-bold pl-0 lg:pl-16 xl:pl-28 pr-23 mb-2 md:mb-0">
              <span class="break-normal">
                {{ "SECTION.HISTORICAL" | uppercase | translate }}
              </span>
            </div>

            <div class="w-full md:w-9/12 md:px-0 px-4 text-sm">
              <div class="text-evolution-header w-full">
                <div class="text-title hidden md:block w-full md:w-5/12"></div>
                <div class="text-date md:w-4/12">
                  {{ "LABEL.PUBLICATION_DATE" | uppercase | translate }}
                </div>
                <div class="text-status md:w-4/12">
                  {{ "LABEL.STATUS" | uppercase | translate }}
                </div>
              </div>

              <div *ngFor="let evolution of data?.evolutions" class="w-full text-evolution-row">
                <div class="text-title md:w-5/12">
                  <span class="break-all" (click)="showRule(evolution?.id)">
                    {{ getEvolutionTitle(evolution) }}</span>
                </div>
                <div class="text-date md:w-4/12">
                  {{ evolution?.publication_date }}
                </div>
                <div class="text-status w-full md:w-4/12">
                  <span>{{ getState(evolution.state) | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Opciones -->
<div *ngIf="data" class="w-full hidden md:block bg-gray-300">
  <div class="w-full md:flex md:flex-row md:justify-around px-2.5 py-5 md:gap-2 lg:px-12 xl:px-40">
    <div class="grid md:grid-cols-1 lg:grid-cols-2 gap-2 md:justify-around" *ngIf="actions?.EXPORT">
      <app-button (click)="exportexcel()" text="EXPORTEXCEL" theme="export_rule">
        <img class="inline-block pr-2" src="assets/imgs/exportar-excel.png" />
      </app-button>
      <app-button (click)="exportpdf()" text="EXPORTPDF" theme="export_rule">
        <img class="inline-block pr-2" src="assets/imgs/exportar-pdf.png" />
      </app-button>
    </div>

    <div class="flex flex-col md:flex-row md:flex-wrap gap-2 md:items-center">
      <app-button *ngIf="actions.UPDATE" theme="main" text="EDIT" (click)="editRule()">
      </app-button>

      <app-button *ngIf="!data.info.validated && actions?.VALIDATE" theme="main" text="PUBLISH"
        (click)="openValidationModal()"></app-button>

      <app-button *ngIf="actions?.SEND_ALERT" theme="main" text="SEND_ALERT" (click)="sendAlert()"></app-button>

      <app-button *ngIf="actions?.DELETE" theme="delete_red" text="DELETE" (click)="confirmDelete()"></app-button>
    </div>
  </div>
</div>

<app-modal #modal class="h-auto">
  <div class="px-12">
    <div class="text-2xl font-bold text-center text-black mb-4">
      {{ "MESSAGES.CONFIRM_DELETE_RULE" | uppercase | translate }}
    </div>
    <div class="text-sm font-bold text-center justify-start text-deloitte_black mb-14">
      {{ "MESSAGES.PERM_DELETE" | uppercase | translate }}
    </div>
    <div class="grid grid-cols-2 gap-2 md:gap-6">
      <app-button theme="main_block" (click)="deleteRule()" text="YES"></app-button>
      <app-button theme="main_block" (click)="closeModal()" text="NO"></app-button>
    </div>
  </div>
</app-modal>

<app-modal #validationModal>
  <div (click)="closeValidationModal()" class="absolute top-3 right-3 md:right-6">
    <img class="scale-75 cursor-pointer" src="assets/svg/Icon-Close@2x.svg" />
  </div>
  <div class="text-2xl mx-4 mb-6 block font-bold text-center">
    {{ "MESSAGES.CONFIRM_CREATE_RULE" | translate }}
  </div>
  <div class="space-around mt-5 md:mt-8">
    <app-button (click)="validateRule()" text="PUBLISH"></app-button>
    <app-button (click)="closeValidationModal()" text="CANCEL"></app-button>
  </div>
</app-modal>
