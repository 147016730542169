import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AWSApiHttpInterceptor } from "./aws.api.interceptor";

export const AWSApiHttpInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useFactory: () => {
    // TODO: Config instead of environment ?
    return new AWSApiHttpInterceptor(environment.aws.API.interceptHttp, environment.apiUrl, environment.aws.API.endpoints[0].name, environment.aws.API.revertOnError)
  },
  deps: [],
  multi: true,
}