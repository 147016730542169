import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RulesService } from 'src/app/shared/services/api/rules/rules.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ModalComponent } from "src/app/core/components/modal/modal.component";
import { ActionParams, RuleActionsService } from "src/app/shared/services/actions/rule-actions.service";
import { ProcessingStatus, Impacts, ValidationStatus } from 'src/app/shared/models/other-data.model';
import { RuleFormComponent } from 'src/app/core/components/forms/rule-form/rule-form.component';
import { mergeMap, tap } from 'rxjs/operators';
import { tapOnError } from 'src/app/shared/utils/rxjsUtils';

@UntilDestroy()
@Component({
  selector: 'app-register-rule',
  templateUrl: './register-rule.component.html',
  styleUrls: ['./register-rule.component.css'],
})

export class RegisterRuleComponent implements OnInit {
  @ViewChild("form") form: RuleFormComponent;
  @ViewChild("modal") modal: ModalComponent | undefined;

  loadingData = false;
  processingStates = ProcessingStatus;
  impacts = Impacts;
  validated = ValidationStatus;

  actions: ActionParams;


  constructor(
    private rulesService: RulesService,
    private router: Router,
    private notif: NotificationService,
    private t: TranslateService,
    private ruleActionsService: RuleActionsService,
  ) { }


  ngOnInit(): void {
    localStorage.removeItem("lastSearchQuery");
    this.getActions();
  }

  getActions() {
    this.ruleActionsService.getUserActions("0")
    .pipe(
      untilDestroyed(this)
    )
    .subscribe((data) => {
      this.actions = data;
    });
  }

  submit() {
    if (!this.form.form.valid) {
      console.log('Invalid form', this.form.form.value);
      return;
    }

    if (this.actions.VALIDATE) {
      this.openModal()
    } else {
      this.addRule()
    }
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.modal.close();
  }

  showInvalid() {
    const invalid = [];
    const controls = this.form.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
  }

  addRule(): void {
    const data = this.form.getData();

    this.rulesService.createRule(data)
      .pipe(
        untilDestroyed(this),
        tap(
          id => this.onRuleCreated(id),
          err => this.onCreationError(err)
        ),
      )
      .subscribe()
  }

  addValidatedRule(): void {
    const data = this.form.getData()

    this.rulesService.createRule(data)
      .pipe(
        tapOnError(err => this.onCreationError(err)),
        mergeMap((id) => this.rulesService.validate(id).pipe(
          tapOnError(err => this.onValidationError(err, id)),
        )),
        tap(id => this.onRuleCreated(id))
      )
      .subscribe()
  }

  onRuleCreated(id: string) {
    // TODO: i18n !
    this.notif.success("NOTIF.CREATE_SUCCESS");
    this.router.navigateByUrl(this.rulesService.getPathToRule(id));
  }

  onCreationError(e: unknown) {
    // TODO: i18n !
    // TODO: Improve error messaging
    console.log('Error creating rule', e);
    this.notif.error("NOTIF.CREATE_ERROR");
    this.modal.close();
  }

  onValidationError(e: unknown, id: string) {
    console.log('Error validating rule', e);
    this.notif.warning("NOTIF.VALIDATE_CREATE_ERROR");
    this.router.navigateByUrl(this.rulesService.getPathToRule(id));
  }

}


