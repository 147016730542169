<div class="flex " [matTooltip]="alert?.description">
  <div class="w-[15rem] md:w-3/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[0] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full cursor-pointer" (click)="showAlert()">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
        "
      >
        {{ alert?.title }}
      </div>
    </div>
  </div>

  <div class="w-[16rem] md:w-3/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[1] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full cursor-pointer" (click)="showAlert()">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
        "
      >
      <div style="display: flex; flex-wrap: wrap;">
        <div *ngFor="let area of alert?.areas" class="mt-3 mb-3 mr-2">
          <span class="tag leading-loose pt-1 pb-2 px-1">
            {{ area | getMasterDataElement: 'area':currentLang() }}
          </span>
        </div>

      </div>
      <ul class="list-none mt-2">
        <li *ngFor="let field of alert?.fields">
          <div class="flex" style="align-items: center;">
            <span class="dot mr-3"></span>
            <span>{{ field | getMasterDataElement: 'field':currentLang() }}</span>
          </div>
        </li>
      </ul>
      </div>
    </div>
  </div>

  <div class="w-[8rem] md:w-1/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[2] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full cursor-pointer" (click)="showAlert()">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
        "
      >
      {{ "LANGUAGE." + alert?.language | uppercase | translate }}
      </div>
    </div>
  </div>

  <div class="w-[12rem] md:w-2/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span
        class="
          inline-block
          truncate
          w-full
          px-4
          border border-b-none border-l-none border-t-none border-r-white
        "
        >{{ headers[3] }}</span
      >
    </div>
    <div class="py-2 bg-white flex h-full cursor-pointer" (click)="showAlert()">
      <div
        class="
          w-full
          text-xs
          px-4
          inline-block
          border border-b-none border-l-none border-t-none
          md:border-r-deloitte_gray9
        "
      >
        {{ alert?.creation_date }}
      </div>
    </div>
  </div>

  <div class="w-[20rem] md:w-3/12 flex flex-col">
    <div
      class="w-full bg-black text-white text-xs py-2 mb-2"
      [ngClass]="{ 'invisible h-0 mb-0': index !== 0 }"
    >
      <span class="inline-block truncate w-full px-4">{{ headers[4] }}</span>
    </div>
    <div class="py-2 bg-white flex h-full cursor-pointer" (click)="showAlert()">
      <div class="w-full text-xs px-4 inline-block">
        <ul class="list-none mt-2">
          <li *ngFor="let recipient of alert?.recipients">
            <div class="flex" style="align-items: center;">
              <span class="dot mr-3"></span>
              <span>{{ recipient.name ?  recipient.name : recipient.value}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
