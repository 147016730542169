import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import Pikaday from "pikaday";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/es";
import { v4 as uuidv4 } from "uuid";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { getDate } from "src/app/shared/utils/DateUtils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";


// TODO: Refactor to ReactiveForm component
@UntilDestroy()
@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
})
export class DatepickerComponent implements OnInit, AfterViewInit {
  // identificador datepicker
  id_datepicker = "";
  pikadayLoaded = false;
  // formato fechas
  date_in = "DD/MM/YYYY";
  date_out = "YYYY/MM/DD";
  group!: UntypedFormGroup;
  @Input() reset?;


  private _value = "";
  get value(): string {
    return this._value;
  }
  @Input() set value(v: string) {
    this._value = v;
    this.group.controls["date"].setValue(this.value)
  }


  // placeholder
  @Input() readonly placeholder;

  @Input() customInputCSS?;

  constructor(private builder: UntypedFormBuilder, private translateService: TranslateService) {
    this.id_datepicker = uuidv4();
    this.group = this.builder.group({ date: [''] });
  }

  ngOnInit(): void {
    if (this.reset) {
      this.reset.pipe(untilDestroyed(this)).subscribe(() => {
        this.resetValues();
      });

    }
    //this.value ? this.group.controls["date"].setValue(this.value) : ''
    // this.group.updateValueAndValidity();

  }

  ngAfterViewInit(): void {
    dayjs.extend(localeData);
    dayjs.localeData();
    dayjs.locale(this.translateService.currentLang);
    if (document.getElementById(this.id_datepicker)) {
      this.loadPikaday();
    }
  }

  loadPikaday(): void {
    new Pikaday({
      field: document.getElementById(this.id_datepicker),
      format: this.date_out,
      i18n: {
        previousMonth: " ",
        nextMonth: " ",
        months: dayjs().localeData().months(),
        weekdays: dayjs().localeData().weekdays(),
        weekdaysShort: dayjs().localeData().weekdaysShort(),
      },
      onSelect: (date: any) => {
        const value = getDate(date, this.translateService.currentLang, this.date_out);
        this.group.controls["date"].setValue(value);
      },
    });
    this.pikadayLoaded = true;
  }

  getFormatES(): string {
    return getDate(this.group.value, this.translateService.currentLang, this.date_in);
  }

  resetValues(): void {
    this.group.reset({
      date: ''
    });
  }
}
