import { Link } from "./rules.model";

enum ROLES_ENUM {
  INTERNAL = 'ROLES.INTERNAL',
  ADMIN = 'ROLES.ADMIN',
  APPROVER = 'ROLES.APPROVER',
  EXTERNAL = 'ROLES.EXTERNAL',
}

export enum VALIDATION_STATUS_ENUM {
  PENDING = 'VALIDATION_STATUS.PENDING',
  VALIDATED = 'VALIDATION_STATUS.VALIDATED',
}

enum PROCESSING_STATUS_ENUM {
  APPROVED = 'PROCESSING_STATUS.APPROVED',
  INPROCESS = 'PROCESSING_STATUS.INPROCESS',
}

enum IMPACTS_ENUM {
  HIGH = 'IMPACTS.HIGH',
  MEDIUM = 'IMPACTS.MEDIUM',
  LOW = 'IMPACTS.LOW',
  WITHOUTIMPACT = 'IMPACTS.WITHOUTIMPACT',
}

enum RECIPIENTS_ENUM {
  COMPANIES = 'RECIPIENTS.COMPANIES',
  USERS = 'RECIPIENTS.USERS',
  EMAIL = 'RECIPIENTS.EMAIL',
}

export type Role = {
  id: Number;
  name: ROLES_ENUM;
};

export type ProcessingState = {
  id: Number;
  name: PROCESSING_STATUS_ENUM;
};

export type Impact = {
  id: Number;
  name: IMPACTS_ENUM;
};

export type RecipientOption = {
  id: number;
  name: RECIPIENTS_ENUM;
};

export const Roles: Role[] = [
  { id: 1, name: ROLES_ENUM.INTERNAL },
  { id: 2, name: ROLES_ENUM.ADMIN },
  { id: 3, name: ROLES_ENUM.APPROVER },
  { id: 4, name: ROLES_ENUM.EXTERNAL },
];

export const ValidationStatus: VALIDATION_STATUS_ENUM[] = [
  VALIDATION_STATUS_ENUM.PENDING,
  VALIDATION_STATUS_ENUM.VALIDATED
];

export const ProcessingStatus: ProcessingState[] = [
  { id: 1, name: PROCESSING_STATUS_ENUM.APPROVED },
  { id: 2, name: PROCESSING_STATUS_ENUM.INPROCESS },
];

export const Impacts: Impact[] = [
  { id: 1, name: IMPACTS_ENUM.HIGH },
  { id: 2, name: IMPACTS_ENUM.MEDIUM },
  { id: 3, name: IMPACTS_ENUM.LOW },
  { id: 4, name: IMPACTS_ENUM.WITHOUTIMPACT },
];

export const Recipients: RecipientOption[] = [
  { id: 2, name: RECIPIENTS_ENUM.COMPANIES },
  { id: 1, name: RECIPIENTS_ENUM.USERS },
  { id: 3, name: RECIPIENTS_ENUM.EMAIL },
];

export interface ExportResult extends Url {
  documents: Link[]
}

export interface Url {
  url: string,
}
