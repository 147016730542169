export default {
  main: {
    /** Boton primario: blanco */
    style:
      "w-full py-3 px-4 md:w-18 lg:min-w-[8rem] lg:max-w-[12rem] lg:w-auto xl:w-44 rounded border border-black bg-white text-black mb-4 sm:mb-0",
    state:
      "hover:bg-black hover:text-white disabled:opacity-30 disabled:bg-black disabled:text-white",
  },
  main_black: {
    /** Boton primario: negro */
    style:
      "py-3 px-4 min-w-[7rem] rounded border border-black bg-black text-white mb-4 sm:mb-0 font-bold",
    state:
      "hover:bg-white hover:text-black disabled:opacity-30 disabled:bg-white disabled:text-black",
  },
  /** Boton main que ocupa menos  */
  main_block_strech: {
    style:
      "py-3 px-4 min-w-[7rem] md:block rounded border border-black bg-white font-bold text-black",
    state:
      "disabled:bg-deloitte_gray9/[.3] disabled:border-deloitte_gray9 disabled:text-deloitte_gray9 hover:bg-black hover:text-white ",
  },
  /** Boton main que ocupa el 100% */
  main_block: {
    style:
      "py-3 px-4 w-full md:block rounded border border-black bg-white font-bold text-black",
    state:
      "disabled:bg-deloitte_gray9/[.3] disabled:border-deloitte_gray9 disabled:text-deloitte_gray9 hover:bg-black hover:text-white ",
  },
  /** Button for home filters options */
  home_filter: {
    style:
      "py-2 px-3 md:py-1 md:px-2 min-w-[7rem] md:min-w-[3rem] rounded border border-black bg-black text-white mb-4 sm:mb-0 font-bold",
    state:
      "hover:bg-white hover:text-black disabled:opacity-30 disabled:bg-white disabled:text-black",
  },
  home_filter_strech: {
    style:
      "py-2 px-3 md:py-1 md:px-2 min-w-[7rem] md:min-w-[3rem] md:block rounded border border-black bg-white font-bold text-black",
    state:
      "disabled:bg-deloitte_gray9/[.3] disabled:border-deloitte_gray9 disabled:text-deloitte_gray9 hover:bg-black hover:text-white ",
  },
  /** Boton nuevo de los items de normas */
  new: {
    style:
      "px-6 py-2 w-full md:w-22 rounded border bg-deloitte_green text-white border-deloitte_green uppercase cursor-default",
    state: "",
  },
  /** boton sin estilos */
  deloitteunderline: {
    style:
      "w-full pt-2 pb-1 px-4 text-deloitte font-bold text-sm underline italic uppercase",
    state: "",
  },
  /** toggle lupa */
  toggle: {
    style: "rounded-full p-5 bg-white",
    state: "",
  },
  icon: {
    style: "rounded-full p-5 bg-white shadow-search_icon",
    state: "",
  },
  /** boton export de historico y favoritos */
  export: {
    style:
      "w-full cursor-pointer block px-5 flex items-center justify-center text-deloitte_gray9",
    state: "",
  },
  /** boton delete de historico y favoritos */
  delete: {
    style:
      "w-full cursor-pointer block px-5 flex items-center justify-center text-tiny text-deloitte_green",
    state: "",
  },
  /** boton delete rojo */
  delete_red: {
    style:
      "w-full cursor-pointer block px-5 flex items-center justify-center text-tiny text-deloitte_red",
    state: "",
  },
  /** boton export de home */
  export_home: {
    style:
      "w-full cursor-pointer content-between text-xs flex-col md:flex-row block px-5 flex items-center justify-center text-deloitte_gray9",
    state: "",
  },
  /** boton export de detalle norma */
  export_rule: {
    style:
      "cursor-pointer block p-5 md:p-0 mr-10 flex md:items-center md:justify-center text-black text-sm",
    state: "",
  },
  /** boton tooltip */
  tooltip: {
    style: "w-full flex items-center py-3 px-4 text-white text-tiny",
    state: "hover:text-deloitte hover:font-bold",
  },
  /** boton exclusivo del componente alllinks */
  link: {
    style:
      "w-full rounded focus:outline-none appearance-none py-2.5 px-5 border border-black bg-black text-base text-white",
    state: "disabled:opacity-30",
  },
  /** boton con borde verde,fondo verde y texto blanco */
  deloitte_button: {
    style:
      "flex justify-center items-center py-2.5 px-4 w-full md:w-auto text-xs md:text-base rounded border border-deloitte bg-deloitte text-white",
    state: "",
  },
  /** boton con borde verde, fondo blanco y texto verde */
  deloitte_search: {
    style:
      "flex justify-center items-center py-2.5 px-4 w-full text-xs md:text-base md:w-auto rounded border border-deloitte bg-white text-deloitte",
    state: "",
  },
  /** boton con borde gris, fondo transparente y texto gris */
  deloitte_button_disabled: {
    style:
      "flex justify-center items-center py-2.5 px-4 w-full md:w-auto text-xs md:text-base rounded border border-deloitte_gray9 bg-transparent text-deloitte_gray9",
    state: "",
  },
};
