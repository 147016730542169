<div class="container">
  <div>
    <h1>
      {{"LABEL.LEGAL_NOTICE" | translate }}
    </h1>

    <ol>
      <li>{{"LEGAL_NOTICE.HEADERS.OWNERSHIP" | translate }}</li>
      <div>
        <p>
          {{"LEGAL_NOTICE.CONTENT.OWNERSHIP" | translate }}
        </p>
      </div>

      <li>{{"LEGAL_NOTICE.HEADERS.USER_OBLIGATION" | translate }}:</li>
      <div>
        <p *ngFor="let item of ('LEGAL_NOTICE.CONTENT.USER_OBLIGATION.CONTENT' | translate)">
          {{item}}
        </p>

        <ul>
          <li *ngFor="let item of ('LEGAL_NOTICE.CONTENT.USER_OBLIGATION.PROHIBITED_ACTIONS' | translate)">
            {{item}}
          </li>
        </ul>

        <p>
          {{"LEGAL_NOTICE.CONTENT.USER_OBLIGATION.ACCESS_KEYS" | translate }}
        </p>
      </div>

      <li class="intelectual-property">{{"LEGAL_NOTICE.HEADERS.INTELECTUAL_PROPERTY" | translate }}:
        <div>
          <p *ngFor="let item of ('LEGAL_NOTICE.CONTENT.INTELECTUAL_PROPERTY' | translate)">
            {{item}}
          </p>
        </div>

        <ol>
          <li>3.1 {{ "LEGAL_NOTICE.HEADERS.LINKS" | translate }}:</li>
          <div>
            <p *ngFor="let item of ('LEGAL_NOTICE.CONTENT.LINKS' | translate)">
              {{item}}
            </p>
          </div>

          <li>3.2 {{"LEGAL_NOTICE.HEADERS.THIRD_PARTY" | translate}}:</li>
          <div>
            <p>
              {{"LEGAL_NOTICE.CONTENT.THIRD_PARTY.TEXT" | translate}}
            </p>

            <table>
              <tr>
                <th>{{"LEGAL_NOTICE.CONTENT.THIRD_PARTY.SOURCE" | translate}}</th>
                <th>{{"LEGAL_NOTICE.CONTENT.THIRD_PARTY.AUTHORITY" | translate}}</th>
              </tr>

              <tr *ngFor="let item of ( 'LEGAL_NOTICE.CONTENT.THIRD_PARTY.LIST' | translate); let thirdParty">
                <td>{{thirdParty.SOURCE}}</td>
                <td>{{thirdParty.AUTHORITY}}</td>
              </tr>
            </table>

            <p *ngFor="let item of ('LEGAL_NOTICE.CONTENT.THIRD_PARTY.CONTENT' | translate)">
              {{item}}
            </p>
          </div>
        </ol>
      </li>

      <li>{{'LEGAL_NOTICE.HEADERS.DATA_PROTECTION' | translate}}:</li>
      <div>
        <p>
          {{'LEGAL_NOTICE.CONTENT.DATA_PROTECTION' | translate}}
        </p>
      </div>

      <li>{{"LEGAL_NOTICE.HEADERS.WEBSITE_RESTRICTIONS" | translate}}:</li>
      <div>
        <p>
          {{"LEGAL_NOTICE.CONTENT.WEBSITE_RESTRICTIONS.NOT_OBLIGED" | translate}}:
        </p>

        <ul>
          <li *ngFor="let item of ('LEGAL_NOTICE.CONTENT.WEBSITE_RESTRICTIONS.NOT_RESTRICTED' | translate)">
            {{item}}
          </li>
        </ul>

        <p>
          {{"LEGAL_NOTICE.CONTENT.WEBSITE_RESTRICTIONS.SERVICE" | translate}}
        </p>
      </div>

      <li>{{"LEGAL_NOTICE.HEADERS.WEBSITE_CHANGES" | translate}}:</li>
      <div>
        <p>
          {{"LEGAL_NOTICE.CONTENT.WEBSITE_CHANGES" | translate}}
        </p>
      </div>

      <li>{{"LEGAL_NOTICE.HEADERS.APPLICABLE_LAW" | translate}}:</li>
      <div>
        <p>
          {{"LEGAL_NOTICE.CONTENT.APPLICABLE_LAW" | translate}}
        </p>
      </div>

      <li>{{"LEGAL_NOTICE.HEADERS.SERVICE_NATURE" | translate}}:</li>
      <div>
        <p>
          {{"LEGAL_NOTICE.CONTENT.SERVICE_NATURE" | translate}}
        </p>
      </div>
    </ol>
  </div>
</div>
