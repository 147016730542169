import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataTypes } from '../../models/master-data.model';
import { GetMasterDataPipe } from '../get-masterdata/get-masterdata.pipe';

@Pipe({
  name: 'getMasterdataElementNameList'
})
export class GetMasterdataElementNameListPipe implements PipeTransform {

  constructor(
    private getMasterData: GetMasterDataPipe,
  ) { }

  transform(key1: MasterDataTypes,
    key2: string[]): any {
    const masterData = this.getMasterData.transform(key1);
    const res: string[] = [];
    masterData.map((elem) => {
      if (key2.includes(elem.id)) {
        res.push(elem.name);
      }
    });
    return res;
  }

}
