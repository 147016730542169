<div *ngIf="show" class="fixed z-40 inset-0">
  <div
    class="
      overflow-auto
      md:overflow-hidden
      items-end
      justify-center
      block
      relative
    "
  >
    <div
      class="fixed inset-0 bg-black/[.6] transition-opacity"
      aria-hidden="true"
    ></div>
    <span class="inline-block align-middle h-screen">&#8203;</span>
    <div
      [ngClass]="{ big: big }"
      class="
        rounded
        absolute
        -translate-x-2/4
        md:-translate-y-2/4
        scrollbar
        top-8
        md:top-1/2
        w-11/12
        left-1/2
        modal-position
        bg-white
      "
    >
      <div class="py-8 px-7 h-auto overflow-auto">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
